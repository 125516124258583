import { CONSTANT } from '../../static/constants'

const initialState = {
     actividad: [],
     loading: true,
     error: null,
 };

 const reducer = (state = initialState, { type, payload }) => {
     switch (type) {
         case CONSTANT.ACTIVIDAD_BY_ID_START:
             return {
                 ...state,
                 actividadid: payload,
                 loading: true,
             };
         case CONSTANT.ACTIVIDAD_BY_ID_SUCCESS:
             return {
                 ...state,
                 actividad: payload.data.data,
                 loading: false,
             };
         case CONSTANT.ACTIVIDAD_BY_ID_ERROR:
             return {
                 ...state,
                 error: payload,
                 loading: false,
             };
        case CONSTANT.ACTIVIDAD_BY_ID_UPDATE_FINISHED:
            let new_state = { ...state }
            new_state.actividad.finalizada = !new_state.actividad.finalizada; 
            return {
                ...new_state
            };
         default:
             return state;
     }
 }
 
 export default reducer