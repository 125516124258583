import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CONSTANT } from '../../../static/constants';
import { Modal, Accordion, ButtonToolbar } from 'react-bootstrap';
import axios from 'axios';
import AuthToken from "modules/shared/authToken";
import flechaAmarilla from '../../../assets/img/iconos/flechaAmarilla.svg';
import aviso from '../../../assets/img/iconos/aviso.svg';
import llave from '../../../assets/img/iconos/llave.svg';
import puerta from '../../../assets/img/iconos/puerta.svg';
import iconoBandera from '../../../assets/img/iconos/banderaVerde.svg';
import flechaRoja from '../../../assets/img/iconos/flechaRoja.svg';
import manita1 from '../../../assets/img/iconos/manita1.svg';
import manita2 from '../../../assets/img/iconos/manita2.svg';
import manita3 from '../../../assets/img/iconos/manita3.svg';
import iconoCamara from '../../../assets/img/iconos/iconoCamara.svg'
import { Donut } from 'react-dial-knob'
import DetalleInsignia from 'components/frontend/detalleInignia/DetalleInsignia';
import Uploader from '../../../shared/uploader/uploaderPicUsuario';
import InputEspecial from 'components/frontend/elementosVarios/InputEspecial';
import User from '../../../assets/img/iconos/UserIcon.svg';
import './Perfil.css';

export default function Perfil() {
     const [show, setShow] = useState(false);
     const [showCargarImagen, setShowCargarImagen] = useState(false);
     const [showCambiarClave, setShowCambiarClave] = useState(false);
     const [insignia, setInsignia] = useState({});
     const [usrPic, setUsrPic] = useState(User);
     const [passError, setPassError] = useState(null);
     const [passSuccess, setPassSuccess] = useState(null);
     const [btnState, setBtnState] = useState("ACTUALIZAR");
     const [modulosPerfil, setModulosPerfil] = useState([])
     const [nivelActSocial, setNivelActSocial] = useState(0)
     const [unidadEnCurso, setUnidadEnCurso] = useState({
          orden: 0,
          nombre: '',
          infoModulos: {
               configuracion: {
                    retoFecha: '',
                    fechaApertura: '',
                    retoFechaDate: new Date()
               }
          }
     })
     let pass_data = {
          password_0: "",
          password_1: "",
          password_2: ""
     };


     const dispatch = useDispatch();
     const cerrarSesion = () => {
          dispatch({ type: CONSTANT.AUTH_LOGOUT_RESET_STORE });
          AuthToken.setRemove(true);
     }
     const currentUser = useSelector((state) => state.auth);
     let obj = { fileName: '', fileUrl: User };
     useEffect(() => {
          if (typeof (currentUser.FOTO) == "string") {
               try {
                    let obj = JSON.parse(currentUser.FOTO)
                    setUsrPic(obj.fileUrl)
               } catch (e) {
                    console.log("Catch!", e)
               }
          }
     }, [currentUser.FOTO])

     var nombre = currentUser.NOMBRE + ' ' + currentUser.APELLIDO_P;
     useEffect(() => {
          const token = localStorage.getItem(CONSTANT.AUTH_TOKEN)
          dispatch({ type: CONSTANT.INSIGNIAS_BY_USUARIO_START, payload: [] });

          axios.get(process.env.REACT_APP_API_URL + `/api/usuario/perfil`, {
               headers: {
                    'Authorization': `${token}`,
                    "Content-Type": "application/json",
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0'
               }
          })
               .then(res => {
                    dispatch({ type: CONSTANT.INSIGNIAS_BY_USUARIO_SUCCESS, payload: res })
                    axios.get(process.env.REACT_APP_API_URL + `/api/get_modulos/perfil`, {
                         headers: {
                              'Authorization': `${token}`,
                              "Content-Type": "application/json",
                              'Cache-Control': 'no-cache',
                              'Pragma': 'no-cache',
                              'Expires': '0'
                         }
                    })
                         .then(res2 => {
                              res2.data.forEach(unidad => {
                                   if (unidad.infoModulos.configuracion !== undefined) {
                                        if (unidad.infoModulos.configuracion.fechaApertura !== "") {
                                             let d2 = new Date()
                                             var uFechaApertura = `${unidad.infoModulos.configuracion.fechaApertura} 00:00:00`
                                             var arr = uFechaApertura.split(/[- :]/);
                                             let d3 = new Date(arr[0], arr[1]-1, arr[2], 0, 0, 0);
                                             if (d2.getTime() > d3.getTime()) {
                                                  arr = unidad.infoModulos.configuracion.retoFecha.split(/[- :]/);
                                                  unidad.infoModulos.configuracion.retoFechaDate = new Date(arr[0], arr[1]-1, arr[2], 0, 0, 0);
                                                  setUnidadEnCurso(unidad)
                                             }
                                        }
                                   }
                                   let puntos = 0
                                   console.log(unidad.SocialRank)
                                   if(unidad.SocialRank !== undefined) {
                                        puntos = unidad.SocialRank[0].Puntos;
                                   } 
                                   // console.log( "Puntos: " + puntos )
                                   setNivelActSocial( puntos )
                              })
                              setModulosPerfil(res2.data)
                              console.log( res2.data )
                         })
                         .catch((err2) => {
                              console.log("Error: ", err2)
                         })

               })
               .catch((err) => {
                    dispatch({ type: CONSTANT.INSIGNIAS_BY_USUARIO_ERROR, payload: err });
               })
     }, [dispatch]);

     const { insignias, insigniasCompletadas, loading } = useSelector((state) => state.insignias);
     const { evaluacionFinal, programa } = useSelector((state) => state.home);
     console.log(evaluacionFinal);
     const califinal = (evaluacionFinal.calificacion !== null && evaluacionFinal.evaluacion !== '' && evaluacionFinal.promedios[0].promedioReto !== null && evaluacionFinal.promedios[0].promedioJefe !== null ? ((((evaluacionFinal.calificacion*30/100)+(evaluacionFinal.promedios[0].promedioReto*40/100)+(evaluacionFinal.promedios[0].promedioJefe*30/100))*100)/100) : 'Pendiente');

     if (loading) return <></>

     const detalleInsignia = (e, detalles) => {
          e.preventDefault();
          let Ins = insigniasCompletadas.filter( ins => parseInt(ins.InsigniasUsuarios.insignia) === parseInt(detalles.id) )
          if(Ins.length > 0){
               detalles.nivel = Ins[0].InsigniasUsuarios.nivel
          }
          setShow(true);
          setInsignia(detalles);
     }

     const handleClose = () => {
          setShowCargarImagen(false);
     }

     const handleCloseCambiarClave = () => {
          setShowCambiarClave(false);
          setPassError(null)
          setPassSuccess(null)
     }

     const fechaUTCaLocal = (d) => {
          let localDate = new Date(d)
          if (isNaN(localDate.getTime())) return ""
          const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
          return "" + ('0' + localDate.getDate()).slice(-2) + "/" + meses[localDate.getMonth()] + "/" + localDate.getFullYear()
     }

     let saveData = {};
     const filesUploaded = {}
     const callbackFromChild = (key, data) => {
          filesUploaded[key] = data;
     }

     const PromedioGeneralCalculo = () => {
          let unidades = 0;
          let promedio = 0;
          modulosPerfil.forEach(unidad =>{
               let PromedioJefe = unidad.PromedioJefe.filter( prom => prom.orden+1 === unidad.orden)
               let promJefe = '';
               if(PromedioJefe.length > 0){
                    promJefe = Math.round( PromedioJefe[0]['promedioJefe'] * 10 ) / 10
               }
               if(promJefe > 0){
                    promedio = (promedio + promJefe);
                    unidades++
               }
          })
          // console.log(promedio)
          // console.log(unidades)
          let promedioGeneral = 0;
          if(promedio > 0 && unidades > 0){
               promedioGeneral = Math.round( (promedio/unidades) * 10 ) / 10
          }else {
               promedioGeneral = '';
          }
          // console.log(promedioGeneral)
          return (
               <>{promedioGeneral}</>
          );
     }

     const PromedioGeneralCalculoReto = () => {
          let unidades = 0;
          let promedio = 0;
          modulosPerfil.forEach(unidad =>{

               let PromedioUnidad = unidad.PromedioUnidades.filter( prom => prom.orden+1 === unidad.orden)
               let promReto = '';
               if(PromedioUnidad.length > 0){
                    promReto = Math.round( PromedioUnidad[0]['promedioReto'] * 10 ) / 10
               }
               if(promReto > 0){
                    promedio = (promedio + promReto);
                    unidades++
               }
          })

          let promedioGeneral = 0;
          if(promedio > 0 && unidades > 0){
               promedioGeneral = Math.round( (promedio/unidades) * 10 ) / 10
          }else {
               promedioGeneral = '';
          }

          return (
               <>{promedioGeneral}</>
          );
     }

     const saveDataFn = data2Save => {
          const token = localStorage.getItem(CONSTANT.AUTH_TOKEN);
          axios({
               method: 'POST',
               url: process.env.REACT_APP_API_URL + `/api/pic_update`,
               data: data2Save,
               headers: {
                    'Authorization': `${token}`,
                    "Content-Type": "application/json"
               }
          })
               .then(res => {
                    if (res.status === 200) {
                         let tmp = currentUser;
                         tmp.FOTO = (data2Save.foto);
                         dispatch({ type: CONSTANT.AUTH_EMPLOYEE_ID_VERIFICATION_UPDATE_PIC, payload: tmp });
                         handleClose()
                    } else {
                         alert("Ocurrio un error al guardar.");
                    }
               })
               .catch(err => {
                    console.log(err)
                    alert("Ocurrio un error al guardar.");
               });
     }

     const guardaImagen = () => {
          if (Object.keys(filesUploaded).length <= 0) {
               alert('Falta agregar archivo');
               return;
          } else {
               // Subimos archivos a aws
               const key = Object.keys(filesUploaded)[0];
               filesUploaded[key]( saveDataFn, saveData, key )
               delete filesUploaded[key];
          }
     }

     const validarPass = (e) => {
          e.preventDefault();
          const valNumeros = /(?=.*[0-9])/;
          const valEspeciales = /(?=.*[!@#$%^&*])/;
          const valMayusculas = /(?=.*[A-Z])/;
          document.getElementById("caracteres").className = (e.target.value.length >= 12) ? 'requisitosPassTrue2' : 'requisitosPassFalse2';
          document.getElementById("numeros").className = valNumeros.test(e.target.value) ? 'requisitosPassTrue2' : 'requisitosPassFalse2';
          document.getElementById("mayusculas").className = valMayusculas.test(e.target.value) ? 'requisitosPassTrue2' : 'requisitosPassFalse2';
          document.getElementById("especiales").className = valEspeciales.test(e.target.value) ? 'requisitosPassTrue2' : 'requisitosPassFalse2';
     }

     const submit = async e => {
          e.preventDefault()
          if (btnState === "GUARDANDO...") {
               return;
          }
          setBtnState("GUARDANDO...")

          const valNumeros = /(?=.*[0-9])/;
          const valEspeciales = /(?=.*[!@#$%^&*])/;
          const valMayusculas = /(?=.*[A-Z])/;

          if ((pass_data.password_1.length < 12) || !valNumeros.test(pass_data.password_1) || !valMayusculas.test(pass_data.password_1) || !valEspeciales.test(pass_data.password_1)) {
               setBtnState("ACTUALIZAR")
               setPassError('Nueva contraseña no cumple con los requisitos.')
               return;
          }

          //
          setPassError(null)
          if (pass_data.password_1 !== pass_data.password_2) {
               setPassError('Contraseñas no coinciden.')
               return;
          }

          const token = localStorage.getItem(CONSTANT.AUTH_TOKEN);
          axios({
               method: 'POST',
               url: process.env.REACT_APP_API_URL + `/api/password_update`,
               data: pass_data,
               headers: {
                    'Authorization': `${token}`,
                    "Content-Type": "application/json"
               }
          })
               .then(res => {
                    if (res.status === 200) {
                         // console.log("Contraseña actualizada.")
                         setBtnState("ACTUALIZAR")
                         setPassSuccess("Contraseña actualizada con éxito.")
                         handleClose()
                    } else {
                         console.log(res)
                         setPassError('Ocurrio un error al guardar.')
                         setBtnState("ACTUALIZAR")
                    }
               })
               .catch(err => {
                    console.log(err)
                    setPassError('Ocurrio un error al guardar.')
                    setBtnState("ACTUALIZAR")
               });
          // 
     }

     const cambiaForm = target => {
          setPassError(null)
          pass_data = {
               ...pass_data,
               [target.name]: target.value
          };
     }

     const ModalCambiarClave = () => {
          return (
               <Modal show={showCambiarClave} onHide={handleCloseCambiarClave} animation={false} size='md' centered>
                    <Modal.Title>
                         <div className="row">
                              <div className="d-flex col align-items-center justify-content-end">
                                   <button className="btn botonModal" onClick={handleCloseCambiarClave}><i className="far fa-times-circle cerrarModal"></i></button>
                              </div>
                         </div>
                    </Modal.Title>
                    <Modal.Body>
                         <form onSubmit={submit} method="post">
                              <div className="row">
                                   <InputEspecial nombre="password_0" label="Contraseña actual" funcionSetData={cambiaForm} />
                              </div>
                              <div className="row">
                                   <InputEspecial nombre="password_1" label="Contraseña nueva" funcionSetData={cambiaForm} funcionValidar={validarPass} />
                              </div>
                              <div className="row">
                                   <InputEspecial nombre="password_2" label="Confirmar contraseña nueva" funcionSetData={cambiaForm} />
                              </div>
                              {passError !== null &&
                                   <div className="row aire">
                                        <div class="col d-flex align-items-center justify-content-center">
                                             <small id="passwordHelp" class="text-danger">{passError}</small>
                                             <br />
                                        </div>
                                   </div>
                              }
                              <div className="row aire">
                                   <div className="input-group mb-3">
                                        <ul>
                                             <li id="caracteres" className="requisitosPassFalse2">Al menos 12 caracteres</li>
                                             <li id="mayusculas" className="requisitosPassFalse2">Al menos una may&uacute;scula</li>
                                             <li id="numeros" className="requisitosPassFalse2">Al menos un n&uacute;mero</li>
                                             <li id="especiales" className="requisitosPassFalse2">Al menos un car&aacute;cter especial</li>
                                        </ul>
                                   </div>
                              </div>

                              <div className="row ">
                                   <div className="col d-flex align-items-center justify-content-center">
                                        <button type="submit" className="btn btn-verde" disabled={btnState === "GUARDANDO..." ? true : false} >{btnState}</button>
                                   </div>
                              </div>
                              {passSuccess !== null &&
                                   <div className="row aire">
                                        <div class="col d-flex align-items-center justify-content-center">
                                             <small id="passwordHelp" class="text-success">{passSuccess}</small>
                                             <br />
                                        </div>
                                   </div>
                              }
                         </form>
                    </Modal.Body>
               </Modal>
          );
     }

     const ModalCambiarImagen = () => {
          return (
               <Modal show={showCargarImagen} onHide={handleClose} animation={false} size='md' centered>
                    <Modal.Title>
                         <div className="row">
                              <div className="d-flex col align-items-center justify-content-end">
                                   <button className="btn botonModal" onClick={handleClose}><i className="far fa-times-circle cerrarModal"></i></button>
                              </div>
                         </div>
                    </Modal.Title>
                    <Modal.Body>
                         <div className='row'>
                              <div className="col uploadBoxBottom">
                                   <div className='uploadBox'>
                                        <div className="col-md-8 offset-md-2">
                                             <div className="d-flex align-items-center justify-content-center">
                                                  <Uploader filesCallback={callbackFromChild} input_key="foto" s3_key={'Usuarios'} input_thumb={obj} accepted_files='image/*' />
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className='row'>
                              <div className="col-12">
                                   <div className="d-flex align-items-center justify-content-center">
                                        &nbsp;<br />
                                        <button type="button" style={{ marginTop: '10px' }} onClick={guardaImagen} className="btn btn-verde mt-3">GUARDAR</button>
                                   </div>
                              </div>
                         </div>
                    </Modal.Body>
               </Modal>
          );
     }

     const backOne = (e) => {
          e.preventDefault()
          window.history.back()
     }


     return (
          <React.Fragment key={"Perfil_Key"}>
               {show ? <DetalleInsignia show={show} setShow={setShow} insignia={insignia} /> : null}
               {showCargarImagen ? <ModalCambiarImagen /> : null}
               {showCambiarClave ? <ModalCambiarClave /> : null}
               <div className="container-fluid fondo">
                    <div className="col-12">
                         <div className='row'>
                              <div className='col-12 col-lg-2 my-2 olineG'>
                                   <div className="col usuarioPerfil py-5 ">
                                        <div className="row">
                                             <div className="col">
                                                  <div className="d-flex linkRegresarPerfil align-items-center justify-content-center">
                                                       <Link onClick={(e) => { backOne(e) }}>
                                                            <img alt="flecha" src={flechaAmarilla}/>
                                                            <span className='linkRegresarPerfil'>&nbsp;&nbsp;&nbsp;REGRESAR</span>
                                                       </Link>
                                                  </div>
                                             </div>
                                        </div>
                                        <div className="row">
                                             <div className="col">
                                                  <div className="d-flex align-items-center justify-content-center">
                                                       <span className='bienvenidaPerfil'>Nos da gusto <br/> verte por aquí</span>
                                                  </div>
                                             </div>
                                        </div>
                                        <div className="row">
                                             <div className="col">
                                                  <div className="d-flex align-items-end justify-content-center divFotoPerfil">
                                                       <img src={usrPic} className="img-circle fotoUsuarioPerfil" width="138" height="138" alt="Perfil usuario" />
                                                       <button onClick={(e) => setShowCargarImagen(true)} className="btn subirImagen" title="Like Button">
                                                            <img src={iconoCamara} alt="" />
                                                       </button>
                                                  </div>
                                             </div>
                                        </div>
                                        <div className="row">
                                             <div className="col">
                                                  <div className="nombreUsuarioPerfil d-flex align-items-center justify-content-center">
                                                       {nombre}
                                                  </div>
                                             </div>
                                        </div>
                                        <div className="row espacioPerfil">
                                             <div className="col">
                                                  <div className="row">
                                                       <div className="col">
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                 <span className='label1Perfil'>Learner desde</span>
                                                            </div>
                                                       </div>
                                                  </div>
                                                  <div className="row">
                                                       <div className="col">
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                 <span className='label1Perfil'>{fechaUTCaLocal(currentUser.createdAt)}</span>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                        <div className="row espacioPerfil">
                                             <div className="col">
                                                  <div className="row grupoLinksPerfil">
                                                       <div className="col">
                                                            <Link to={{ pathname: '/avisoLogeado' }}>
                                                                 <div className="row">
                                                                      <div className="col-4 d-flex align-items-center justify-content-end">
                                                                           <img alt="aviso de privacidad" src={aviso} />
                                                                      </div>
                                                                      <div className="col d-flex align-items-center justify-content-start">
                                                                           <span className='linkMenuPerfil'>Aviso de Privacidad</span>
                                                                      </div>
                                                                 </div>
                                                            </Link>
                                                       </div>
                                                  </div>
                                                  <div className="row grupoLinksPerfil">
                                                       <div className="col">
                                                            <a href="#/" onClick={() => { setShowCambiarClave(true) }}>
                                                                 <div className="row">
                                                                      <div className="col-4 d-flex align-items-center justify-content-end">
                                                                           <img alt="cambiar contraseña" src={llave} />
                                                                      </div>
                                                                      <div className="col d-flex align-items-center justify-content-start">
                                                                           <span className='linkMenuPerfil'>Cambiar contraseña</span>
                                                                      </div>
                                                                 </div>
                                                            </a>
                                                       </div>
                                                  </div>
                                                  <div className="row grupoLinksPerfil">
                                                       <div className="col">
                                                            <a href="#/" onClick={() => { cerrarSesion() }}>
                                                                 <div className="row">
                                                                      <div className="col-4 d-flex align-items-center justify-content-end">
                                                                           <img alt="cerrar sesión" src={puerta} />
                                                                      </div>
                                                                      <div className="col d-flex align-items-center justify-content-start">
                                                                           <span className='linkMenuPerfil'>Cerrar sesión</span>
                                                                      </div>
                                                                 </div>
                                                            </a>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className='col-12 col-lg-10 my-2 olineG'>
                                   <div className='row'>
                                        <div className='col'>
                                             <div className="col encabezadoPerfil d-flex align-items-center justify-content-center">
                                             ¿Quieres ganar los premios que tenemos para ti? Participa activamente consiguiendo la mayor cantidad posible de insignias.<br/> ¡Mientras más acumules, mayores probabilidades tienes de ganar!
                                             </div>                                   
                                        </div>
                                   </div>
                                   <div className="row my-3 my-lg-0">{/* RESPONSIVO */}
                                        <div className='col'>
                                             <div className="col mensajeEncabezadoPerfil d-flex align-items-center justify-content-center">
                                                  <span className='subMensajeEncabezadoPerfil'>Para saber cómo ganarlas, haz clic en cada una de ellas.</span>
                                             </div>
                                        </div>
                                   </div>
                                   <div className='row'>
                                        <div className='col'>
                                        <Accordion className="olineB"  defaultActiveKey="0">
                                             <Accordion.Toggle as={ButtonToolbar}  eventKey="0" >
                                                  <div className="col franjaNegraPerfil d-flex align-items-center text-center justify-content-between justify-content-lg-center">INSIGNIAS <div className='d-block d-lg-none iconAccordion'>&gt;</div></div>{/* RESPONSIVO */}
                                             </Accordion.Toggle>
                                             <Accordion.Collapse eventKey="0">
                                                  <div className="col align-items-center insigniasPerfil">
                                                       <div className="row">
                                                            {insignias.rows.map((insignia, key_item) => {
                                                                 var img = { fileName: '', fileUrl: '' };
                                                                 if (typeof (insignia.imagen) == "string") {
                                                                      try {
                                                                           img = JSON.parse(insignia.imagen)
                                                                      } catch (e) { }
                                                                 }

                                                                 if( programa.programaReducido === 1){
                                                                      if(insignia.llave_insignia === 'reto_extra'){
                                                                           return <></>
                                                                      }
                                                                 }

                                                                 if( insignia.completada === true && insignia.tipo === 2){
                                                                      console.log( insignia )
                                                                      console.log( insigniasCompletadas )
                                                                      let Ins = insigniasCompletadas.filter( ins => parseInt(ins.InsigniasUsuarios.insignia) === parseInt(insignia.id) )
                                                                      if(Ins.length > 0){
                                                                      if(Ins[0].InsigniasUsuarios.nivel > 1){
                                                                           if (typeof (insignia["imagenNivel"+Ins[0].InsigniasUsuarios.nivel]) == "string") {
                                                                                try {
                                                                                     img = JSON.parse(insignia["imagenNivel"+Ins[0].InsigniasUsuarios.nivel])
                                                                                } catch (e) { }
                                                                           }
                                                                      }
                                                                      }
                                                                 }

                                                                 return (
                                                                      <React.Fragment key={"insignia_" + insignia.id}>
                                                                           <div className="col espacioInsigniaPerfil">
                                                                                <div className="col d-flex align-items-center justify-content-center imagenInsigniaPerfil">
                                                                                     <button className='btn' onClick={(e) => detalleInsignia(e, insignia)} data-toggle="tooltip" data-placement="top" title="¿Como obtenerla?">
                                                                                          <img src={img.fileUrl} className={insignia.completada ? 'insignia' : 'insigniaNoGanada insignia'} width="150" height="150" alt="imagen insignia"/>
                                                                                     </button>
                                                                                </div>
                                                                           </div>
                                                                      </React.Fragment>
                                                                 );
                                                            })}
                                                       </div>
                                                  </div>
                                             </Accordion.Collapse>
                                        </Accordion>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className="row paddingAbajoDemoExtras olineG">
                              <Accordion className="col-12 col-lg-6 mt-3 olineB"  defaultActiveKey="0">
                                   <Accordion.Toggle as={ButtonToolbar}  eventKey="0" >  
                                        <div className="col franjaNegraPerfil d-flex align-items-center text-center justify-content-between justify-content-lg-center">MI AVANCE <div className='d-block d-lg-none iconAccordion'>&gt;</div></div>{/* RESPONSIVO */}
                                   </Accordion.Toggle>
                                   <Accordion.Collapse eventKey="0">
                                        <div className="col align-items-center demoExtras3 demo-height-default">{/** RESPONSIVO */}
                                             <div className="row">
                                                  <div className="col">
                                                       <div className="row justify-content-start">
                                                            {modulosPerfil.map((unidad, key_item) => {
                                                                 if( unidad.infoModulos.modulos === undefined ) return <></>
                                                                 let porcentageAvance = 0;
                                                                 let totalTerminados = 0;
                                                                 let modulosTerminados = 0, modulosTotales = 0;
                                                                 unidad.infoModulos.modulos.forEach( modulo => {
                                                                      porcentageAvance = porcentageAvance + modulo.progreso
                                                                      modulosTotales++
                                                                      if( modulo.moduloTerminado === true) {
                                                                           // totalTerminados++
                                                                           modulosTerminados++
                                                                      }
                                                                 })
                                                                 totalTerminados = modulosTerminados / modulosTotales;
                                                                 
                                                                 if(unidad.infoModulos.informacion.reto === true) totalTerminados++;
                                                                 if(unidad.infoModulos.informacion.encuesta === true) totalTerminados++;
                                                                 if(unidad.infoModulos.informacion.evaluacion === true) totalTerminados++;

                                                                 // porcentageAvance = porcentageAvance / (unidad.infoModulos.modulos.length)
                                                                 porcentageAvance = 100 * (totalTerminados / (unidad.infoModulos.modulos.length + 3));
                                                                 porcentageAvance = 100 * (totalTerminados / 4);

                                                                 return <React.Fragment key={"PerfilModulos_"+unidad.id}>
                                                                      <div className="col-12 col-lg-6 justify-content-center">
                                                                           <div className="col-11 paddingExtrasProgresoPerfil olineR">
                                                                                <div className="row d-flex align-items-center justify-content-center">
                                                                                     <div className="col">
                                                                                          <div className="row">
                                                                                               <div className="offset-0 ps-1 col-6 d-flex align-items-center justify-content-end">{/* RESPONSIVO */}
                                                                                                    <div className="col">
                                                                                                         <div className="row">
                                                                                                              <span className='textoRojoProgresoPerfil'>{"Unidad "+unidad.orden}</span>
                                                                                                         </div>
                                                                                                         <div className="row">
                                                                                                              <span className='textoProgresoPerfil'>{unidad.nombre}</span>
                                                                                                         </div>
                                                                                                         <div className="row">
                                                                                                              <span className='textoProgresoExtraPerfil'>{'Porcentaje de avance'}</span>
                                                                                                         </div>
                                                                                                    </div>
                                                                                               </div>
                                                                                               <div className="col-6 pe-3 d-flex align-items-center justify-content-end ajusteGraficaProgresoPerfil">{/* RESPONSIVO */}
                                                                                                    <Donut diameter={100} min={0} max={100} step={1} value={parseInt(porcentageAvance)} theme={{ donutColor: '#218E8E', donutThickness: '10', centerFocusedColor: 'white'  }} ariaLabelledBy={'my-label'}/>                                                                         
                                                                                               </div>
                                                                                          </div>
                                                                                          <div className="row">
                                                                                               <div className="offset-0 px-3 col-12 ajusteChecksProgresoPerfil">{/* RESPONSIVO */}
                                                                                                    {unidad.infoModulos.modulos.map((modulo, key_item) => {
                                                                                                         return (
                                                                                                              <div className="row d-flex align-items-center justify-content-start">
                                                                                                                   <div className="col-1"><img src={flechaRoja} alt="flecha roja" width="10" height="9"/></div>
                                                                                                                   <div className="col-9">{/* RESPONSIVO */}<span className='textoChecksProgresoPerfil'>{"MÓDULO "+modulo.orden}</span></div>
                                                                                                                   <div className="col-2 d-flex align-items-end justify-content-end">{/* RESPONSIVO */}<input type="checkbox" className='checkMarkPerfil' defaultChecked={modulo.moduloTerminado}/></div>
                                                                                                              </div>
                                                                                                         )
                                                                                                    })}
                                                                                                    <div className="row d-flex align-items-center justify-content-start">
                                                                                                         <div className="col-1"><img src={flechaRoja} alt="flecha roja" width="10" height="9"/></div>
                                                                                                         <div className="col-9">{/* RESPONSIVO */}<span className='textoChecksProgresoPerfil'>RETO ON THE JOB</span></div>
                                                                                                         <div className="col-2 d-flex align-items-end justify-content-end">{/* RESPONSIVO */}<input type="checkbox" className='checkMarkPerfil' defaultChecked={unidad.infoModulos.informacion.reto}/></div>
                                                                                                    </div>
                                                                                                    <div className="row d-flex align-items-center justify-content-start">
                                                                                                         <div className="col-1"><img src={flechaRoja} alt="flecha roja" width="10" height="9"/></div>
                                                                                                         <div className="col-9">{/* RESPONSIVO */}<span className='textoChecksProgresoPerfil'>EVALUACIÓN DE LA UNIDAD</span></div>
                                                                                                         <div className="col-2 d-flex align-items-end justify-content-end">{/* RESPONSIVO */}<input type="checkbox" className='checkMarkPerfil' defaultChecked={unidad.infoModulos.informacion.encuesta}/></div>
                                                                                                    </div>
                                                                                                    <div className="row d-flex align-items-center justify-content-start">
                                                                                                         <div className="col-1"><img src={flechaRoja} alt="flecha roja" width="10" height="9"/></div>
                                                                                                         <div className="col-9">{/* RESPONSIVO */}<span className='textoChecksProgresoPerfil'>AUTOEVALUACIÓN DE APRENDIZAJES CLAVE</span></div>
                                                                                                         <div className="col-2 d-flex align-items-end justify-content-end">{/* RESPONSIVO */}<input type="checkbox" className='checkMarkPerfil' defaultChecked={unidad.infoModulos.informacion.evaluacion}/></div>
                                                                                                    </div>
                                                                                                    {/*<div className="row d-flex align-items-center justify-content-start">
                                                                                                         <div className="col-1"><img src={flechaRoja} alt="flecha roja" width="10" height="9"/></div>
                                                                                                         <div className="col-9"><span className='textoChecksProgresoPerfil'>SESIÓN 1 - 1</span></div>
                                                                                                         <div className="col-2 d-flex align-items-end justify-content-end"><input type="checkbox" className='checkMarkPerfil' defaultChecked={false}/></div>
                                                                                                    </div> */ }
                                                                                               </div>
                                                                                          </div>
                                                                                     </div>
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </React.Fragment>
                                                            })}
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </Accordion.Collapse>
                              </Accordion>
                              <div className="col-12 col-lg-6 my-3 olineB">
                                   <div className="row">
                                   <Accordion className="col-12 col-lg-6 mb-3 olineB"  defaultActiveKey="0">
                                        <Accordion.Toggle as={ButtonToolbar}  eventKey="0" >  
                                             <div className="col franjaNegraPerfil d-flex align-items-center text-center justify-content-between justify-content-lg-center">MI CALENDARIO <div className='d-block d-lg-none iconAccordion'>&gt;</div></div>{/* RESPONSIVO */}
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0">
                                             <div className="col align-items-center demoExtras3">
                                                  <div className="row">
                                                            <div className="col todoElAltoPerfil align-items-center">
                                                                 <div className="row">
                                                                      <div className="col d-flex align-items-center justify-content-center margenTopExtrasPerfil">
                                                                           <span className='textoRojoDemoExtra'>UNIDAD DE APRENDIZAJE EN CURSO</span>
                                                                      </div>
                                                                 </div>
                                                                 <div className="row">
                                                                      <div className="col">
                                                                           <div className="row d-flex align-items-center justify-content-center margenTopExtrasPerfil">
                                                                                <div className="col-auto divValorDatoDemo2ExtrasPerfil">
                                                                                     <span className='textoDatoDemoExtrasPerfil'>{unidadEnCurso.orden}</span>
                                                                                </div>
                                                                                <div className="col-auto divValorDatoDemo2ExtrasPerfil">
                                                                                     <span className='textoDatoDemoExtrasPerfil'>{unidadEnCurso.nombre}</span>
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                                 <div className="row margenTopExtrasPerfil">
                                                                      <div className="col align-items-center">
                                                                           <div className="row">
                                                                                <div className="col d-flex align-items-center justify-content-center margenTopExtrasPerfil">
                                                                                     <img src={iconoBandera} className="colorIconoBandera" width="35" height="35" alt="bandera" />
                                                                                </div>
                                                                           </div>
                                                                           <div className="row">
                                                                                <div className="col">
                                                                                     <div className="row">
                                                                                          <div className="col d-flex align-items-center justify-content-center margenTopDemoExtrasPerfil">
                                                                                               <span className='textoRojoDemo2Extra'>Fecha límite para envío de</span>
                                                                                          </div>
                                                                                     </div>
                                                                                     <div className="row">
                                                                                          <div className="col d-flex align-items-center justify-content-center">
                                                                                               <span>RETO ON THE JOB</span>
                                                                                          </div>
                                                                                     </div>  
                                                                                </div>
                                                                           </div>
                                                                           <div className="row">
                                                                                <div className="col">
                                                                                     <div className="row d-flex align-items-center justify-content-center margenTopDemoExtrasPerfil">
                                                                                          <div className="col-auto divValorDatoDemo2ExtrasPerfil">
                                                                                               <span className='textoDatoDemoExtrasPerfil'>{unidadEnCurso.infoModulos.configuracion.retoFechaDate.getDate()}</span>
                                                                                          </div>
                                                                                          <div className="col-auto divValorDatoDemo2ExtrasPerfil">
                                                                                               <span className='textoDatoDemoExtrasPerfil'>{unidadEnCurso.infoModulos.configuracion.retoFechaDate.getMonth()+1}</span>
                                                                                          </div>
                                                                                          <div className="col-auto divValorDatoDemo2ExtrasPerfil">
                                                                                               <span className='textoDatoDemoExtrasPerfil'>{unidadEnCurso.infoModulos.configuracion.retoFechaDate.getFullYear()}</span>
                                                                                          </div>
                                                                                     </div>
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                  </div>
                                             </div>
                                        </Accordion.Collapse>
                                   </Accordion>
                                   <Accordion className="col-12 col-lg-6 mb-3 olineB"  defaultActiveKey="0">
                                        <Accordion.Toggle as={ButtonToolbar}  eventKey="0" >                
                                             <div className="col franjaNegraPerfil d-flex align-items-center text-center justify-content-between justify-content-lg-center">NIVEL DE ACTIVIDAD SOCIAL <div className='d-block d-lg-none iconAccordion'>&gt;</div></div>{/* RESPONSIVO */}             
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0">
                                             <div className="col align-items-center demoExtras3">
                                                  <div className="row">
                                                       <div className="col paddingExtrasNivelPerfil">
                                                            <div className="row">
                                                                 <div className="col d-flex align-items-center justify-content-center">
                                                                      <span className='textoBaseNivelPerfil'>MI NIVEL</span>
                                                                 </div>
                                                            </div>
                                                            <div className="row margenTopDemoExtrasPerfil">
                                                                 <div className="col  d-flex align-items-center justify-content-center">
                                                                      <div className='divNivelPerfil d-flex align-items-center justify-content-center'>
                                                                           <span className='textoNivelPerfil'>{(nivelActSocial < 40 ? 0 : nivelActSocial < 70 ? 1 : (nivelActSocial >= 100 ? 3 : 2))}</span>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                            <div className="row margenTopExtrasPerfil">
                                                                 <div className="col d-flex align-items-center justify-content-start">
                                                                      <img src={manita1} alt="manita"/>
                                                                 </div>
                                                                 <div className="col d-flex align-items-center justify-content-center">
                                                                      <img src={manita2} alt="manita"/>
                                                                 </div>
                                                                 <div className="col d-flex align-items-center justify-content-end">
                                                                      <img src={manita3} alt="manita"/>
                                                                 </div>
                                                            </div>
                                                            <div className="row margenTopDemoExtrasPerfil">
                                                                 <div className="col progreso flex-grow-1">
                                                                      <div className="progress">
                                                                           <div className="progress-bar progress-bar-striped bg-custom" role="progressbar" style={{width: ((nivelActSocial - 40) * 100) / 60+'%'}} aria-valuenow={((nivelActSocial - 40) * 100) / 60} aria-valuemin="0" aria-valuemax="100"></div>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                            <div className="row margenTopDemoExtrasPerfil">
                                                                 <div className="col">
                                                                      <div className="row">
                                                                           <div className="col d-flex align-items-center justify-content-start">
                                                                                <span className='textoRojoNivelPerfil'>NIVEL 1</span>
                                                                           </div>
                                                                      </div>
                                                                      <div className="row">
                                                                           <div className="col d-flex align-items-center justify-content-start margenIzquerdaNivelPerfil">
                                                                                <span className='textoExtraNivelPerfil'>40/69</span>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                                 <div className="col">
                                                                      <div className="row">
                                                                           <div className="col d-flex align-items-center justify-content-center">
                                                                                <span className='textoRojoNivelPerfil'>NIVEL 2</span>
                                                                           </div>
                                                                      </div>
                                                                      <div className="row">
                                                                           <div className="col d-flex align-items-center justify-content-center">
                                                                                <span className='textoExtraNivelPerfil'>70/99</span>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                                 <div className="col">
                                                                      <div className="row">
                                                                           <div className="col d-flex align-items-center justify-content-end">
                                                                                <span className='textoRojoNivelPerfil'>NIVEL 3</span>
                                                                           </div>
                                                                      </div>
                                                                      <div className="row">
                                                                           <div className="col d-flex align-items-center justify-content-end margenDerechaNivelPerfil">
                                                                                <span className='textoExtraNivelPerfil'>+100</span>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  </div>               
                                             </div>
                                        </Accordion.Collapse>
                                   </Accordion>
                                   <Accordion className="col-12 col-lg-6 mb-3 olineB"  defaultActiveKey="0">
                                        <Accordion.Toggle as={ButtonToolbar}  eventKey="0" >                
                                             <div className="col franjaNegraPerfil d-flex align-items-center text-center justify-content-between justify-content-lg-center">MIS RETOS ON THE JOB <div className='d-block d-lg-none iconAccordion'>&gt;</div></div>{/* RESPONSIVO */}            
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" className='tomarTodoElEspacioVerticalPerfil'>
                                             <div className="col align-items-center demoExtras4">
                                                  <div className="row align-items-center tomarTodoElEspacioVerticalPerfil">
                                                       <div className="col">
                                                            <div className="row align-items-center justify-content-center margenTopExtrasPerfil">
                                                                 <div className="col d-flex align-items-center justify-content-center">
                                                                      <span className='textoDemoExtrasPerfil'>PUNTAJE PROMEDIO OTORGADO</span>
                                                                 </div>
                                                            </div>
                                                            <div className="row margenTopExtrasPerfil">
                                                                 <div className="col d-flex align-items-center justify-content-center">
                                                                      <span className='textoLabelVerdePerfil'>Representa el 40% de la calificación final</span>
                                                                 </div>
                                                            </div>
                                                            {modulosPerfil.map((unidad, key_item) => {
                                                                 let PromedioUnidad = unidad.PromedioUnidades.filter( prom => prom.orden+1 === unidad.orden)
                                                                 
                                                                 console.log(PromedioUnidad);
                                                                 return (
                                                                      <div className="row align-items-center margenTopExtrasPerfil">
                                                                           <div className="offset-1 col-5 justify-content-start">
                                                                                <span className='textoDemoExtrasPerfil'>{"Unidad "+unidad.orden}</span>
                                                                           </div>
                                                                           <div className="col-4">
                                                                                <div className="row d-flex justify-content-end">
                                                                                     <div className="col-auto divValorDatoDemoExtrasPerfil">
                                                                                          <span className='textoDatoDemoExtrasPerfil'>{(PromedioUnidad[0]['promedioReto'] > 0 ? PromedioUnidad[0]['promedioReto'].toFixed(1) : '')}</span>
                                                                                     </div>
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 )
                                                            })}
                                                            <div className="row align-items-center margenTopExtrasPerfil">
                                                                 <div className="offset-1 col-5 justify-content-start">
                                                                      <span className='textoDatoBoldDemoExtrasPerfil'>{"Promedio"}</span>
                                                                 </div>
                                                                 <div className="col-4">
                                                                      <div className="row d-flex justify-content-end">
                                                                           <div className="col-auto divValorDatoDemoExtrasPerfil">
                                                                                <span className='textoDatoBoldDemoExtrasPerfil'><PromedioGeneralCalculoReto/></span>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                            <br />
                                                       </div>
                                                  </div>             
                                             </div>
                                        </Accordion.Collapse>
                                   </Accordion>
                                   <Accordion className="col-12 col-lg-6 mb-3 olineB"  defaultActiveKey="0">
                                        <Accordion.Toggle as={ButtonToolbar}  eventKey="0" >                
                                             <div className="col franjaNegraPerfil d-flex align-items-center text-center justify-content-between justify-content-lg-center">PROGRAMA DE RETROALIMENTACIÓN DE DESEMPEÑO <div className='d-block d-lg-none iconAccordion'>&gt;</div></div>{/* RESPONSIVO */}            
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0">
                                             <div className="col align-items-center demoExtras4">
                                                  <div className="row align-items-center tomarTodoElEspacioVerticalPerfil">
                                                       <div className="col">
                                                            <div className="row align-items-center justify-content-center margenTopExtrasPerfil">
                                                                 <div className="col d-flex align-items-center justify-content-center">
                                                                      <span className='textoDemoExtrasPerfil'>RETROALIMENTACIÓN DE MI JEFE</span>
                                                                 </div>
                                                            </div>
                                                            <div className="row margenTopExtrasPerfil">
                                                                 <div className="col d-flex align-items-center justify-content-center">
                                                                      <span className='textoLabelVerdePerfil'>Representa el 30% de la calificación final</span>
                                                                 </div>
                                                            </div>
                                                            {modulosPerfil.map((unidad, key_item) => {
                                                                 let PromedioJefe = unidad.PromedioJefe.filter( prom => prom.orden+1 === unidad.orden)
                                                                 let promJefe = '';
                                                                 if(PromedioJefe.length > 0){
                                                                      promJefe = Math.round( PromedioJefe[0]['promedioJefe'] * 10 ) / 10
                                                                 }
                                                                 if(promJefe <= 0){
                                                                      promJefe = ''
                                                                 }
                                                                 return (
                                                                      <div className="row align-items-center margenTopExtrasPerfil">
                                                                           <div className="offset-1 col-5 justify-content-start">
                                                                                <span className='textoDemoExtrasPerfil'>{'Mi calificación / Unidad ' + (unidad.orden)}</span>
                                                                           </div>
                                                                           <div className="col-4">
                                                                                <div className="row d-flex justify-content-end">
                                                                                     <div className="col-auto divValorDatoDemoExtrasPerfil">
                                                                                          <span className='textoDatoDemoExtrasPerfil'>{promJefe}</span>
                                                                                     </div>
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 )
                                                            })}
                                                            <div className="row align-items-center margenTopExtrasPerfil">
                                                                 <div className="offset-1 col-5 justify-content-start">
                                                                      <span className='textoDatoBoldDemoExtrasPerfil'>{"Promedio"}</span>
                                                                 </div>
                                                                 <div className="col-4">
                                                                      <div className="row d-flex justify-content-end">
                                                                           <div className="col-auto divValorDatoDemoExtrasPerfil">
                                                                                <span className='textoDatoBoldDemoExtrasPerfil'><PromedioGeneralCalculo/></span>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                            <br />
                                                       </div>
                                                  </div>
                                             </div>
                                        </Accordion.Collapse>
                                   </Accordion>
                                   <Accordion className="col-12 col-lg-6 mb-3 olineB"  defaultActiveKey="0">
                                        <Accordion.Toggle as={ButtonToolbar}  eventKey="0" >                
                                             <div className="col franjaNegraPerfil d-flex align-items-center text-center justify-content-between justify-content-lg-center">EVALUACIÓN FINAL<div className='d-block d-lg-none iconAccordion'>&gt;</div></div>{/* RESPONSIVO */}            
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0">
                                             <div className="col align-items-center divBlancoPerfil">
                                                  <div className="row align-items-center tomarTodoElEspacioVerticalPerfil">
                                                       <div className="col">
                                                            <div className="row margenTopExtrasPerfil">
                                                                 <div className="col d-flex align-items-center justify-content-center">
                                                                      <span className='textoLabelVerdePerfil'>Representa el 30% de la calificación final</span>
                                                                 </div>
                                                            </div>
                                                            <div className="row menosAire">
                                                                 <div className="col d-flex align-items-center justify-content-center">
                                                                      <div className={'d-flex align-items-center justify-content-center ' + (evaluacionFinal.evaluacion === null || evaluacionFinal.evaluacion === '' ? 'circuloPendienteCaliFinalResultadosPerfil' : evaluacionFinal.calificacion >= 8 ? 'circuloPositivoEvFinalResultadosPerfil' : 'circuloNegativoCaliFinalResultadosPerfil')}>
                                                                           <span className={evaluacionFinal.evaluacion === null || evaluacionFinal.evaluacion === '' ? 'textoCirculoPendienteCaliFinalResultadosPerfil' : evaluacionFinal.calificacion >= 8 ? 'textoCirculoPositivoEvFinalResultadosPerfil' : 'textoCirculoNegativoCaliFinalResultadosPerfil'}>{evaluacionFinal.evaluacion === null || evaluacionFinal.evaluacion === '' ? 'Pendiente' : (evaluacionFinal.calificacion != null ? evaluacionFinal.calificacion.toFixed(1) : 0)}</span>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                            <br />
                                                       </div>
                                                  </div>             
                                             </div>
                                        </Accordion.Collapse>
                                   </Accordion>
                                   <Accordion className="col-12 col-lg-6 mb-3 olineB"  defaultActiveKey="0">
                                        <Accordion.Toggle as={ButtonToolbar}  eventKey="0" >                
                                             <div className="col franjaNegraPerfil d-flex align-items-center text-center justify-content-between justify-content-lg-center">CALIFICACIÓN FINAL<div className='d-block d-lg-none iconAccordion'>&gt;</div></div>{/* RESPONSIVO */}            
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0">
                                             <div className={"col align-items-center " + (califinal === 'Pendiente' ? 'divBlancoPerfil' : califinal >= 8 ? 'divVerdePerfil' : 'divBlancoPerfil')}>
                                                  <div className="row align-items-center tomarTodoElEspacioVerticalPerfil">
                                                       <div className="col">
                                                            <div className="row menosAire">
                                                                 <div className="col d-flex align-items-center justify-content-center">
                                                                      <div className={'d-flex align-items-center justify-content-center ' + (califinal === 'Pendiente' ? 'circuloPendienteCaliFinalResultadosPerfil' : califinal >= 8 ? 'circuloPositivoCaliFinalResultadosPerfil' : 'circuloNegativoCaliFinalResultadosPerfil')}>
                                                                           <span className={califinal === 'Pendiente' ? 'textoCirculoPendienteCaliFinalResultadosPerfil' : califinal >= 8 ? 'textoCirculoPositivoCaliFinalResultadosPerfil' : 'textoCirculoNegativoCaliFinalResultadosPerfil'}>{califinal === 'Pendiente' ? 'Pendiente' : califinal.toFixed(1)}</span>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                            <div className="row margenTopExtrasPerfil">
                                                                 <div className="col d-flex align-items-center justify-content-center">
                                                                      <span className={califinal >= 8 ? 'subTextoPositivoCaliFinalPerfil' : 'subTextoNegativoCaliFinalPerfil'}>{califinal === 'Pendiente' ? '' : califinal >= 8 ? 'Aprobado' : 'No aprobado'}</span>
                                                                 </div>
                                                            </div>
                                                            <br />
                                                       </div>
                                                  </div>             
                                             </div>
                                        </Accordion.Collapse>
                                   </Accordion>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </React.Fragment>
     );
}
