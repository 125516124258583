import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { v4 as uuid } from 'uuid';
import { CONSTANT } from "../../static/constants";
import iconUpload from '../../assets/img/Upload.png'

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
    color: '#000000'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 150,
    height: 150,
    padding: 4,
    boxSizing: 'border-box',
    justifyContent: 'center'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

export default function Uploader(props) {

    const [files, setFiles] = useState([]);
    var fileUploadConfig = {};

    useEffect(() => () => {
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    useEffect(() => {
        if( props.input_thumb.fileUrl !== ''){
            var url = ''
            if( props.input_thumb.fileName.match(/\.(jpg|jpeg|png|gif|svg)$/) ){
                url = props.input_thumb.fileUrl;
            }else{
                url = '/assets/images/thumb_file.png';
            }
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'arraybuffer';
            xhr.onload = function() {
                let _file = new File([this.response], props.input_thumb.fileName,{type:props.input_thumb.fileType, lastModified:new Date().getTime()});
                setFiles([_file].map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })))
            };
            xhr.send();
        }
    }, [props.input_thumb])

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: props.accepted_files,
        maxFiles: 1,
        onDrop: acceptedFiles => {
            FileS3Upload(acceptedFiles);
            setFiles(acceptedFiles.map(file => 
                Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })
            ));
        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                {files.length <= 0 &&  (
                    <React.Fragment>
                        <img src={iconUpload} alt="" className="paddingAbajo"/>
                        <p>Arrastra el archivo o da click para seleccionarlo de tu equipo</p>
                    </React.Fragment>
                )}
                {files.length > 0 && file.name.match(/\.(jpg|jpeg|png|gif|svg)$/) && (
                    <>
                    <img src={file.preview} style={img} alt="Vista previa" />
                    </>
                )}
                {files.length > 0 && !file.name.match(/\.(jpg|jpeg|png|gif|svg)$/) && (
                    <>
                    <img src='/assets/images/thumb_file.png' style={img} alt="Vista previa" />
                    </>
                )}                
            </div>
        </div>
    ));

    const FileS3Upload = (acceptedFiles) => {
        acceptedFiles.forEach(file => {
            var extension = "";
            var ext = /^.+\.([^.]+)$/.exec(file.name);
            if( ext === null ) {
                extension =  ""
             } else {
                extension = ext[1];
             }
            // Upload all files one by one to S3
            const token = localStorage.getItem(CONSTANT.AUTH_TOKEN);
            axios.post(process.env.REACT_APP_API_URL + `/api/s3_sign`, {
                fileName: uuid() + '.' + extension,
                fileType: file.type,
                fileKey: props.s3_key
            }, {
                headers: {
                    'Authorization': `${token}`
                }
            })
                .then(function (response) {
                    var returnData = response.data.data.returnData;
                    var signedRequest = returnData.signedRequest;
                    var _url = returnData.url;
                    var options = {
                        headers: {
                            'Content-Type': file.type,
                            'x-amz-acl': 'public-read'
                        }
                    };
                    // regresamos la funcion para subir el archivo.. 
                    props.filesCallback(props.input_key, uploadFile)
                    fileUploadConfig = { 
                        aws_signedRequest: signedRequest, 
                        aws_options: options,
                        fileName: file.name, 
                        fileType: file.type, 
                        fileUrl: _url,
                        file: file
                    }             
                })
                .then(function (result) {
                    // console.log("Result: ", result)
                })
                .catch(function (err) {
                    console.log('Error: ', err);
                });
        });
    }

    const uploadFile = ( onSubmit, datos, key ) => {
        axios.put(
            fileUploadConfig.aws_signedRequest, 
            fileUploadConfig.file,
            fileUploadConfig.aws_options)
        .then(function (result_upload) {
            datos[key] = JSON.stringify({ 
                fileName: fileUploadConfig.fileName, 
                fileType: fileUploadConfig.fileType, 
                fileUrl: fileUploadConfig.fileUrl 
            })  
            onSubmit(datos)
        })
        .catch(function (err_upload) {
            console.log('ToDo... Error al subir archivo', err_upload);
            onSubmit(datos)
        });
    }

    return (
        <div className="containerUploader">
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {!files.length > 0 && (
                    <React.Fragment>
                        <img src={iconUpload} alt="" className="paddingAbajo"/>
                        <br/>
                        <p>Arrastra el archivo o da click para seleccionarlo de tu equipo</p>
                    </React.Fragment>
                )}
                <aside style={thumbsContainer}>
                    {thumbs}
                </aside>
            </div>
        </div>
    );
}