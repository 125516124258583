import React from "react";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";
import AuthToken from "modules/shared/authToken";

const CmsPublicRouter = ({ currentUser, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if ( AuthToken.get() ) {
          return <Redirect to="/" />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

export default CmsPublicRouter;

CmsPublicRouter.propTypes = {
  currentUser: PropTypes.object.isRequired,
  component: PropTypes.func.isRequired,
};
