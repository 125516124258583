import { CONSTANT } from '../../static/constants'

const initialState = {
     insignias: [],
     insigniasCompletadas: [],
     olas: false,
     loading: true,
     error: false,
 };

 const reducer = (state = initialState, { type, payload }) => {
     switch (type) {
         case CONSTANT.OLAS_BY_USUARIO_START:
             return {
                 ...state,
                 usuarioId: payload,
                 insignias: {rows: []},
                 insigniasCompletadas: [],
                 error: false,
                 loading: true,
             };
         case CONSTANT.OLAS_BY_USUARIO_SUCCESS:
             return {
                 ...state,
                 olas: payload.data.olas || false,
                 error: false,
                 loading: false,
             };
         case CONSTANT.OLAS_BY_USUARIO_ERROR:
             return {
                 ...state,
                 error: payload,
                 loading: false,
             };
        case CONSTANT.INSIGNIAS_BY_USUARIO_START:
            return {
                ...state,
                usuarioId: payload,
                insignias: {rows: []},
                insigniasCompletadas: [],
                error: false,
                loading: true,
            };
        case CONSTANT.INSIGNIAS_BY_USUARIO_SUCCESS:
            return {
                ...state,
                insignias: payload.data.Insignias,
                insigniasCompletadas: payload.data.Insignias_Completadas,
                error: false,
                loading: false,
            };
        case CONSTANT.INSIGNIAS_BY_USUARIO_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };
         default:
             return state;
     }
 }
 
 export default reducer