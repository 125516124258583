import React from "react";
import AppRouter from "./routes/AppRouter";
import { Provider } from "react-redux";
import { store, persistor } from "modules/store";
import { PersistGate } from 'redux-persist/integration/react'
import { Toaster } from 'react-hot-toast';

const App = () => {
  console.log('NEXXO - V1.0.0');

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div><Toaster position="top-right" toastOptions={{ style: {zIndex: 20000} }} /></div>
        <AppRouter />
      </PersistGate>
    </Provider>
  );
};

export default App;