import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { CONSTANT } from '../../../static/constants';
// import AuthToken from "modules/shared/authToken";
import logo from '../../../assets/img/logoLearner.png';
import Notificaciones from '../../../components/frontend/notificaciones/Notificaciones';
import "bootstrap-icons/font/bootstrap-icons.css";
import { Link } from "react-router-dom";
import './Header.css';
import User from '../../../assets/img/iconos/UserIcon.svg';
import {cargaUnidades} from 'modules/services/services'
import DetalleInsignia from 'components/frontend/detalleInignia/InsigniaGanada';

export default function Header() {
     const [show, setShow] = useState(false);
     const [insignia, setInsignia] = useState({});
	const dispatch = useDispatch();
	const currentUser = useSelector((state) => state.auth);
	const { programa, loading, error } = useSelector((state) => state.home);
	document.body.classList.add('layout-top-nav');
	useEffect(() => {
		dispatch(cargaUnidades());		
	}, [dispatch]);

	if(loading) return <></>
	if(error) return <></>
	if(programa === undefined) return <></>

	var obj = { fileName: '', fileUrl: User };
	if (typeof (currentUser.FOTO) == "string") {
		try {
			obj = JSON.parse(currentUser.FOTO)
		} catch (e) { }
	}

	const detalleInsignia = (e, detalles) => {
          e.preventDefault();
          // let Ins = insigniasCompletadas.filter( ins => parseInt(ins.InsigniasUsuarios.insignia) === parseInt(detalles.id) )
          // if(Ins.length > 0){
          //      detalles.nivel = Ins[0].InsigniasUsuarios.nivel
          // }
          setShow(true);
          setInsignia(detalles);
     }
	return (
		<> 
			{show ? <DetalleInsignia show={show} setShow={setShow} insignia={insignia} /> : null}
			<nav id="navbarLift" className="main-header navbar navbar-expand-md navbar-light navbar-white">
				<div className="container-fluid">
					<div className="logoContainer">
						<a href="/" className="navbar-brand">
							<img src={logo} className="logo" alt="logo" />
						</a>
					</div>
					<div className="separador d-none d-sm-none d-md-block"></div>					
					<span className="nombrePrograma d-none d-sm-none d-md-block">{programa.nombre || ''}</span>

					<ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
						<li className="nav-item ajusteNombreLearner d-none d-lg-block">
								<span className="navbar-text nombreUsuario text-right">{`${currentUser.NOMBRE} ${currentUser.APELLIDO_P} ${currentUser.APELLIDO_M||''}`}</span>
						</li>

						<li className="nav-item">
								<Link to={{pathname: '/perfil'}} className="nav-link navbar-brand">
									<img src={obj.fileUrl} className="img-circle fotoPerfil" width="60" height="60" alt="Perfil usuario" />
								</Link>
						</li>
						<Notificaciones />
					</ul>
				</div>
			</nav>
		</>
	)
}
