import { CONSTANT } from '../../static/constants'

const initialState = {
    filtrosUsuarioGeneracion: 0,
    filtrosUsuarioPrograma: 0,
    filtrosRotjGeneracion: 0,
    filtrosRotjPrograma: 0,
    filtrosRotjUnidad: 0,
    filtrosGralGeneracion: 0,
    filtrosGralPrograma: 0,
    filtrosGralUnidad: 0,
    filtrosGralModulo: 0,
    filtroGralFechaIn:new Date("2022-01-01 00:00:00"),
    filtroGralFechaFin: new Date(),
    opcionesVisibles: {
        informacion_gral: false,
        poblacion: false,
        desempenio: false,
        avance_programa: false,
        objetos: false,
        participacion_foro: false,
        sesiones_virtuales: false,
        encuesta: false
    },
    preFiltrosGralGeneracion: 0,
    preFiltrosGralPrograma: 0,
    preFiltrosGralUnidad: 0,
    preFiltrosGralModulo: 0,
    preFiltroGralFechaIn:new Date("2022-01-01 00:00:00"),
    preFiltroGralFechaFin: new Date(),
    preOpcionesVisibles: {
        informacion_gral: false,
        poblacion: false,
        desempenio: false,
        avance_programa: false,
        objetos: false,
        participacion_foro: false,
        sesiones_virtuales: false,
        encuesta: false
    },
    filtroGeneracionJefe: 0
 };

 const reducer = (state = initialState, { type, payload }) => {
     switch (type) {
        case CONSTANT.FILTRO_USUARIOS_GENERACION:
            return {
                ...state,
                filtrosUsuarioGeneracion: payload
            };
        case CONSTANT.FILTRO_USUARIOS_PROGRAMA:
            return {
                ...state,
                filtrosUsuarioPrograma: payload
            };
        case CONSTANT.FILTRO_ROTJ_GENERACION:
            return {
                ...state,
                filtrosRotjGeneracion: payload
            };
        case CONSTANT.FILTRO_ROTJ_PROGRAMA:
            return {
                ...state,
                filtrosRotjPrograma: payload
            };
        case CONSTANT.FILTRO_ROTJ_UNIDAD:
            return {
                ...state,
                filtrosRotjUnidad: payload
            };
        case CONSTANT.FILTRO_GRAL_GENERACION:
            return {
                ...state,
                filtrosGralGeneracion: payload
            };
        case CONSTANT.FILTRO_GRAL_PROGRAMA:
            return {
                ...state,
                filtrosGralPrograma: payload
            };
        case CONSTANT.FILTRO_GRAL_UNIDAD:
            return {
                ...state,
                filtrosGralUnidad: payload
            };
        case CONSTANT.FILTRO_GRAL_MODULO:
            return {
                ...state,
                filtrosGralModulo: payload
            };
        case CONSTANT.FILTRO_GRAL_OPCIONES_VISIBLES:
            return {
                ...state,
                opcionesVisibles: payload
            };
        case CONSTANT.FILTRO_GRAL_FECHA_IN:
            return {
                ...state,
                filtroGralFechaIn: payload
            };
        case CONSTANT.FILTRO_GRAL_FECHA_FIN:
            return {
                ...state,
                filtroGralFechaFin: payload
            };
        case CONSTANT.FILTRO_GRAL_PRE_GENERACION:
            return {
                ...state,
                preFiltrosGralGeneracion: payload
            };
        case CONSTANT.FILTRO_GRAL_PRE_PROGRAMA:
            return {
                ...state,
                preFiltrosGralPrograma: payload
            };
        case CONSTANT.FILTRO_GRAL_PRE_UNIDAD:
            return {
                ...state,
                preFiltrosGralUnidad: payload
            };
        case CONSTANT.FILTRO_GRAL_PRE_MODULO:
            return {
                ...state,
                preFiltrosGralModulo: payload
            };
        case CONSTANT.FILTRO_GRAL_PRE_OPCIONES_VISIBLES:
            return {
                ...state,
                preOpcionesVisibles: payload
            };
        case CONSTANT.FILTRO_GRAL_PRE_FECHA_IN:
            return {
                ...state,
                preFiltroGralFechaIn: payload
            };
        case CONSTANT.FILTRO_GRAL_PRE_FECHA_FIN:
            return {
                ...state,
                preFiltroGralFechaFin: payload
            };
        
        case CONSTANT.FILTRO_JEFE_GENERACION:
            return {
                ...state,
                filtroGeneracionJefe: payload
            };
        
         default:
             return state;
     }
 }
 
 export default reducer