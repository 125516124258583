import React from 'react'
import flechaAcordeon from '../../../assets/img/iconos/flechaAcordeon.svg'

export default function ResultadoEvaluacion({ evaluacion, titulo, fechaEvaluacion, claseTitulo}) {

     var form = null;
     if(typeof evaluacion === 'object'){
          form = evaluacion;
     }else {
          form = JSON.parse(evaluacion);
     }

     const fechaUTCaLocal = (d) => {
          let localDate = new Date(d)
          if (isNaN(localDate.getTime())) return ""
          const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
          return "Publicado el " + localDate.getDate() + "/" + meses[localDate.getMonth()] + "/" + localDate.getFullYear() + " a las " + localDate.getHours() + ":" + localDate.getMinutes()
     }

     return (
          <React.Fragment key={`AutoEv_`+titulo}>
               <div className="card">
                    <div className="card-header" id="headingOne">
                         <button className="btn btn-block text-left" type="button" data-toggle="collapse" data-target={`#Collapse_${titulo.replace(/\s/g, "")}`} aria-expanded="false" aria-controls={`Collapse_${titulo.replace(/\s/g, "")}`}>
                              <div className="row">
                                   <div className="col-10">
                                        <h2 className={claseTitulo ? "mb-0 encabezadoAcordeonResultados " + claseTitulo : "mb-0 encabezadoAcordeonResultados"}>
                                             {titulo}
                                        </h2>
                                   </div>
                                   <div className="col-2">
                                        <div className="d-flex align-items-center justify-content-end">
                                             <img src={flechaAcordeon} alt="" className="flechaAcordeon" />
                                        </div>
                                   </div>
                              </div>
                         </button>
                    </div>
                    <div id={`Collapse_${titulo.replace(/\s/g, "")}`} className="collapse resultadosAcordion" aria-labelledby="headingOne" data-parent="#accordionExample">
                         <div className="card-body">
                              {fechaEvaluacion != null && 
                                   <div className="row">
                                        <div className="col">
                                             <span className="fechaEvaluacionResultados">{fechaUTCaLocal(fechaEvaluacion)}</span>
                                        </div>
                                   </div>
                              }
                              {form.map((data, key) => {
                                   return (
                                        <React.Fragment key={"Preguntas_CONT_"+key}>
                                             {data.seccion.preguntas.map((pregunta, key) => {
                                                  if (pregunta.pregunta.tipo === "rango") {
                                                       return (
                                                            <React.Fragment key={"Pregunta_R_"+key}>
                                                                 <div className="row aire">
                                                                      <div className="col">
                                                                           <div className="row">
                                                                                <div className="col-auto">
                                                                                     <span className='preguntaResultados'>{pregunta.pregunta.titulo}</span>
                                                                                </div>
                                                                                <div className="col-12">
                                                                                     <span className='respuestaResultados'>{pregunta.pregunta.respuesta}</span>
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            </React.Fragment>
                                                       );
                                                  } else if (pregunta.pregunta.tipo === "texto") {
                                                       return (
                                                            <React.Fragment key={"Pregunta_T_"+key}>
                                                                 <div className="row aire">
                                                                      <div className="col">
                                                                           <div className="row">
                                                                                <div className="col">
                                                                                     <span className='preguntaResultados'>{pregunta.pregunta.titulo}</span>
                                                                                </div>
                                                                           </div>
                                                                           <div className="row">
                                                                                <div className="col">
                                                                                     <span className='respuestaResultados'>{pregunta.pregunta.respuesta}</span>
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            </React.Fragment>
                                                       );
                                                  }else if (pregunta.pregunta.tipo === "pregunta") {
                                                       return (
                                                            <React.Fragment key={"Pregunta_T_"+key}>
                                                                 <div className="row aire">
                                                                      <div className="col">
                                                                           <div className="row">
                                                                                <div className="col">
                                                                                     <span className='preguntaResultados'>{pregunta.pregunta.pregunta}</span>
                                                                                </div>
                                                                           </div>
                                                                           <div className="row">
                                                                                <div className="col">
                                                                                     <span className='respuestaResultados'>{pregunta.pregunta.respuesta}</span>
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            </React.Fragment>
                                                       );
                                                  }else if (pregunta.pregunta.tipo === "opcionMultiple") {
                                                       return (
                                                            <React.Fragment key={"Pregunta_T_"+key}>
                                                                 <div className="row aire">
                                                                      <div className="col">
                                                                           <div className="row">
                                                                                <div className="col">
                                                                                     <span className='preguntaResultados'>{pregunta.pregunta.titulo}</span>
                                                                                </div>
                                                                           </div>
                                                                           <div className="row">
                                                                                <div className="col">
                                                                                     <span className='respuestaResultados'>{pregunta.pregunta.respuesta}</span>
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            </React.Fragment>
                                                       );
                                                  }
                                                  return null
                                             })}
                                        </React.Fragment>
                                   );
                              })}

                              <div className="row"></div>
                              <div className="row"></div>
                              <div className="row"></div>
                         </div>
                    </div>
               </div>
          </React.Fragment>
     )
}
