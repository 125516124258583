export default function Footer() {
    const Year = new Date().getFullYear()
    return (
      <footer className="main-footer">
          <div className="float-right d-none d-sm-block">
              Intelego
          </div>
          <strong>Copyright © {Year}.</strong> Todos los derechos reservados.
      </footer>
    );
  }
  