import React from "react";
import Header from "components/frontend/tutor/Header";


const RenderTemplateTutor = ( {component} ) => {
  return (
    <>
    <Header/>
        {component}
    </>
  );
};

export default RenderTemplateTutor;