import React, {useState} from 'react'
import iconoVer from '../../../assets/img/iconos/iconoVer.svg'
import iconoNoVer from '../../../assets/img/iconos/iconoNoVer.svg'

export default function InputEspecial({nombre, label, funcionSetData, funcionValidar}) {
  const [claseLleno, setClaseLleno] = useState( 'no-valido' );
  const [mostrar, setMostrar] = useState( 'password' );
  const [icono, setIcono] = useState( iconoVer );
  const validarValor = (e) => {
       e.preventDefault();
       if(e.target.value.length === 0) {
          setClaseLleno( 'no-valido' );
      } else {
          setClaseLleno( 'valido' );
      }
      funcionSetData(e.target)
  }
  const mostrarOcultar = (e) => {
     e.preventDefault();
     mostrar === 'password' ? setMostrar('text') : setMostrar('password');
     mostrar === 'password' ? setIcono(iconoNoVer) : setIcono(iconoVer);
  }
  return (
     <div className="input-group mb-1 campo">
          <input type={mostrar} onKeyUp={funcionValidar ? (e) => funcionValidar(e) : null} onChange={(e) => validarValor(e)} name={nombre} className={"inputLogin"}/>
          <label className={claseLleno}>{label}</label>
          <img src={icono} onClick={(e) => mostrarOcultar(e)}/>
     </div>
  )
}
