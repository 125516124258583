import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Modal } from 'react-bootstrap';
import { CONSTANT } from '../../../static/constants';
import axios from 'axios';
import iconoEngrane from '../../../assets/img/iconos/iconoEngraneRojo.svg'
import Formulario from '../../../static/formularioAcuerdosJefe.json'
import _ from "lodash";

export default function FormularioCompromisos() {

    const dispatch = useDispatch();
    const storeJefe = useSelector((state) => state.rutaJefe);
    const [showConfirmar, setShowConfirmar] = useState( false );

    const { register, handleSubmit, reset } = useForm();

    const handleCloseConfirmar = () => {
        setShowConfirmar(false);
    }

    const backOne = (e) => { 
        e.preventDefault()
        setShowConfirmar(false);
        window.history.back() 
    }

    let _form = _.clone(Formulario)
    if(storeJefe.dataUsuario.acuerdos_jefe !== null && typeof storeJefe.dataUsuario.acuerdos_jefe !== "undefined" && storeJefe.dataUsuario.acuerdos_jefe !== "" && storeJefe.dataUsuario.acuerdos_jefe !== "{}"){
        _form = JSON.parse(storeJefe.dataUsuario.acuerdos_jefe) 
    }

    const [form, setForm] = useState( _form )

    var obj = { fileName: '', fileUrl: '' };
    if (typeof (storeJefe.dataUsuario.foto) == "string") {
         try {
              obj = JSON.parse(storeJefe.dataUsuario.foto)
         } catch (e) { }
    }

    const onSubmit = datos => {
        updateForm(datos)
        setShowConfirmar(true)
    }

    const updateForm = (datos) => {
        form[0].secciones.forEach(seccion => {
            seccion.seccion.preguntas.forEach(pregunta => {
                if (datos[pregunta.pregunta.input] !== undefined) {
                    pregunta.respuesta = datos[pregunta.pregunta.input]
                }

            })
        })
    }

    const saveForm = ( ) => {
        const token = localStorage.getItem(CONSTANT.AUTH_TOKEN);
        
        axios({
             method: 'POST',
             url: process.env.REACT_APP_API_URL + `/api/guarda_acuerdos_jefe`,
             data: { 
                  id_usuario: storeJefe.usuario,
                  id_unidad: storeJefe.unidad,
                  id_grupo: storeJefe.grupo,
                  acuerdos: JSON.stringify( form )
             },
             headers: { 
                 'Authorization': `${token}`,
                 "Content-Type": "application/json",
                 'Cache-Control': 'no-cache',
                 'Pragma': 'no-cache',
                 'Expires': '0'
             }
         })
         .then(res => { 
             if(res.status===200){
                let tmp = storeJefe.dataUsuario
                tmp.acuerdos_jefe = JSON.stringify( form )
                dispatch({ type: CONSTANT.RUTA_JEFE_SET_DATA_USUARIO, payload: tmp })
                setShowConfirmar(false);

                form.map((secciones, key1) => {
                    secciones.secciones.map((seccion, key2) => {
                        seccion.seccion.preguntas.map((pregunta, key_pregunta) => {
                            pregunta.respuesta = '';
                        })
                    })
                })
                
             }else{
                 // alert("Ocurrio un error al guardar.");
             }
         })
         .catch(err => { 
             console.log(err)
             // alert("Ocurrio un error al guardar.");
         });
   }

   const ModalConfirmar = () => {
    return (
         <Modal show={showConfirmar} onHide={handleCloseConfirmar} animation={false} size='lg' centered>
              <Modal.Title>
              <div className="row">
                   <div className="d-flex col align-items-center justify-content-end">
                        <button className="btn botonModal" onClick={handleCloseConfirmar}><i className="far fa-times-circle cerrarModal"></i></button>
                   </div>
              </div>
              </Modal.Title>
              <Modal.Body>
                   <div className="row">
                        <div className="col">
                             <div className="d-flex align-items-center justify-content-center">
                                  <i className="fas fa-check-circle iconoModalCheck"></i>
                             </div>
                        </div>
                   </div>
                   <div className="row aire">
                        <div className="col">
                             <div className="row">
                                    <div className="d-flex align-self-center justify-content-center">
                                        <span className="textoModal">Estas a punto de concluir con tu retroalimentación del participante en esta unidad de aprendizaje.</span>
                                    </div>
                             </div>
                        </div>
                   </div>
                   <div className="row aire">
                        <div className="col">
                             <div className="row">
                                    <div className="d-flex align-self-center justify-content-center">
                                        <span className="textoModal">El participante recibirá calificación y acuerdos en su perfil de la plataforma. Una vez enviada no podrás modificarla.</span>
                                    </div>
                             </div>
                        </div>
                   </div>
                   <div className="row aire">
                        <div className="col d-flex align-self-center justify-content-end">
                            <button onClick={(e) => handleCloseConfirmar(e)} className="btn btn-grisModalJefe">EDITAR</button>
                        </div>
                        <div className="col d-flex align-self-center justify-content-start">
                            <button onClick={(e) => saveForm(e)} className="btn btn-verdeAcuerdosModalJefe">ENVIAR</button>
                        </div>
                   </div>
              </Modal.Body>
         </Modal>
    );
}

    return (
        <>
            {showConfirmar ? <ModalConfirmar /> : null}
            <div className="row aire">
                <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
                    <span className='tituloVistaJefe'>ACCIONES Y COMPROMISOS CON</span>
                </div>
            </div>
            <div className="row">
                <div className="offset-1 col-10 offset-lg-2 col-lg-8 d-flex align-items-center justify-content-center divAmarilloUsuarioRetroJefe">{/** RESPONSIVO */}
                    <div className="col-auto"><img src={obj.fileUrl} className="fotoUsuariosJefe" width="60" height="60" alt="Perfil usuario" /></div>
                    <div className="col">
                        <span className='textoDivAmarilloUsuarioRetroJefe'>{storeJefe.dataUsuario.nombre + ' ' + storeJefe.dataUsuario.apellido_paterno + ' ' + storeJefe.dataUsuario.apellido_materno}</span>
                    </div>
                </div>
            </div>
            <div className="row menosAire">
                <div className="offset-1 offset-lg-2 col-auto d-flex align-items-center justify-content-center divNegroUsuarioRetroJefe">{/** RESPONSIVO */}
                    <div className="col-auto d-flex align-items-center justify-content-center">
                        <i className="fas fa-user-friends usuariosPendientesPorCalificarDivIconoJefe"></i>
                    </div>
                    <div className="col-11">
                        <span className='toolbarTextoDivUsuariosJefe'>Evaluarás: Unidad {storeJefe.dataUnidad.orden + 1} - {storeJefe.dataUnidad.nombre}</span>
                    </div>
                </div>
            </div>
            <div className="row menosAire">
                <div className="offset-1 col-10 offset-lg-2 col-lg-8 divAmarilloAlertaCompromisosJefe">{/** RESPONSIVO */}
                    <div className="row">
                        <div className="col-1 d-flex align-items-start justify-content-center">
                            <i className="fas fa-exclamation-triangle iconoAlertaCompromisosJefe"></i>
                        </div>
                        <div className="col d-flex align-items-center justify-content-center">
                            <span className='textoAlertaComprososJefe'>Recuerda que para poder redactar y enviar al colaborador estas acciones y compromisos deberás enviar primero la retroalimentación del paso anterior.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row aire">
                <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
                    <div className="row">
                        <div className="col">
                            <p className='textoEncabezadoRetroJefe'>Antes de terminar la sesión, definan juntos acciones y compromisos que ayudarán al participante a mejorar en algún aspecto de la competencia. También puedes establecer acciones enfocadas a reforzar una buena práctica que está teniendo el participante y deseas que continue aplicándola.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <p className='textoEncabezadoRetroJefe'>No olvides que, previo a esta sesión, el colaborador realizará la Autoevaluación de aprendizajes clave, con el fin de saber cómo ha sido su desempeño hasta el momento. Pide que te muestre sus resultados para conocer sus fortalezas y áreas de mejora y, así, complementar las acciones y compromisos que ambos definan, procurando que tus recomendaciones sean integrales y consideren la experiencia particular del colaborador.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row aire">
                <div className="offset-1 col-10 offset-lg-2 col-lg-8 d-flex align-items-center justify-content-center">{/** RESPONSIVO */}
                    <div className="col-auto d-flex align-items-center justify-content-center">
                        <img src={iconoEngrane} alt="" width="41.2px" height="41.2px" />
                    </div>
                    <div className="col">
                        <div className="row">
                            <div className="col d-flex align-items-center justify-content-start">
                                <span className='textoRojoInstruccionesCompromisosJefe'>Importante:</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col d-flex align-items-center justify-content-start">
                                <span className='textoNegroInstruccionesCompromisosJefe'>Establece acciones específicas utilizando los criterios que apliquen de un objetivo SMART.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {form.map((secciones, key1) => {
                            return (
                                <>
                                    {secciones.secciones.map((seccion, key2) => {
                                        return (
                                            <>
                                                {seccion.seccion.preguntas.map((pregunta, key_pregunta) => {
                                                    return (
                                                            <div className="row aire">
                                                                <div className="col">
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <span className='TextoAcuerdoInputFormCompromisosJefe'>{pregunta.pregunta.pregunta}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <textarea {...register(pregunta.pregunta.input, { required: (key_pregunta === 0 ? true : false) })} className="form-control textareaJefe" id={pregunta.pregunta.input} rows="7" defaultValue={pregunta.respuesta || ''} disabled={storeJefe.dataUsuario.evaluacion_jefe_enviada !== 1 || storeJefe.dataUsuario.acuerdos_jefe !== null}></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    );
                                                })}
                                            </>
                                        );
                                    })}
                                </>
                            );
                        })}
                        <div className="row aire">
                            <div className="offset-1 col-10 offset-lg-2 col-lg-8 d-flex align-items-center justify-content-center">{/** RESPONSIVO */}
                                <button type="submit" className='btn btn-verdeFormRetroJefe' disabled={storeJefe.dataUsuario.evaluacion_jefe_enviada !== 1 || storeJefe.dataUsuario.acuerdos_jefe !== null }>TERMINAR RETROALIMENTACIÓN</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
        </>
    )
}
