export default function Footer() {
    const Year = new Date().getFullYear()
    return (
      /*
      <>
      <div className="col-md-auto align-self-center px-md-0">
        <strong className="paddingFooterIzquierda">Copyright © {Year}.</strong>
      </div>
      <div className="col-md-auto align-self-center px-md-0">
        Todos los derechos reservados.
      </div>
      <div className="col offset-md-8 d-flex justify-content-end align-self-center">
        <span className="paddingFooterDerecha">Intelego</span>
      </div>
      </>
      */

      /* INICIO RESPONSIVO */
      <>
      <div className="d-block d-md-flex align-items-center justify-content-md-between justify-content-sm-center justify-content-center">
        <div className="text-center">
          <strong>Copyright © {Year}.</strong> Todos los derechos reservados.
        </div>        
        <div className="text-center">
          <span>&nbsp;Intelego</span>
        </div>      
      </div>
      </>
      /* FIN RESPONSIVO */
    );
  }
  