import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Modal, Accordion, ButtonToolbar } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { CONSTANT } from '../../../static/constants';
import { useAxios } from 'use-axios-client';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import axios from 'axios';
import AuthToken from "modules/shared/authToken";
import flechaAmarilla from '../../../assets/img/iconos/flechaAmarilla.svg';
import iconoCamara from '../../../assets/img/iconos/iconoCamara.svg';
import aviso from '../../../assets/img/iconos/aviso.svg';
import llave from '../../../assets/img/iconos/llave.svg';
import puerta from '../../../assets/img/iconos/puerta.svg';
import InputEspecial from 'components/frontend/elementosVarios/InputEspecial';
import Uploader from '../../../shared/uploader/uploaderPicUsuario';
import DetalleInsignia from 'components/frontend/detalleInignia/DetalleInsignia';
import Select from "react-select";
import '../perfil/Perfil.css'
import './PerfilJefe.css'

export default function Index() {
    const [show, setShow] = useState(false);
    const [insignia, setInsignia] = useState({});
    const [usrPic, setUsrPic] = useState('');
    const [showCargarImagen, setShowCargarImagen] = useState(false);
    const [showCambiarClave, setShowCambiarClave] = useState(false);
    const [passError, setPassError] = useState(null);
    const [passSuccess, setPassSuccess] = useState(null);
    const [btnState, setBtnState] = useState("ACTUALIZAR");
    const [selectedGroup, setSelectedGroup] = useState(0);
    const dispatch = useDispatch();
    const token = localStorage.getItem(CONSTANT.AUTH_TOKEN);

    const currentUser = useSelector((state) => state.auth);
    const { insignias, insigniasCompletadas, olas, loading } = useSelector((state) => state.insignias);

    const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    const traerMes = (mes) => {
        return (
            meses[mes]
        );
    }

    useEffect(() => {
        const token = localStorage.getItem(CONSTANT.AUTH_TOKEN)
        dispatch({ type: CONSTANT.OLAS_BY_USUARIO_START, payload: [] });
        setSelectedGroup(0)
        axios.get(process.env.REACT_APP_API_URL + `/api/jefe/perfil`, {
            headers: {
                'Authorization': `${token}`,
                "Content-Type": "application/json",
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0'
            }
        })
        .then(res => {
            dispatch({ type: CONSTANT.OLAS_BY_USUARIO_SUCCESS, payload: res })
        })
        .catch((err) => {
            dispatch({ type: CONSTANT.OLAS_BY_USUARIO_ERROR, payload: err });
        })
    }, [dispatch]);
    
    let obj = { fileName: '', fileUrl: '' };
    useEffect(() => {
        if (typeof (currentUser.FOTO) == "string") {
            try {
                let obj = JSON.parse(currentUser.FOTO)
                setUsrPic(obj.fileUrl)
            } catch (e) {
                console.log("Catch!", e)
            }
        }
    }, [currentUser.FOTO])

    const {data, error, loading: loadingGrupos } = useAxios({
		url: process.env.REACT_APP_API_URL + `/api/grupos_jefe`,
		headers: {
			'Authorization': `${token}` ,
			"Content-Type": "application/json",
			'Cache-Control': 'no-cache',
			'Pragma': 'no-cache',
			'Expires': '0'
		}
	})

	if( !data ){
		if(loadingGrupos) return(<div className="loadingPage"><LoadingSpinner /></div>)
		if(error) return "Error!";
		if(!data) return "no data";
	}

	const options = [];
	data.rows.forEach( function(valor) {
	  	options.push({value: valor.id, label: valor.nombre});
	});

    const updateGrupo = ( data ) => {
        if(data.value > 0){
            setSelectedGroup(data)
            const token = localStorage.getItem(CONSTANT.AUTH_TOKEN)
            dispatch({ type: CONSTANT.INSIGNIAS_BY_USUARIO_START, payload: [] });
            axios.get(process.env.REACT_APP_API_URL + `/api/insignias_jefe/`+data.value, {
                headers: {
                    'Authorization': `${token}`,
                    "Content-Type": "application/json",
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0'
                }
            })
            .then(res => {
                dispatch({ type: CONSTANT.INSIGNIAS_BY_USUARIO_SUCCESS, payload: res })
            })
            .catch((err) => {
                dispatch({ type: CONSTANT.INSIGNIAS_BY_USUARIO_ERROR, payload: err });
            })
        }
   	}

    if (loading) return(<div className="loadingPage"><LoadingSpinner /></div>)

    let pass_data = {
        password_0: "",
        password_1: "",
        password_2: ""
    };

    let saveData = {};
    const filesUploaded = {}
    const callbackFromChild = (key, data) => {
        filesUploaded[key] = data;
    }

    const cambiaForm = target => {
        setPassError(null)
        pass_data = {
            ...pass_data,
            [target.name]: target.value
        };
    }

    const saveDataFn = data2Save => {
        const token = localStorage.getItem(CONSTANT.AUTH_TOKEN);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_API_URL + `/api/pic_update`,
            data: data2Save,
            headers: {
                'Authorization': `${token}`,
                "Content-Type": "application/json"
            }
        })
            .then(res => {
                if (res.status === 200) {
                    let tmp = currentUser;
                    tmp.FOTO = (data2Save.foto);
                    dispatch({ type: CONSTANT.AUTH_EMPLOYEE_ID_VERIFICATION_UPDATE_PIC, payload: tmp });
                    handleClose()
                } else {
                    alert("Ocurrio un error al guardar.");
                }
            })
            .catch(err => {
                console.log(err)
                alert("Ocurrio un error al guardar.");
            });
    }

    const guardaImagen = () => {
        if (Object.keys(filesUploaded).length <= 0) {
            alert('Falta agregar archivo');
            return;
        } else {
            // Subimos archivos a aws
            const key = Object.keys(filesUploaded)[0];
            filesUploaded[key]( saveDataFn, saveData, key )
            delete filesUploaded[key];
        }
    }

    const validarPass = (e) => {
        e.preventDefault();
        const valNumeros = /(?=.*[0-9])/;
        const valEspeciales = /(?=.*[!@#$%^&*])/;
        const valMayusculas = /(?=.*[A-Z])/;
        document.getElementById("caracteres").className = (e.target.value.length >= 12) ? 'requisitosPassTrue2' : 'requisitosPassFalse2';
        document.getElementById("numeros").className = valNumeros.test(e.target.value) ? 'requisitosPassTrue2' : 'requisitosPassFalse2';
        document.getElementById("mayusculas").className = valMayusculas.test(e.target.value) ? 'requisitosPassTrue2' : 'requisitosPassFalse2';
        document.getElementById("especiales").className = valEspeciales.test(e.target.value) ? 'requisitosPassTrue2' : 'requisitosPassFalse2';
    }

    const backOne = (e) => {
        e.preventDefault()
        window.history.back()
    }

    const cerrarSesion = () => {
        dispatch({ type: CONSTANT.AUTH_LOGOUT_RESET_STORE });
        AuthToken.setRemove(true);
    }

    const handleClose = () => {
        setShowCargarImagen(false);
    }

    const handleCloseCambiarClave = () => {
        setShowCambiarClave(false);
        setPassError(null)
        setPassSuccess(null)
    }

    const fechaUTCaLocal = (d) => {
        let localDate = new Date(d)
        if (isNaN(localDate.getTime())) return ""
        const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
        return "" + ('0' + localDate.getDate()).slice(-2) + "/" + meses[localDate.getMonth()] + "/" + localDate.getFullYear()
    }



    var nombre = currentUser.NOMBRE + ' ' + currentUser.APELLIDO_P;

    const detalleInsignia = (e, detalles) => {
        e.preventDefault();
        setShow(true);
        let Ins = insigniasCompletadas.filter( ins => parseInt(ins.insignia) === insignia.id)
        if(Ins.length > 0){
            detalles.nivel = Ins[0].nivel
        }
        setInsignia(detalles);
    }

    const submit = async e => {
        e.preventDefault()
        if (btnState === "GUARDANDO...") {
            return;
        }
        setBtnState("GUARDANDO...")

        const valNumeros = /(?=.*[0-9])/;
        const valEspeciales = /(?=.*[!@#$%^&*])/;
        const valMayusculas = /(?=.*[A-Z])/;

        if ((pass_data.password_1.length < 12) || !valNumeros.test(pass_data.password_1) || !valMayusculas.test(pass_data.password_1) || !valEspeciales.test(pass_data.password_1)) {
            setBtnState("ACTUALIZAR")
            setPassError('Nueva contraseña no cumple con los requisitos.')
            return;
        }

        //
        setPassError(null)
        if (pass_data.password_1 !== pass_data.password_2) {
            setPassError('Contraseñas no coinciden.')
            return;
        }

        const token = localStorage.getItem(CONSTANT.AUTH_TOKEN);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_API_URL + `/api/password_update`,
            data: pass_data,
            headers: {
                'Authorization': `${token}`,
                "Content-Type": "application/json"
            }
        })
            .then(res => {
                if (res.status === 200) {
                    setBtnState("ACTUALIZAR")
                    setPassSuccess("Contraseña actualizada con éxito.")
                    handleClose()
                } else {
                    console.log(res)
                    setPassError('Ocurrio un error al guardar.')
                    setBtnState("ACTUALIZAR")
                }
            })
            .catch(err => {
                console.log(err)
                setPassError('Ocurrio un error al guardar.')
                setBtnState("ACTUALIZAR")
            });
        // 
    }

    const ModalCambiarClave = () => {
        return (
            <Modal show={showCambiarClave} onHide={handleCloseCambiarClave} animation={false} size='md' centered>
                <Modal.Title>
                    <div className="row">
                        <div className="d-flex col align-items-center justify-content-end">
                            <button className="btn botonModal" onClick={handleCloseCambiarClave}><i className="far fa-times-circle cerrarModal"></i></button>
                        </div>
                    </div>
                </Modal.Title>
                <Modal.Body>
                    <form onSubmit={submit} method="post">
                        <div className="row">
                            <InputEspecial nombre="password_0" label="Contraseña actual" funcionSetData={cambiaForm} />
                        </div>
                        <div className="row">
                            <InputEspecial nombre="password_1" label="Contraseña nueva" funcionSetData={cambiaForm} funcionValidar={validarPass} />
                        </div>
                        <div className="row">
                            <InputEspecial nombre="password_2" label="Confirmar contraseña nueva" funcionSetData={cambiaForm} />
                        </div>
                        {passError !== null &&
                            <div className="row aire">
                                <div class="col d-flex align-items-center justify-content-center">
                                    <small id="passwordHelp" class="text-danger">{passError}</small>
                                    <br />
                                </div>
                            </div>
                        }
                        <div className="row aire">
                            <div className="input-group mb-3">
                                <ul>
                                    <li id="caracteres" className="requisitosPassFalse2">Al menos 12 caracteres</li>
                                    <li id="mayusculas" className="requisitosPassFalse2">Al menos una may&uacute;scula</li>
                                    <li id="numeros" className="requisitosPassFalse2">Al menos un n&uacute;mero</li>
                                    <li id="especiales" className="requisitosPassFalse2">Al menos un car&aacute;cter especial</li>
                                </ul>
                            </div>
                        </div>

                        <div className="row ">
                            <div className="col d-flex align-items-center justify-content-center">
                                <button type="submit" className="btn btn-verde" disabled={btnState === "GUARDANDO..." ? true : false} >{btnState}</button>
                            </div>
                        </div>
                        {passSuccess !== null &&
                            <div className="row aire">
                                <div class="col d-flex align-items-center justify-content-center">
                                    <small id="passwordHelp" class="text-success">{passSuccess}</small>
                                    <br />
                                </div>
                            </div>
                        }
                    </form>
                </Modal.Body>
            </Modal>
        );
    }

    const ModalCambiarImagen = () => {
        return (
            <Modal show={showCargarImagen} onHide={handleClose} animation={false} size='md' centered>
                <Modal.Title>
                    <div className="row">
                        <div className="d-flex col align-items-center justify-content-end">
                            <button className="btn botonModal" onClick={handleClose}><i className="far fa-times-circle cerrarModal"></i></button>
                        </div>
                    </div>
                </Modal.Title>
                <Modal.Body>
                    <div className='row'>
                        <div className="col uploadBoxBottom">
                            <div className='uploadBox'>
                                <div className="col-md-8 offset-md-2">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <Uploader filesCallback={callbackFromChild} input_key="foto" s3_key={'Usuarios'} input_thumb={obj} accepted_files='image/*' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-12">
                            <div className="d-flex align-items-center justify-content-center">
                                &nbsp;<br />
                                <button type="button" style={{ marginTop: '10px' }} onClick={guardaImagen} className="btn btn-verde mt-3">GUARDAR</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <>
            {showCargarImagen ? <ModalCambiarImagen /> : null}
            {showCambiarClave ? <ModalCambiarClave /> : null}
            {show ? <DetalleInsignia show={show} setShow={setShow} insignia={insignia} /> : null}
            <div className="container-fluid fondo">
                <div className="col-12">
                    <div className='row'>
                        <div className='col-12 col-lg-2 my-2 olineG'>
                            <div className="col usuarioPerfil py-5 ">
                                <div className="row">
                                    <div className="col">
                                        <div className="d-flex linkRegresarPerfil align-items-center justify-content-center">
                                            <Link onClick={(e) => { backOne(e) }}>
                                                <img alt="flecha" src={flechaAmarilla} />
                                                <span className='linkRegresarPerfil'>&nbsp;&nbsp;&nbsp;REGRESAR</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <span className='bienvenidaPerfil'>Nos da gusto <br /> verte por aquí</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row aire">
                                    <div className="col d-flex align-items-center justify-content-center franjaAmarillaPerfilJefe">
                                        <span className='textoFranjaAmarillaPerfilJefe'>ROL DE JEFE</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="d-flex align-items-end justify-content-center divFotoPerfil">
                                            <img src={usrPic} className="img-circle fotoUsuarioPerfil" width="138" height="138" alt="Perfil usuario" />
                                            <button onClick={(e) => setShowCargarImagen(true)} className="btn subirImagen" title="Like Button">
                                                <img src={iconoCamara} alt="" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="nombreUsuarioPerfil d-flex align-items-center justify-content-center">
                                            {nombre}
                                        </div>
                                    </div>
                                </div>
                                <div className="row espacioPerfil">
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <span className='label1Perfil'>Jefe desde</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <span className='label1Perfil'>{fechaUTCaLocal(currentUser.createdAt)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row espacioPerfil">
                                    <div className="col">
                                        <div className="row grupoLinksPerfil">
                                            <div className="col">
                                                <Link to={{ pathname: '/avisoLogeadoJefe' }}>
                                                    <div className="row">
                                                        <div className="col-4 d-flex align-items-center justify-content-end">
                                                            <img alt="aviso de privacidad" src={aviso} />
                                                        </div>
                                                        <div className="col d-flex align-items-center justify-content-start">
                                                            <span className='linkMenuPerfil'>Aviso de Privacidad</span>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="row grupoLinksPerfil">
                                            <div className="col">
                                                <a href="#/" onClick={() => { setShowCambiarClave(true) }}>
                                                    <div className="row">
                                                        <div className="col-4 d-flex align-items-center justify-content-end">
                                                            <img alt="cambiar contraseña" src={llave} />
                                                        </div>
                                                        <div className="col d-flex align-items-center justify-content-start">
                                                            <span className='linkMenuPerfil'>Cambiar contraseña</span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="row grupoLinksPerfil">
                                            <div className="col">
                                                <a href="#/" onClick={() => { cerrarSesion() }}>
                                                    <div className="row">
                                                        <div className="col-4 d-flex align-items-center justify-content-end">
                                                            <img alt="cerrar sesión" src={puerta} />
                                                        </div>
                                                        <div className="col d-flex align-items-center justify-content-start">
                                                            <span className='linkMenuPerfil'>Cerrar sesión</span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-10 my-2 olineG'>
                            <div className='row rowInsigniasPerfilJefe'>
                                <div className='col rowInsigniasPerfilJefe'>
                                    <Accordion className="olineB rowInsigniasPerfilJefe" defaultActiveKey="0">
                                        <Accordion.Toggle as={ButtonToolbar} eventKey="0" >
                                            <div className="col franjaNegraPerfil d-flex align-items-center text-center justify-content-between justify-content-lg-center">MIS INSIGNIAS<div className='d-block d-lg-none iconAccordion'>&gt;</div></div>{/* RESPONSIVO */}
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" className="rowInsigniasPerfilJefe">
                                            <div className="col align-items-center insigniasPerfilJefe">
                                                <div className="row my-3 my-lg-0">{/* RESPONSIVO */}
                                                    <div className='col'>
                                                        <div className="col mensajeEncabezadoPerfil d-flex align-items-center justify-content-center">
                                                            <span className='subMensajeEncabezadoPerfil'>Para saber cómo ganarlas, haz clic en cada una de ellas.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row menosAire">
                                                    <div className="col d-flex align-items-center justify-content-center">{/** RESPONSIVO */}
                                                        <Select name="grupos"
                                                            value={options.value}
                                                            options={options}
                                                            placeholder="SELECCIONA GENERACIÓN"
                                                            defaultValue={selectedGroup}
                                                            onChange={updateGrupo}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row menosAire">
                                                    {insignias.rows.map((insignia, key_item) => {
                                                        
                                                        var img = { fileName: '', fileUrl: '' };
                                                        if (typeof (insignia.imagen) == "string") {
                                                            try {
                                                                img = JSON.parse(insignia.imagen)
                                                            } catch (e) { }
                                                        }

                                                        if( insignia.completada === true && insignia.tipo === 2){
                                                            let Ins = insigniasCompletadas.filter( ins => parseInt(ins.insignia) === insignia.id)
                                                            if(Ins.length > 0){
                                                                insignia.nivel = Ins[0].nivel
                                                                if(Ins[0].nivel > 1){
                                                                    if (typeof (insignia["imagenNivel"+Ins[0].nivel]) == "string") {
                                                                        try {
                                                                            img = JSON.parse(insignia["imagenNivel"+Ins[0].nivel])
                                                                        } catch (e) { }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                            
                                                        return (
                                                            <React.Fragment key={"insignia_" + insignia.id}>
                                                                <div className="col espacioInsigniaPerfil">
                                                                    <div className="col d-flex align-items-center justify-content-center imagenInsigniaPerfil">
                                                                        <button className='btn' onClick={(e) => detalleInsignia(e, insignia)} data-toggle="tooltip" data-placement="top" title="¿Como obtenerla?">
                                                                            <img src={img.fileUrl} className={insignia.completada ? 'insignia' : 'insigniaNoGanada insignia'} width="150" height="150" alt="imagen insignia" />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </Accordion.Collapse>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-12 col-lg-12 my-2 olineG'>
                            <div className="col d-flex align-items-center justify-content-center divNegroPerfilJefe">
                                <span className='textoBlancoDivNegroPerfilJefe'>MI CALENDARIO</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {Object.values(olas).map((grupo, key_grupo) => {
                            let fechaInicioSesion = new Date(`${grupo.jefeAutoEvInicioFecha} 00:00:00`);
                            let fechaFinSesion = new Date(`${grupo.jefeAutoEvFinFecha} 00:00:00`);
                            return (
                                <div className='col-3 col-lg-3 olineG'>
                                    <div className="divBlancoPerfilJefe">{/** RESPONSIVO */}
                                        <div className="row menosAire pt-3">
                                            <div className="col d-flex align-items-center justify-content-center">
                                                <span className='textoNegroDivNegroPerfilJefe'>OLA EN CURSO</span>
                                            </div>
                                        </div>
                                        <div className="row menosAire">
                                            <div className="col">
                                                <div className="offset-1 col-10 d-flex align-items-center justify-content-center divBordePerfilJefe">
                                                    <span className='textoVerdeDivNegroPerfilJefe'>{grupo.grupo}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row aire">
                                            <div className="col d-flex align-items-center justify-content-center">
                                                <span className='textoNegroDivNegroPerfilJefe'>UNIDAD DE APRENDIZAJE EN CURSO</span>
                                            </div>
                                        </div>
                                        <div className="row menosAire">
                                            <div className="offset-1 col-10 d-flex">
                                                <div className="col d-flex align-items-center justify-content-center divBordePerfilJefe">
                                                    <span className='textoVerdeDivNegroPerfilJefe'>{(grupo.orden+1)}</span>
                                                </div>
                                                <div className="offset-1 col-9 d-flex align-items-center justify-content-center divBordePerfilJefe">
                                                    <span className='textoVerdeDivNegroPerfilJefe'>{grupo.unidad}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row menosAire">
                                            <div className="col d-flex align-items-center justify-content-center">
                                                <span className='textoNegroDivNegroPerfilJefe'>PERIODO DE RETROALIMENTACIÓN 1-1</span>
                                            </div>
                                        </div>
                                        <div className="row menosAire">
                                            <div className="col">
                                                <div className="offset-1 col-10 d-flex align-items-center justify-content-center divBordePerfilJefe">
                                                    <span className='textoVerdeDivNegroPerfilJefe fechaMiCalendarioJefe'>{fechaInicioSesion.getDate() + " DE " + traerMes(fechaInicioSesion.getMonth()) + " AL " + fechaFinSesion.getDate() + " DE " + traerMes(fechaFinSesion.getMonth())}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <br />
                </div>
            </div>
        </>
    )
}
