import { CONSTANT } from '../../static/constants'

const initialState = {
    tabActual: "uno",
    viewPaso4: 0
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case CONSTANT.CHANGE_TAB:
            return {
                ...state,
                tabActual: payload
            };
        case CONSTANT.VIEW_PASO_4:
            return {
                ...state,
                viewPaso4: payload
            };
        default:
            return state;
    }
}

export default reducer
  