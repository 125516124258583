import { CONSTANT } from '../../static/constants'

const initialState = {
     modulos: [],
     loading: true,
     error: false,
 };

 const reducer = (state = initialState, { type, payload }) => {
     switch (type) {
         case CONSTANT.ACTIVIDADES_BY_UNIDAD_START:
             return {
                 ...state,
                 unidaid: payload,
                 error: false,
                 loading: true,
             };
         case CONSTANT.ACTIVIDADES_BY_UNIDAD_SUCCESS:
             return {
                 ...state,
                 modulos: payload.data.unidad,
                 error: false,
                 loading: false,
             };
         case CONSTANT.ACTIVIDADES_BY_UNIDAD_ERROR:
             return {
                 ...state,
                 error: payload,
                 loading: false,
             };
         default:
             return state;
     }
 }
 
 export default reducer