import React from 'react'
import { CONSTANT } from '../../../static/constants';
import { useAxios } from 'use-axios-client';
import iconoRelojVerde from '../../../assets/img/iconos/iconoRelojVerde.svg'
import iconoRelojRojo from '../../../assets/img/iconos/iconoRelojRojo.svg'
import iconoBaja from '../../../assets/img/iconos/iconoBajaUsuario.svg'
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap-table'
import 'bootstrap-table/dist/bootstrap-table.min.css'
import 'bootstrap-table/dist/bootstrap-table-locale-all.js'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
require('react-bootstrap-table-next/dist/react-bootstrap-table2.min.css');
require('react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css');
require('react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css');

export default function TablaUsuarios() {
     const token = localStorage.getItem(CONSTANT.AUTH_TOKEN);
     const dispatch = useDispatch();
     const storeJefe = useSelector((state) => state.rutaJefe);
     let usuariosPendientes = 0;

     const {data, error, loading } = useAxios({
          url: process.env.REACT_APP_API_URL + `/api/consulta_grupo/${storeJefe.grupo}`,
          headers: {
               'Authorization': `${token}` 
          }
     })

     // useEffect(() => {

     //      if(storeJefe.dataUsuario !== null){
     //           dispatch({ type: CONSTANT.RUTA_JEFE_SET_DATA_USUARIO, payload: null })
     //           dispatch({ type: CONSTANT.RUTA_JEFE_SET_DATA_UNIDAD, payload: null }) 
     //      }

     // } );

     if(loading) return(<div className="loadingPage"><LoadingSpinner /></div>)
     if(error || !data) return "Error!";

     function cambiarVista(usuario, unidad, vista) {
          dispatch({ type: CONSTANT.RUTA_JEFE_SET_UNIDAD_USUARIO, payload: {unidad: unidad, usuario: usuario} })
          dispatch({ type: CONSTANT.RUTA_JEFE_SET_VISTA_ACTIVE, payload: {vista: vista} })
     }

     function avanceFormatter(cell, row, rowIndex, formatExtraData) {
          if (row.activo === 0) {
            return (
               <>
                    <div className="row">
                         <div className="col d-flex align-items-center justify-content-center">
                              <img src={iconoBaja} className="fotoUsuariosJefe" width="30" height="30" alt="Perfil usuario" />
                         </div>
                    </div>
                    <div className="row">
                         <div className="col d-flex align-items-center justify-content-center">
                              <span className="textoGrisTablaJefe">Baja de usuario</span>
                         </div>
                    </div>
                    <div className="row">
                         <div className="col d-flex align-items-center justify-content-center">
                              <button className="btn linkPrepararTablaAvanceJefe">Ver detalles</button>
                         </div>
                    </div>
               </>
            );
          }
          return (
               <>
                    <div className="row">
                         <div className="col d-flex align-items-center justify-content-center">
                              <img src={cell === 1 ? iconoRelojRojo : iconoRelojVerde} className="fotoUsuariosJefe" width="30" height="30" alt="Perfil usuario" />
                         </div>
                    </div>
                    <div className="row">
                         <div className="col d-flex align-items-center justify-content-center">
                              <span className="textoGrisTablaJefe">{cell === 1 ? 'No entregado' : 'En tiempo'}</span>
                         </div>
                    </div>
                    <div className="row">
                         <div className="col d-flex align-items-center justify-content-center">
                              <button className="btn linkPrepararTablaAvanceJefe">Ver detalles</button>
                         </div>
                    </div>
               </>
          );
        }

     function fotoFormatter(cell, row, rowIndex, formatExtraData) {
          return (
               <>
                    <img src={row.foto} className="fotoUsuariosJefe" width="60" height="60" alt="Perfil usuario" />
               </>
          );
     }

     const columns = [
          {
               dataField: 'id',
               text: '',
               hidden: true
          },{
               dataField: 'activo',
               text: '',
               hidden: true
          },{
               dataField: 'foto',
               text: '',
               headerAlign: 'center',
               headerClasses: 'encabezadoGrisUsuariosJefe',
               classes: 'align-middle',
               formatter: fotoFormatter,
               align: 'center'
          },{
               dataField: 'nombre',
               text: 'Nombre',
               headerAlign: 'center',
               align: 'center',
               headerClasses: 'align-middle encabezadoGrisUsuariosJefe',
               classes: 'align-middle textoGrisTablaJefe',
               sort: true
          },{
               dataField: 'apellidos',
               text: 'Apellidos',
               headerAlign: 'center',
               align: 'center',
               headerClasses: 'align-middle encabezadoGrisUsuariosJefe',
               classes: 'align-middle textoGrisTablaJefe',
               sort: true
          },{
               dataField: 'avance',
               text: 'Avance en Programa',
               headerClasses: 'align-middle encabezadoGrisUsuariosJefe',
               classes: 'align-middle',
               formatter: avanceFormatter,
               headerAlign: 'center',
               align: 'center',
               formatExtraData: {
                    vista: 'tres'
               },
               events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                      cambiarVista(row.id, 0, 'tres');
                    }
               },
               sort: true
          }
     ];

     const columns2 = [{
          dataField: 'Usuario',
          text: ' Usuarios',
          headerAttrs: { row:`0`, colSpan: `4` },
          headerAlign: 'center',
          headerClasses: 'align-middle encabezadoGrisUsuariosJefe'
     }, {
          dataField: 'Unidades',
          text: 'Unidades',
          headerAttrs: { row:`0`, colSpan: `4` },
          headerAlign: 'center',
          headerClasses: 'align-middle encabezadoAmarilloUsuariosJefe',
     }
     ];

     const DateFix = (fecha, hora) => {
          var uFecha2 = fecha + ' ' +  hora
          var arr2 = uFecha2.split(/[- :]/);
          return new Date(arr2[0], arr2[1]-1, arr2[2], arr2[3], arr2[4], arr2[5]);
      }

     Object.values(data.info_grupo).forEach((usuario, index) => {
          if(index === 0){
               Object.values(usuario.unidades).forEach((unidad, index) => {
                    columns.push({
                         dataField: 'unidad' + unidad.no_unidad,
                         text: 'Calif. Unidad ' + unidad.no_unidad,
                         headerAlign: 'center',
                         align: 'center',
                         formatExtraData: { 
                              unidad: unidad.no_unidad,
                              noUnidad: unidad.unidad_id 
                         }, 
                         events: {
                              onClick: (e, column, columnIndex, row, rowIndex) => {
                                   if(row['reto' + column.formatExtraData.unidad] !== '')
                                   cambiarVista(row.id, column.formatExtraData.noUnidad, 'dos');
                              }
                         },
                         classes: (cell, row, rowIndex, colIndex) => {
                              switch (cell) {
                                   case 'Prepara evaluación':
                                        return (
                                             'align-middle prepEvalUsuariosJefe'
                                        );
                                   case 'Enviar calificación':
                                   return (
                                        'align-middle envEvalUsuariosJefe'
                                   );
                                   case 'Aún no disponible':
                                   return (
                                        'align-middle'
                                   );
                                   default:
                                        return (
                                             'align-middle calEvalUsuariosJefe'
                                        );
                                        break;
                              }
                         },
                         headerClasses: 'align-middle encabezadoAmarilloUsuariosJefe',
                         sort: true
                    });
                    columns.push({
                         dataField: 'reto' + unidad.no_unidad,
                         text: '',
                         hidden: true
                    });
                    columns.push({
                         dataField: 'evaluacionJefe' + unidad.no_unidad,
                         text: '',
                         hidden: true
                    });
                    columns.push({
                         dataField: 'evalEnviada' + unidad.no_unidad,
                         text: '',
                         hidden: true
                    });
                    columns.push({
                         dataField: 'calificacionJefe' + unidad.no_unidad,
                         text: '',
                         hidden: true
                    });

               });
               columns.push({dataField: 'promedio', text: 'Promedio LIFT', headerAlign: 'center', align: 'center',
               headerClasses: 'align-middle encabezadoAmarilloUsuariosJefe',
               classes: 'align-middle textoGrisPromedioTablaJefe',
               sort: true})
          }
     });

     let info = [];
     Object.values(data.info_grupo).forEach((usuario, index) => {
          let infoUsuario = {};
          let estatusAvance = 0;
          var obj = { fileName: '', fileUrl: '' };
          if (typeof (usuario.foto) == "string") {
               try {
                    obj = JSON.parse(usuario.foto)
               } catch (e) { }
          }
          infoUsuario['id'] = usuario.usuario_id;
          infoUsuario['activo'] = usuario.activo;
          infoUsuario['foto'] = obj.fileUrl;
          infoUsuario['nombre'] = usuario.nombre;
          infoUsuario['apellidos'] = usuario.ap_paterno + ' ' + usuario.ap_materno;
          Object.values(usuario.unidades).forEach((unidad, index) => {
               let fechaHoy = new Date();
               let fechaLimite = DateFix(unidad.retoFechaLimite + ' ' + '00:00:00');
               if(unidad.retoSubido === '' && fechaHoy > fechaLimite){
                    estatusAvance = 1;
               }
               let celda = ''; 
               if(unidad.retoSubido !== '' && unidad.evaluacionJefe === 0){
                    celda = 'Prepara evaluación';
                    usuariosPendientes = usuariosPendientes + 1;
               }else if(unidad.retoSubido !== '' && unidad.evaluacionJefe === 1 && unidad.evalEnviada === 0) {
                    celda = 'Enviar calificación';
                    usuariosPendientes = usuariosPendientes + 1;
               }else if(unidad.retoSubido !== '' && unidad.evaluacionJefe === 1 && unidad.evalEnviada === 1){
                    celda = unidad.calificacionJefe.toFixed(1);
               }else {
                    celda = 'Aún no disponible';
               }
               infoUsuario['unidad'+unidad.no_unidad] = celda;
               infoUsuario['reto'+unidad.no_unidad] = unidad.retoSubido;
               infoUsuario['evaluacionJefe'+unidad.no_unidad] = unidad.evaluacionJefe;
               infoUsuario['evalEnviada'+unidad.no_unidad] = unidad.evalEnviada;
               infoUsuario['calificacionJefe'+unidad.no_unidad] = unidad.calificacionJefe;
          });
          infoUsuario['avance'] = estatusAvance;
          infoUsuario['promedio'] = (usuario.promedioGral ? usuario.promedioGral.toFixed(1) : '0');
          info.push(infoUsuario);
     });

     const { SearchBar } = Search;

     const customTotal = (from, to, size) => (
          <span className="react-bootstrap-table-pagination-total">
            &nbsp;Mostrando { from } a { to } de { size } Resultados
          </span>
     );
        
     const options = {
          paginationSize: 10,
          pageStartIndex: 1,
          // alwaysShowAllBtns: true, // Always show next and previous button
          // withFirstAndLast: false, // Hide the going to First and Last page button
          // hideSizePerPage: true, // Hide the sizePerPage dropdown always
          // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
          firstPageText: 'Primera',
          prePageText: 'Atras',
          nextPageText: 'Siguiente',
          lastPageText: 'Ultima',
          nextPageTitle: 'Primera pagina',
          prePageTitle: 'Pagina anterior',
          firstPageTitle: 'Siguiente pagina',
          lastPageTitle: 'Ultima pagina',
          showTotal: true,
          paginationTotalRenderer: customTotal,
          disablePageTitle: true,
          sizePerPageList: [{
               text: '10', value: 10
          }, {
               text: '20', value: 20
          }, {
               text: 'Todos', value: info.length
          }] // A numeric array is also available. the purpose of above example is custom the text
     };
  return (
     <>
     <ToolkitProvider bootstrap4 keyField="id" data={ info } columns={ columns } search>
          {
               props => (
                    <div>
                         <div className="row">
                              {Object.values(data.info_grupo).length > 0 && 
                                   <>
                                        <div className="col-auto">
                                             <span className='toolbarTextoVerdeUsuariosJefe'>{data.nombre_grupo}</span>
                                        </div>
                                        <div className="col-auto">
                                             <span className='toolbarTextoFechaVerdeUsuariosJefe'>Fecha de inicio: {data.fecha_inicio}</span>
                                        </div>
                                        <div className="col-auto">
                                             <button type="button" className="btn-negro" disabled={true}>
                                                  <div className="row">
                                                       <div className="col-2 d-flex align-items-center justify-content-center">
                                                            <i className="fas fa-user-friends usuariosPendientesPorCalificarDivIconoJefe"></i>
                                                       </div>
                                                       <div className="col d-flex align-items-center justify-content-start">
                                                            <span className='toolbarTextoDivUsuariosJefe'>Usuarios pendientes por calificar: {usuariosPendientes}</span>
                                                       </div>
                                                  </div>
                                             </button>
                                        </div>
                                   </>
                              }
                              <div className="col">
                                   <SearchBar { ...props.searchProps } />
                              </div>
                         </div>
                         {/* <BootstrapTable keyField='id' data={ [] } columns={ columns2 } classes="nomargenes"/> */}
                         <BootstrapTable { ...props.baseProps }  pagination={ paginationFactory(options) }/>
                    </div>
               )
          }
     </ToolkitProvider>
     <br />
     <br />
     <br />
     <br />
    </>
  )
}
