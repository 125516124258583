import React from 'react'
import Header from '../../../components/frontend/jefe/Header.jsx'
import Aviso from './aviso.jsx'

export default function avisoJefe() {
  return (
    <>
     <Header />
     <Aviso />
    </>
  )
}
