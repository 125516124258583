import React from "react";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";
import AuthToken from "modules/shared/authToken";
import Template from "../views/cms/template/index";
import axios from 'axios';
import { CONSTANT } from "../static/constants";

const CmsPrivateRouter = ({ currentUser, component: Component, ...rest }) => {
	React.useEffect( () => {
		if (AuthToken.check()) {
			const token = localStorage.getItem(CONSTANT.AUTH_TOKEN)
			axios.get(
				process.env.REACT_APP_API_URL + `/api/alive`,
				{ headers: { 
					'Authorization': `${token}`,
					"Content-Type": "application/json",
					'Cache-Control': 'no-cache',
					'Pragma': 'no-cache',
					'Expires': '0'
				} }
			)
			.then((res) => {
				let resData = res.data
				if (typeof resData.data.token !== "undefined") {
					AuthToken.set(resData.data.token, true)
				}
			})
			.catch((err) => {
				// console.log("Alive catch")
			})
			.finally(() => {
				// console.log("Alive finally")
			})
		}
	}, [])

	return (
		<Route
			{...rest}
			render={(props) => {
				if (!AuthToken.get()) {
					return <Redirect to="/login" />;
				}
				if (parseInt(currentUser.PROFILE) !== 1) {
					return <Redirect to="/" />;
				}
				return (<Template component={<Component />} />);
			}}
		/>
	);
};

export default CmsPrivateRouter;

CmsPrivateRouter.propTypes = {
	currentUser: PropTypes.object.isRequired,
	component: PropTypes.func.isRequired,
};
