import React from "react";
import Header from './header';
import Breadcrum from './breadcrum';
import Footer from './footer';

import './Index.css';

const RenderTemplate = ( {component} ) => {
  return (
    <>
    <div className="container-fluid" >
      
      <div className="row header delanteMas"><Header /></div>
      <div className="row headerBreadcrum delante"><Breadcrum /></div>
            
      <div className="row main wrapper-20" >{/* RESPONSIVO */}
        {component}
      </div>
      
     <div className="row footer delante"><Footer /></div>     
    </div> 
    </>
  );
};

export default RenderTemplate;