import React from 'react'

export default function AcuerdosJefe({formulario}) {
     let form = JSON.parse(formulario);
  return (
    <>
     {form.map((secciones, key1) => {
          return (
               <>
                    {secciones.secciones.map((seccion, key2) => {
                    return (
                         <>
                              {seccion.seccion.preguntas.map((pregunta, key_pregunta) => {
                                   return (
                                        <div className="row aire">
                                             <div className="col">
                                                  <div className="row">
                                                       <div className="col">
                                                            <span className='TextoAcuerdoInputFormCompromisosJefe'>{pregunta.pregunta.pregunta}</span>
                                                       </div>
                                                  </div>
                                                  <div className="row">
                                                       <div className="col">
                                                            <textarea className="form-control textareaJefe" id={pregunta.pregunta.input} rows="7" defaultValue={pregunta.respuesta || ''} disabled={true}></textarea>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   );
                              })}
                         </>
                    );
                    })}
               </>
          );
     })}
    </>
  )
}
