import React from 'react'
import flechaAcordeon from '../../../assets/img/iconos/flechaAcordeon.svg';

export default function AcordeonEvaluacionJefe({formulario, llave}) {
     let form = JSON.parse(formulario);
  return (
    <>
          <React.Fragment key={`formJefe`}>
               {form.map((data, key) => {
                    return (
                         <>
                              {data.secciones.map((seccion, key_seccion) => {
                                   return (
                                        <React.Fragment key={`formJefe_Seccion_` + key_seccion}>
                                             <div className="accordion acordeonRetroJefe aire" id={`accordionEvaluacion_` + llave + '' + key_seccion}>
                                                  <div className="card" key={key_seccion}>
                                                       <div className="card-header" id="headingOne">
                                                            <button className="btn btn-block text-left" type="button" data-toggle="collapse" data-target={`#Collapse_${seccion.seccion.titulo.replace(/\s/g, "")}` + llave + '' + key_seccion} aria-expanded="false" aria-controls={`Collapse_${seccion.seccion.titulo.replace(/\s/g, "")}` + llave + '' + key_seccion}>
                                                                 <div className="row">
                                                                      <div className="col-10">
                                                                           <span className='tituloAcordeonEvaluacionDetalleJefeLearner'>
                                                                                {seccion.seccion.titulo}
                                                                           </span>
                                                                      </div>
                                                                      <div className="col">
                                                                           <div className="d-flex align-items-center justify-content-end">
                                                                                <img src={flechaAcordeon} alt="" className="flechaAcordeon" />
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            </button>
                                                       </div>
                                                       <div id={`Collapse_${seccion.seccion.titulo.replace(/\s/g, "")}` + llave + '' + key_seccion} className="collapse resultadosAcordion" aria-labelledby="headingOne" data-parent={`#accordionEvaluacion_` + llave + '' + key_seccion}>
                                                            <div className="card-body">
                                                                 <div className="row">
                                                                      <div className="col">
                                                                           <span className="textoEncabezadoSeccionFormRetroJefe">{seccion.seccion.texto}</span>
                                                                      </div>
                                                                 </div>
                                                                 {seccion.seccion.preguntas.map((pregunta, key_pregunta) => {
                                                                      return (
                                                                           <React.Fragment key={'Pregunta' + key_pregunta}>
                                                                                <div className="row aire">
                                                                                     <div className="col">
                                                                                          <span className="textoPreguntaFormRetroJefe">{pregunta.pregunta.titulo}</span>
                                                                                     </div>
                                                                                </div>
                                                                                <div className="row aire">
                                                                                     {pregunta.pregunta.respuestas.map((respuesta, key3) => {
                                                                                          const checked = respuesta.respuesta === pregunta.respuesta;
                                                                                          return (
                                                                                               <React.Fragment key={'resCon' + key3}>
                                                                                                    <div className="row">
                                                                                                         <div className="col">
                                                                                                              <div className="form-check">
                                                                                                                   <div className="align-items-center">
                                                                                                                        <input key={key3} className="form-check-input checkMarkFormRetroJefe" type="radio" name={llave+''+respuesta.grupo} id={llave+''+respuesta.nombreInput} value={respuesta.respuesta} defaultChecked={checked} disabled/>
                                                                                                                        <label className="form-check-label labelRespuestaFormRetroJefe" htmlFor={llave+''+respuesta.nombreInput}>
                                                                                                                             {respuesta.respuesta}
                                                                                                                        </label>
                                                                                                                   </div>
                                                                                                              </div>
                                                                                                         </div>
                                                                                                    </div>
                                                                                               </React.Fragment>
                                                                                          );
                                                                                     })}
                                                                                </div>
                                                                           </React.Fragment>
                                                                      );
                                                                 })}
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                        </React.Fragment>
                                   );
                              })}
                         </>
                    );
               })}
          </React.Fragment>
    </>
  )
}
