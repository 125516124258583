import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { rootReducer } from "./reducers";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' 

const middlewares = [thunk];

const enhancers = [applyMiddleware(...middlewares)];

let composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const persistConfig = {
  key: 'root',
  storage,
} 

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(persistedReducer, composeEnhancers(...enhancers))
export const persistor = persistStore(store)

// export default () => {
//   let store = createStore(persistedReducer, composeEnhancers(...enhancers))
//   let persistor = persistStore(store)
//   return { store, persistor }
// }

// export const store = createStore(rootReducer, composeEnhancers(...enhancers));

/* for (const initializer of initializers) {
  initializer(store);
}
*/