
export const CONSTANT = {
    AUTH_TOKEN: 'ACCESS_TOKEN',
    AUTH_USER_ID: 'ID',
    AUTH_USERNAME: 'USERNAME',
    AUTH_PROFILE: 'PROFILE',
    AUTH_FOTO: 'FOTO',
    AUTH_BIENVENIDA: 'BIENVENIDA',
    AUTH_NOMBRE: 'NOMBRE',
    AUTH_APELLIDO_P: 'APELLIDO_P',
    AUTH_APELLIDO_M: 'APELLIDO_M',
    AUTH_TIMESTAMP_LA: 'AUTH_TIMESTAMP_LA',
    AUTH_CREATEDAT: 'AUTH_CREATEDAT',

    AUTH_EMPLOYEE_ID_VERIFICATION_START: `AUTH_EMPLOYEE_ID_VERIFICATION_START`,
    AUTH_EMPLOYEE_ID_VERIFICATION_SUCCESS: `AUTH_EMPLOYEE_ID_VERIFICATION_SUCCESS`,
    AUTH_EMPLOYEE_ID_VERIFICATION_ERROR: `AUTH_EMPLOYEE_ID_VERIFICATION_ERROR`,
    AUTH_EMPLOYEE_ID_VERIFICATION_UPDATE_PIC: `AUTH_EMPLOYEE_ID_VERIFICATION_UPDATE_PIC`,
    
    AUTH_LOGIN_WITH_PASSWORD_START: `AUTH_LOGIN_WITH_PASSWORD_START`,
    AUTH_LOGIN_WITH_PASSWORD_SUCCESS: `AUTH_LOGIN_WITH_PASSWORD_SUCCESS`,
    AUTH_LOGIN_WITH_PASSWORD_ERROR: `AUTH_LOGIN_WITH_PASSWORD_ERROR`,
  
    AUTH_LOGIN_WITH_TOKEN_START: `AUTH_LOGIN_WITH_TOKEN_START`,
    AUTH_LOGIN_WITH_TOKEN_SUCCESS: `AUTH_LOGIN_WITH_TOKEN_SUCCESS`,
    AUTH_LOGIN_WITH_TOKEN_ERROR: `AUTH_LOGIN_WITH_TOKEN_ERROR`,
  
    AUTH_LOGOUT_RESET_STORE: `AUTH_LOGOUT_RESET_STORE`,

    PROGRAMAS_BY_EMPLOYEE_START: `PROGRAMAS_BY_EMPLOYEE_ID_START`,
    PROGRAMAS_BY_EMPLOYEE_SUCCESS: `PROGRAMAS_BY_EMPLOYEE_ID_SUCCESS`,
    PROGRAMAS_BY_EMPLOYEE_ERROR: `PROGRAMAS_BY_EMPLOYEE_ID_ERROR`,

    UNIDADES_BY_EMPLOYEE_START: `UNIDADES_BY_EMPLOYEE_ID_START`,
    UNIDADES_BY_EMPLOYEE_SUCCESS: `UNIDADES_BY_EMPLOYEE_ID_SUCCESS`,
    UNIDADES_BY_EMPLOYEE_ERROR: `UNIDADES_BY_EMPLOYEE_ID_ERROR`,

    RUTA_JEFE_SET_GRUPO: `RUTA_JEFE_SET_GRUPO`,
    RUTA_JEFE_SET_UNIDAD_USUARIO: `RUTA_JEFE_SET_UNIDAD_USUARIO`,
    RUTA_JEFE_SET_VISTA_ACTIVE: `RUTA_JEFE_SET_VISTA_ACTIVE`,
    RUTA_JEFE_SET_DATA_USUARIO: `RUTA_JEFE_SET_DATA_USUARIO`,
    RUTA_JEFE_SET_DATA_UNIDAD: `RUTA_JEFE_SET_DATA_UNIDAD`,
    

    ARBOL_CHANGE_GRUPO: `ARBOL_ID_GRUPO`,
    ARBOL_CHANGE_PROGRAMA: `ARBOL_ID_PROGRAMA`,
    ARBOL_CHANGE_UNIDAD: `ARBOL_ID_UNIDAD`,
    ARBOL_CHANGE_MODULO: `ARBOL_ID_MODULO`,
    ARBOL_REFRESH_PROGRAMAS: `ARBOL_REFRESH_PROGRAMAS`,
    ARBOL_REFRESH_UNIDADES: `ARBOL_REFRESH_UNIDADES`,
    ARBOL_REFRESH_MODULOS: `ARBOL_REFRESH_MODULOS`,
    ARBOL_REFRESH_ACTIVIDADES: `ARBOL_REFRESH_ACTIVIDADES`,
    ARBOL_SHOW_MODAL: `ARBOL_SHOW_MODAL`,

    MODULOS_BY_UNIDAD_START: `MODULOS_BY_UNIDAD_ID_START`,
    MODULOS_BY_UNIDAD_SUCCESS: `MODULOS_BY_UNIDAD_ID_SUCCESS`,
    MODULOS_BY_UNIDAD_ERROR: `MODULOS_BY_UNIDAD_ID_ERROR`,
    MODULOS_BY_UNIDAD_UPDATE_AUTO_EV: `MODULOS_BY_UNIDAD_UPDATE_AUTO_EV`,
    MODULOS_BY_UNIDAD_UPDATE_ACTS: `MODULOS_BY_UNIDAD_UPDATE_ACTS`,

    ACTIVIDADES_BY_UNIDAD_START: `ACTIVIDADES_BY_UNIDAD_ID_START`,
    ACTIVIDADES_BY_UNIDAD_SUCCESS: `ACTIVIDADES_BY_UNIDAD_ID_SUCCESS`,
    ACTIVIDADES_BY_UNIDAD_ERROR: `ACTIVIDADES_BY_UNIDAD_ID_ERROR`,

    ACTIVIDADES_BY_UNIDAD_BY_MODULO_START: `ACTIVIDADES_BY_UNIDAD_BY_MODULO_ID_START`,
    ACTIVIDADES_BY_UNIDAD_BY_MODULO_SUCCESS: `ACTIVIDADES_BY_UNIDAD_BY_MODULO_ID_SUCCESS`,
    ACTIVIDADES_BY_UNIDAD_BY_MODULO_ERROR: `ACTIVIDADES_BY_UNIDAD__BY_MODULOID_ERROR`,

    ACTIVIDAD_BY_ID_START: `ACTIVIDAD_BY_ID_START`,
    ACTIVIDAD_BY_ID_SUCCESS: `ACTIVIDAD_BY_ID_SUCCESS`,
    ACTIVIDAD_BY_ID_ERROR: `ACTIVIDAD_BY_ID_ERROR`,
    ACTIVIDAD_BY_ID_UPDATE_FINISHED: `ACTIVIDAD_BY_ID_UPDATE_FINISHED`,

    OLAS_BY_USUARIO_START: `OLAS_BY_USUARIO_START`,
    OLAS_BY_USUARIO_SUCCESS: `OLAS_BY_USUARIO_SUCCESS`,
    OLAS_BY_USUARIO_ERROR: `OLAS_BY_USUARIO_ERROR`,

    INSIGNIAS_BY_USUARIO_START: `INSIGNIAS_BY_USUARIO_START`,
    INSIGNIAS_BY_USUARIO_SUCCESS: `INSIGNIAS_BY_USUARIO_SUCCESS`,
    INSIGNIAS_BY_USUARIO_ERROR: `INSIGNIAS_BY_USUARIO_ERROR`,

    FILTRO_USUARIOS_GENERACION: `FILTRO_USUARIOS_GENERACION`,
    FILTRO_USUARIOS_PROGRAMA: `FILTRO_USUARIOS_PROGRAMA`,

    FILTRO_ROTJ_GENERACION: `FILTRO_ROTJ_GENERACION`,
    FILTRO_ROTJ_PROGRAMA: `FILTRO_ROTJ_PROGRAMA`,
    FILTRO_ROTJ_UNIDAD: `FILTRO_ROTJ_UNIDAD`,

    FILTRO_GRAL_GENERACION: `FILTRO_GRAL_GENERACION`,
    FILTRO_GRAL_PROGRAMA: `FILTRO_GRAL_PROGRAMA`,
    FILTRO_GRAL_UNIDAD: `FILTRO_GRAL_UNIDAD`,
    FILTRO_GRAL_MODULO: `FILTRO_GRAL_MODULO`,
    FILTRO_GRAL_OPCIONES_VISIBLES: `FILTRO_GRAL_OPCIONES_VISIBLES`,
    FILTRO_GRAL_FECHA_IN: `FILTRO_GRAL_FECHA_IN`,
    FILTRO_GRAL_FECHA_FIN: `FILTRO_GRAL_FECHA_FIN`,

    FILTRO_GRAL_PRE_GENERACION: `FILTRO_GRAL_PRE_GENERACION`,
    FILTRO_GRAL_PRE_PROGRAMA: `FILTRO_GRAL_PRE_PROGRAMA`,
    FILTRO_GRAL_PRE_UNIDAD: `FILTRO_GRAL_PRE_UNIDAD`,
    FILTRO_GRAL_PRE_MODULO: `FILTRO_GRAL_PRE_MODULO`,
    FILTRO_GRAL_PRE_OPCIONES_VISIBLES: `FILTRO_GRAL_PRE_OPCIONES_VISIBLES`,
    FILTRO_GRAL_PRE_FECHA_IN: `FILTRO_GRAL_PRE_FECHA_IN`,
    FILTRO_GRAL_PRE_FECHA_FIN: `FILTRO_GRAL_PRE_FECHA_FIN`,

    ADMIN_SET_ACTIVE_MENU: `ADMIN_SET_ACTIVE_MENU`,
    ADMIN_SET_PROGRAMA_REDUCIDO_ARBOL: `ADMIN_SET_PROGRAMA_REDUCIDO_ARBOL`,

    ACTUALIZA_EV_FINAL: `ACTUALIZA_EV_FINAL`,

    CHANGE_TAB: `CHANGE_TAB`,
    VIEW_PASO_4: `VIEW_PASO_4`,

    TUTOR_CLEAR: `TUTOR_CLEAR`,
    TUTOR_SET_GRUPO: `TUTOR_SET_GRUPO`,

    FILTRO_JEFE_GENERACION: `FILTRO_JEFE_GENERACION`,
    
};