import { CONSTANT } from '../../static/constants'

const initialState = {
    programa: [],
    unidades: [],
    evaluacionFinal: [],
    loading: true,
    error: null,
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case CONSTANT.PROGRAMAS_BY_EMPLOYEE_START:
            return {
                ...state,
                employeeid: payload,
                error: null,
                loading: true,
            };
        case CONSTANT.PROGRAMAS_BY_EMPLOYEE_SUCCESS:
            return {
                ...state,
                error: null,
                programa: payload.data.programa[0],
                unidades: payload.data.unidades,
                evaluacionFinal: payload.data.evaluacionFinal,
                loading: false,
            };
        case CONSTANT.PROGRAMAS_BY_EMPLOYEE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        case CONSTANT.ACTUALIZA_EV_FINAL:
            return {
                ...state,
                evaluacionFinal: payload
            };
        default:
            return state;
    }
}

export default reducer
  