import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { CONSTANT } from '../../../static/constants';
import Table from './TablaUsuarios';
import Select from "react-select";

export default function Usuarios({options}) {
     const dispatch = useDispatch();
     const storeJefe = useSelector((state) => state.rutaJefe);
     
     const updateGrupo = ( data ) => {
          // setSelectGrupo( data )
          dispatch({ type: CONSTANT.RUTA_JEFE_SET_GRUPO, payload: data.value })
     }

  return (
     <>
          <div className="row airex2">
               <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
                    <span className='tituloVistaJefe'>MIS EQUIPOS A</span><br />
                    <span className='tituloRojoVistaJefe'>RETROALIMENTAR</span>
               </div>
          </div>
          <div className="row aire">
               <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
                    <p className='parradoVistaJefe'>Esta es una vista general de todo tu equipo, aquí puedes revisar su estatus general e identificar si están al corriente o no. Para conocer más detalles sobre un colaborador en particular, haz clic en el botón correspondiente.</p>
               </div>
          </div>
          <div className="row aire">
               <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
                    <div className="row">
                         <div className="col-8 col-lg-5">
                              <div className="form-group">
                                   <Select name="grupos" 
                                   placeholder="SELECCIONA GENERACIÓN A REVISAR" 
                                   defaultValue={null}
                                   onChange={updateGrupo}
                                   options={options} 
                                   />
                              </div>
                         </div>
                    </div>
               </div>
          </div>
          <div className="row aire">
               <div className="offset-0 col-12 offset-lg-1 col-lg-10 pl-4 pr-4 pl-lg-0 pr-lg-0">{/** RESPONSIVO */}
                    {storeJefe.grupo !== null && (
                         <Table />
                    )}
               </div>
          </div>
     </>
  )
}
