import { CONSTANT } from '../../static/constants'

const initialState = {
     unidadActual: [],
     modulos: [],
     loading: true,
     error: null,
 };

 const reducer = (state = initialState, { type, payload }) => {
     switch (type) {
         case CONSTANT.MODULOS_BY_UNIDAD_START:
             return {
                 ...state,
                 unidaid: payload,
                 loading: true,
             };
         case CONSTANT.MODULOS_BY_UNIDAD_SUCCESS:
             return {
                 ...state,
                 unidadActual: payload.data.unidad[0],
                 modulos: payload.data.modulos,
                 informacion: payload.data.informacion,
                 configuracion: payload.data.configuracion,
                 loading: false,
             };
        case CONSTANT.MODULOS_BY_UNIDAD_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        case CONSTANT.MODULOS_BY_UNIDAD_UPDATE_AUTO_EV:
            return {
                ...state,
                informacion: payload,
            };
        case CONSTANT.MODULOS_BY_UNIDAD_UPDATE_ACTS:
            return {
                ...state,
                modulos: payload,
            };
        
         default:
             return state;
     }
 }
 
 export default reducer