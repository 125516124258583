import React from "react";
import CustomizedProgressBars from "./LoadingPage";

export default function CustomLoadable(opts) {
  const LazyComponent = React.lazy(opts.loader);

  return (props) => (
    <React.Suspense fallback={<CustomizedProgressBars />}>
      <LazyComponent {...props} />
    </React.Suspense>
  );
}
