import { CONSTANT } from '../../static/constants'
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL

const AxiosReq = axios.create({
  baseURL: `${API_URL}`,
  headers: {
    "Content-Type": "application/json",
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0'
  },
});                                              

AxiosReq.interceptors.request.use(
    async function (options) {
        const token = localStorage.getItem(CONSTANT.AUTH_TOKEN)
        if (token) {
            options.headers['Authorization'] = token
        }
        return options;
    },
    function (error) {
        console.log("Request error", error);
        return Promise.reject(error);
    }
);

export const cargaUnidades = () => {
    return async (dispatch) => {
        let pathname = window.location.pathname;
        if(pathname === '/'){ // Esto no me gusta pero funciona, ver si hay una forma mejor en un futuro
            dispatch({ type: CONSTANT.PROGRAMAS_BY_EMPLOYEE_START, payload: [] });
            try {
                const AxiosResponse = await AxiosReq.get(`/api/get_unidades`);
                dispatch({ type: CONSTANT.PROGRAMAS_BY_EMPLOYEE_SUCCESS, payload: AxiosResponse });
            } catch (e) {
                const { data, status } = e.response;
                console.info(data, status)
                dispatch({ type: CONSTANT.PROGRAMAS_BY_EMPLOYEE_ERROR, payload: e });
            }
        }
    }
};

export const cargaModulos = (unidad) => {
    return async (dispatch) => {
        dispatch({type: CONSTANT.MODULOS_BY_UNIDAD_START, payload: []});
        try {
            const AxiosResponse = await AxiosReq.get(`/api/get_modulos/unidad/` + unidad);
            dispatch({type: CONSTANT.MODULOS_BY_UNIDAD_SUCCESS, payload: AxiosResponse});
        } catch (e) {
            const { data, status } = e.response;
            console.info(data, status)
            dispatch({type: CONSTANT.MODULOS_BY_UNIDAD_ERROR, payload: e});
        }
    }
};

export const cargaActividadesModulo = (unidad, modulo) => {
    return async (dispatch) => {
        dispatch({type: CONSTANT.ACTIVIDADES_BY_UNIDAD_BY_MODULO_START, payload: []});
        try {
            const AxiosResponse = await AxiosReq.get(`/api/get_actividades/unidad/`+ unidad + `/modulo/` + modulo);
            dispatch({type: CONSTANT.ACTIVIDADES_BY_UNIDAD_BY_MODULO_SUCCESS, payload: AxiosResponse});
        } catch (e) {
            const { data, status } = e.response;
            console.info(data, status)
            dispatch({type: CONSTANT.ACTIVIDADES_BY_UNIDAD_BY_MODULO_ERROR, payload: e});
        }
    }
};

export const cargaSidebar = (unidad) => {
    return async (dispatch) => {
        dispatch({type: CONSTANT.ACTIVIDADES_BY_UNIDAD_START, payload: []});
        try {
            const AxiosResponse = await AxiosReq.get( `/api/get_actividades/modulos/unidad/` + unidad );
            dispatch({type: CONSTANT.ACTIVIDADES_BY_UNIDAD_SUCCESS, payload: AxiosResponse});
        } catch (e) {
            const { data, status } = e.response;
            console.info(data, status)
            dispatch({type: CONSTANT.ACTIVIDADES_BY_UNIDAD_ERROR, payload: e});
        }
    }
};

export const cargaActividad = (actividad) => {
    return async (dispatch) => {
        dispatch({type: CONSTANT.ACTIVIDAD_BY_ID_START, payload: []});
        try {
            const AxiosResponse = await AxiosReq.get( `/api/actividades/` + actividad );
            dispatch({type: CONSTANT.ACTIVIDAD_BY_ID_SUCCESS, payload: AxiosResponse});
        } catch (e) {
            const { data, status } = e.response;
            console.info(data, status)
            dispatch({type: CONSTANT.ACTIVIDAD_BY_ID_ERROR, payload: e});
        }
    }
};

export const cambiaEstatusActividad = () => {
    return async (dispatch) => {
        dispatch({type: CONSTANT.ACTIVIDAD_BY_ID_UPDATE_FINISHED, payload: {}});
    }
};

export const dataTop = async (programaUnidad, id_usuario) => {
    try {
        const AxiosResponse = await AxiosReq.post( `/api/get_info_top`, {pu: programaUnidad, usuario: id_usuario} );
        return AxiosResponse.data
    } catch (e) {
        return { data: [], status: 404 }
    }
    
}