import { CONSTANT } from '../../static/constants'

const initialState = {
     moduloActual: [],
     actividades: [],
     loading: true,
     error: null,
 };

 const reducer = (state = initialState, { type, payload }) => {
     switch (type) {
         case CONSTANT.ACTIVIDADES_BY_UNIDAD_BY_MODULO_START:
             return {
                 ...state,
                 moduloid: payload,
                 loading: true,
             };
         case CONSTANT.ACTIVIDADES_BY_UNIDAD_BY_MODULO_SUCCESS:
             return {
                 ...state,
                 moduloActual: payload.data.modulo[0],
                 actividades: payload.data.actividades,
                 loading: false,
             };
         case CONSTANT.ACTIVIDADES_BY_UNIDAD_BY_MODULO_ERROR:
             return {
                 ...state,
                 error: payload,
                 loading: false,
             };
         default:
             return state;
     }
 }
 
 export default reducer