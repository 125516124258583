import Logo from '../../../assets/img/liftLogo.png';
import menu from '../../../static/menu.json';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CONSTANT } from '../../../static/constants';
import "./Sidebar.css"

export const MenuData = () => {
    
    /*
    const history = useHistory();
    const handleClick = (e, url) => {
        e.preventDefault();
        history.push(url);
    }
    */

    const { activeLinkSidebar } = useSelector((state) => state.admin);
    const dispatch = useDispatch()

    return (
      <>
        {menu.map((data, key) => {
            if(data.type==="section"){
                return (
                    <li className="nav-item menu-open" key={data.label}>
                        <a href="#/" className="nav-link active">
                            <i className={"nav-icon fas " + data.fa_icon}></i>
                            <p>
                                {data.label}<i className="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul className="nav nav-treeview">
                        {data.items.map((data_item, key_item) => {
                            return (
                            <li className="nav-item" key={data_item.label}>
                                <Link to={data_item.href} className="nav-link">
                                    <i className={"nav-icon fas " + data_item.fa_icon}></i>
                                    <p>{data_item.label}</p>
                                </Link>
                            </li>
                            )
                        })}
                        </ul>
                    </li>
                );
            }else{
                return (
                    <li className={"nav-item"+(activeLinkSidebar===data.label?" rowActive":"")} key={data.label}>
                        <Link to={data.href} className="nav-link" onClick={(e) => dispatch({ type: CONSTANT.ADMIN_SET_ACTIVE_MENU, payload: data.label   }) }>
                            <div className='row'>
                                <div className='col-auto d-flex align-items-center justify-content-center'>
                                    <i className={"nav-icon fas " + data.fa_icon}></i>
                                </div>
                                <div className='col'>
                                    <p>{data.label}</p>
                                </div>
                            </div>
                        </Link>
                    </li>
                )
                /*
                return (
                    <li className="nav-item" key={data.label}>
                        <a href="#/" onClick={(e) => handleClick(e, data.href)} className="nav-link">
                        <i className={"nav-icon fas " + data.fa_icon}></i>
                        <p>{data.label}</p>
                        </a>
                    </li>
                );*/
            }
        })}
      </>
    );
  };

export default function Sidebar() {
    return(
        <aside className="main-sidebar sidebar-dark-primary">
            <div className="row justify-content-md-center">
                <div className="col align-content-center ajusteAlturaSidebar">
                    <a href="#/" className="brand-link">
                        <img src={Logo} alt="AdminLTE Logo" className="mx-auto d-block brand-image"/>
                    </a>
                </div>
            </div>
            <div className="sidebar ajusteMenuAdmin2">
                <div className="row menosAire">
                    <div className='col d-flex align-items-center justify-content-center'>
                        <span className='txtMenuSideAdmin'>ADMINISTRADOR</span>
                    </div>
                </div>
                <div className="row">
                    <nav className="ajusteMenuAdmin mt-3">
                        <ul className="nav nav-pills nav-sidebar" data-widget="treeview" role="menu" data-accordion="false">
                            <MenuData />
                        </ul>
                    </nav>
                </div>
            </div>
        </aside>
    );
}