import React from 'react'
import { Link } from "react-router-dom";
import { useDispatch , useSelector} from "react-redux";
import { CONSTANT } from '../../../static/constants'
import axios from 'axios';
import notiGris from '../../../assets/img/iconos/notiGris.png'
import notiVerde from '../../../assets/img/iconos/notiVerde.png'
import iconoSesion from '../../../assets/img/iconos/sesionlive.svg'
import iconoPDF from '../../../assets/img/iconos/pdf.png'
import iconoInsignia from '../../../assets/img/iconos/iconoInsignia.svg'
import { cargaActividadesModulo, cargaModulos } from '../../../modules/services/services'
import User from '../../../assets/img/iconos/UserIcon.svg';
import './Notificaciones.css'

export default function Notificaciones() {
     const dispatch = useDispatch();
     const currentUser = useSelector((state) => state.auth);

     const [Notificaciones, setNotificaciones] = React.useState([])
     const [refresh, setRefresh] = React.useState(false)
     React.useEffect( () => {
          const token = localStorage.getItem(CONSTANT.AUTH_TOKEN);
          axios({
               method: 'GET',
               url: process.env.REACT_APP_API_URL + `/api/notificaciones`,
               headers: { 
                    'Authorization': `${token}`,
                    "Content-Type": "application/json",
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0'
               }
          })
          .then(res => { 
               if(res.status===200){
                    setNotificaciones( res.data.notificaciones )
               }else{
                    setNotificaciones( [] )     
               }
          })
          .catch(err => { 
               console.log( "Error: ", err )
          });

          // // obtener insignias
          // axios({
          //      method: 'GET',
          //      url: process.env.REACT_APP_API_URL + `/api/usuario/insignias`,
          //      headers: { 'Authorization': `${token}`}
          // })
          // .then(res => { 
          //      if(res.status===200){
                    
          //      }
          // })
          // .catch(err => { 
          //      console.log( "Error: ", err )
          // });

          // // obtener perfil
          // axios({
          //      method: 'GET',
          //      url: process.env.REACT_APP_API_URL + `/api/usuario/perfil`,
          //      headers: { 'Authorization': `${token}`}
          // })
          // .then(res => { 
          //      if(res.status===200){
                    
          //      }
          // })
          // .catch(err => { 
          //      console.log( "Error: ", err )
          // });
     }, [ refresh ])

     // const localRoute = (e, route, conf) => {
     //      e.preventDefault()
     //      console.log("Pendiente!")
     // }

     const fechaUTCaLocal = (d) => {
          let localDate = new Date(d)
          // var localDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);
      
          // var offset = date.getTimezoneOffset() / 60;
          // var hours = date.getHours();
      
          // localDate.setHours(hours - offset);
          const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
          
          return localDate.getDate() + " de " + meses[localDate.getMonth()] + " " + localDate.getFullYear() + " " + localDate.getHours() + ":" + localDate.getMinutes()
     }

     const markAsRead = (e, id, preventHide) => {
          if(preventHide === true){
               e.stopPropagation();
               e.preventDefault();
          }
          const token = localStorage.getItem(CONSTANT.AUTH_TOKEN);
          axios({
               method: 'GET',
               url: process.env.REACT_APP_API_URL + `/api/notificacion_vista/${id}`,
               headers: { 'Authorization': `${token}`}
          })
          .then(res => { 
               if(res.status===200){
                    setNotificaciones( [] )
                    setRefresh(!refresh)
               }
          })
          .catch(err => { 
               console.log( "Error: ", err )
          });
     }

     const irActividad = (e, unidad, modulo, id) => {

          dispatch( cargaModulos( unidad ) );
          dispatch( cargaActividadesModulo( unidad, modulo ) );
          markAsRead(e, id, false)

     }

     const irActividadTutor = (e, grupo, id) => {
          dispatch({ type: CONSTANT.TUTOR_SET_GRUPO, payload: grupo }) 
          markAsRead(e, id, false)
     }

     const irReto = (e, unidad, id) => {
          dispatch( cargaModulos( unidad ) );
          markAsRead(e, id, false)
     }

     const notificacion_respuesta_comentario = ( data ) => {

          var obj = { fileName: '', fileUrl: User };
          if (typeof (data.Usuarios[0].foto) == "string") {
               try {
                    obj = JSON.parse( data.Usuarios[0].foto )
               } catch (e) { }
          }
          
          var objData = { nombre_act: '', id_act: '', tipo_act: 0, unidad:0, modulo:0 };
          if (typeof (data.data) == "string") {
               try {
                    objData = JSON.parse( data.data )
               } catch (e) { }
          }
          
          let tipo = (objData.tipo_act===1 ? 'Podcast' : (objData.tipo_act===2 ? 'Video' : (objData.tipo_act===3 ? 'Artículo' : (objData.tipo_act===4 ? 'Toolkit': (objData.tipo_act===5 ? 'Ejercicio de Reforzamiento' : 'N/D')))))

          if(parseInt(currentUser.PROFILE) === 4){
               return <React.Fragment key={"Not_R_"+data.createdAt}>
                         <div className="row mb-3">
                              <div className="col-11">
                                   <Link to={{pathname: '/tutorObjetoForo', state:{objeto: objData.id_act}}} onClick={(e) => irActividadTutor(e, data.Usuarios[0].Grupos[0].id, data.id) } className="dropdown-item notificacion notiPregunta" >
                                        <div className="row d-flex align-items-center">
                                             <div className="col-auto d-flex justify-content-center">
                                                  <img src={obj.fileUrl} className="img-circle" alt="" width="60" height="60"/>
                                             </div>
                                             <div className="col">
                                                  <div className="row">
                                                       <div className="col-auto d-flex align-items-center justify-content-start">
                                                            <span className="tituloNotificacion">{data.Usuarios[0].nombre+" "+data.Usuarios[0].apellido_paterno+" "+data.Usuarios[0].apellido_materno}</span>
                                                       </div>
                                                       <div className="col d-flex align-items-center justify-content-start">
                                                            <span className="fechaNotificacion">{fechaUTCaLocal(data.createdAt)}</span> 
                                                       </div>
                                                  </div>
                                                  <div className="row">
                                                       <div className="col d-flex justify-content-start">
                                                            <p className="textoNotificacion">Respondió pregunta del {tipo}, {objData.nombre_act}</p>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </Link>
                              </div>
                              <div className="col-1">
                                   <div className="col-1 d-flex align-items-start justify-content-end ajustarCerrarNotificacion">
                                        <button className="btn" onClick={(e) => markAsRead(e, data.id, true)}><i className="far fa-times-circle"></i></button>
                                   </div>
                              </div> 
                         </div>
                         <div className="dropdown-divider"></div>
                    </React.Fragment>
          }


          return <React.Fragment key={"Not_R_"+data.createdAt}>
               <div className="row mb-3">
                    <div className="col-11">
                         <Link to={{pathname: '/actividades', state:{unidad: objData.unidad, objeto: tipo, id: objData.id_act}}} onClick={(e) => irActividad(e, objData.unidad, objData.modulo, data.id)} className="dropdown-item notificacion notiPregunta" >
                              <div className="row d-flex align-items-center">
                                   <div className="col-auto d-flex justify-content-center">
                                        <img src={obj.fileUrl} className="img-circle" alt="" width="60" height="60"/>
                                   </div>
                                   <div className="col">
                                        <div className="row">
                                             <div className="col-auto d-flex align-items-center justify-content-start">
                                                  <span className="tituloNotificacion">{data.Usuarios[0].nombre+" "+data.Usuarios[0].apellido_paterno+" "+data.Usuarios[0].apellido_materno}</span>
                                             </div>
                                             <div className="col d-flex align-items-center justify-content-start">
                                                  <span className="fechaNotificacion">{fechaUTCaLocal(data.createdAt)}</span> 
                                             </div>
                                        </div>
                                        <div className="row">
                                             <div className="col d-flex justify-content-start">
                                                  <p className="textoNotificacion">Respondió pregunta del {tipo}, {objData.nombre_act}</p>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </Link>
                    </div>
                    <div className="col-1">
                         <div className="col-1 d-flex align-items-start justify-content-end ajustarCerrarNotificacion">
                              <button className="btn" onClick={(e) => markAsRead(e, data.id, true)}><i className="far fa-times-circle"></i></button>
                         </div>
                    </div> 
               </div>
               <div className="dropdown-divider"></div>
          </React.Fragment>
     }

     const notificacion_evaluar_reto = ( data ) => {

          var objData = { unidad: 0, usuario: ''};
          if (typeof (data.data) == "string") {
               try {
                    objData = JSON.parse( data.data )
               } catch (e) { }
          }

          return <React.Fragment key={"Not_Ev_"+data.createdAt}>
               <div className="row">
                    <div className="col-11">
                         <Link to={{pathname: '/reto', state:{}}} onClick={(e) => irReto(e, objData.unidad, data.id)}className="dropdown-item notificacion notiReto" >
                              <div className="row d-flex align-items-center">
                                   <div className="col-auto d-flex justify-content-center">
                                        <img src={iconoPDF} className="img-circle" alt="" width="60" height="60"/>
                                   </div>
                                   <div className="col">
                                        <div className="row">
                                             <div className="col-auto d-flex align-items-center justify-content-start">
                                                  <span className="tituloNotificacion">Reto para evaluar</span>
                                             </div>
                                             <div className="col d-flex align-items-center justify-content-start">
                                                  <span className="fechaNotificacion">{fechaUTCaLocal(data.createdAt)}</span> 
                                             </div>
                                        </div>
                                        <div className="row">
                                             <div className="col d-flex justify-content-start">
                                                  <p className="textoNotificacion">Puedes evaluar el reto de un compañero!</p>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </Link>
                    </div>
                    <div className="col-1">
                         <div className="col-1 d-flex align-items-start justify-content-end ajustarCerrarNotificacion">
                              <button className="btn" onClick={(e) => markAsRead(e, data.id, true)}><i className="far fa-times-circle"></i></button>
                         </div>
                    </div> 
               </div>
               <div className="dropdown-divider"></div>
          </React.Fragment>
     }

     const notificacion_sesion_live = ( data ) => {
          return <React.Fragment key={"Not_S_"+data.createdAt}>
               <a className="dropdown-item notificacion notiSesion mb-3">
                    <div className="row d-flex align-items-center">
                         <div className="col-auto d-flex justify-content-center">
                              <img src={iconoSesion} className="img-circle" alt="" width="60" height="60"/>
                         </div>
                         <div className="col">
                              <div className="row">
                                   <div className="col-auto d-flex align-items-center justify-content-start">
                                        <span className="tituloNotificacion">SESION LIVE</span>
                                   </div>
                                   <div className="col d-flex align-items-center justify-content-start">
                                        <span className="fechaNotificacion">12 diciembre 2022</span> 
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col d-flex justify-content-start">
                                        <p className="textoNotificacion">Se aproxima una sesión live el día</p>
                                   </div>
                              </div>
                         </div>
                    </div>
               </a>
               <div className="dropdown-divider"></div>
          </React.Fragment>
     }

     const notificacion_cambia_foto = ( data ) => {
          return <React.Fragment key={"Not_S_"+data.createdAt}>
               <a className="dropdown-item notificacion notiSesion mb-3">
                    <div className="row d-flex align-items-center">
                         {/* <div className="col-auto d-flex justify-content-center">
                              <img src={iconoSesion} className="img-circle" alt="" width="60" height="60"/>
                         </div> */}
                         <div className="col">
                              <div className="row">
                                   <div className="col-auto d-flex align-items-center justify-content-start">
                                        <span className="tituloNotificacion">INFORMACIÓN</span>
                                   </div>
                                   <div className="col d-flex align-items-center justify-content-start">
                                        <span className="fechaNotificacion"></span> 
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col d-flex justify-content-start">
                                        <p className="textoNotificacion">Cambia tu foto de perfil.</p>
                                   </div>
                              </div>
                         </div>
                    </div>
               </a>
               <div className="dropdown-divider"></div>
          </React.Fragment>
     }

     const notificacion_conoce_insignias = ( data ) => {
          return <React.Fragment key={"Not_S_"+data.createdAt}>
               <a className="dropdown-item notificacion notiSesion mb-3">
                    <div className="row d-flex align-items-center">
                         {/* <div className="col-auto d-flex justify-content-center">
                              <img src={iconoSesion} className="img-circle" alt="" width="60" height="60"/>
                         </div> */}
                         <div className="col">
                              <div className="row">
                                   <div className="col-auto d-flex align-items-center justify-content-start">
                                        <span className="tituloNotificacion">INFORMACIÓN</span>
                                   </div>
                                   <div className="col d-flex align-items-center justify-content-start">
                                        <span className="fechaNotificacion"></span> 
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col d-flex justify-content-start">
                                        <p className="textoNotificacion">Conoce las insignias que puedes ganar</p>
                                   </div>
                              </div>
                         </div>
                    </div>
               </a>
               <div className="dropdown-divider"></div>
          </React.Fragment>
     }

     const notificacion_insignia = ( data ) => {
          var dataObj={nombre: '', descripcion: 'x', imagen: '{}', tipo: 0};
          if(typeof(data.data) == "string"){
               try {
                    dataObj = JSON.parse( data.data ) 
               }catch(e) {}
          }
          return <React.Fragment key={"Not_I_"+data.id+"_"+data.createdAt}>
               <div className="row mb-3">
                    <div className="col-11">
                         <Link to={{pathname: '/perfil', state:{}}} onClick={(e) => markAsRead(e, data.id, false)} className="dropdown-item notificacion notiReto" >
                              <div className="row d-flex align-items-center">
                                   <div className="col-auto d-flex justify-content-center">
                                        <img src={iconoInsignia} className="img-circle" alt="" width="60" height="60"/>
                                   </div>
                                   <div className="col">
                                        <div className="row">
                                             <div className="col-auto d-flex align-items-center justify-content-start">
                                                  <span className="tituloNotificacion">INSIGNIA</span>
                                             </div>
                                             <div className="col d-flex align-items-center justify-content-start">
                                                  <span className="fechaNotificacion">{fechaUTCaLocal(data.createdAt)}</span> 
                                             </div>
                                        </div>
                                        <div className="row">
                                             <div className="col d-flex justify-content-start">
                                                  <p className="textoNotificacion"><strong>{dataObj.descripcion}</strong></p>
                                             </div>
                                        </div>
                                   </div>
                              
                              </div>
                         </Link>
                    </div>
                    <div className="col-1">
                         <div className="col-1 d-flex align-items-start justify-content-end ajustarCerrarNotificacion">
                              <button className="btn" onClick={(e) => markAsRead(e, data.id, true)}><i className="far fa-times-circle"></i></button>
                         </div>
                    </div>
               </div>
               <div className="dropdown-divider"></div>
          </React.Fragment>
     }

     const notificacion_insignia_jefe = ( data ) => {
          var dataObj={nombre: '', descripcion: 'x', imagen: '{}', tipo: 0};
          if(typeof(data.data) == "string"){
               try {
                    dataObj = JSON.parse( data.data ) 
               }catch(e) {}
          }
          return <React.Fragment key={"Not_I_"+data.id+"_"+data.createdAt}>
               <div className="row mb-3">
                    <div className="col-11">
                         <Link to={{pathname: '/perfilJefe', state:{}}} onClick={(e) => markAsRead(e, data.id, false)} className="dropdown-item notificacion notiReto" >
                              <div className="row d-flex align-items-center">
                                   <div className="col-auto d-flex justify-content-center">
                                        <img src={iconoInsignia} className="img-circle" alt="" width="60" height="60"/>
                                   </div>
                                   <div className="col">
                                        <div className="row">
                                             <div className="col-auto d-flex align-items-center justify-content-start">
                                                  <span className="tituloNotificacion">INSIGNIA</span>
                                             </div>
                                             <div className="col d-flex align-items-center justify-content-start">
                                                  <span className="fechaNotificacion">{fechaUTCaLocal(data.createdAt)}</span> 
                                             </div>
                                        </div>
                                        <div className="row">
                                             <div className="col d-flex justify-content-start">
                                                  <p className="textoNotificacion"><strong>{dataObj.descripcion}</strong></p>
                                             </div>
                                        </div>
                                   </div>
                              
                              </div>
                         </Link>
                    </div>
                    <div className="col-1">
                         <div className="col-1 d-flex align-items-start justify-content-end ajustarCerrarNotificacion">
                              <button className="btn" onClick={(e) => markAsRead(e, data.id, true)}><i className="far fa-times-circle"></i></button>
                         </div>
                    </div>
               </div>
               <div className="dropdown-divider"></div>
          </React.Fragment>
     }

     const notificacion_jefe_1 = ( data ) => {
          return <React.Fragment key={"Not_I_"+data.id+"_"+data.createdAt}>
               <div className="row mb-3">
                    <div className="col-11">
                         <div className="row d-flex align-items-center">
                         <div className="col-auto d-flex justify-content-center">
                                   &nbsp;
                              </div>
                              <div className="col">
                                   <div className="row">
                                        <div className="col-auto d-flex align-items-center justify-content-start">
                                             <span className="tituloNotificacion">NOTIFICACIÓN</span>
                                        </div>
                                        <div className="col d-flex align-items-center justify-content-start">
                                             <span className="fechaNotificacion">{fechaUTCaLocal(data.createdAt)}</span> 
                                        </div>
                                   </div>
                                   <div className="row">
                                        <div className="col d-flex justify-content-start">
                                             <p className="textoNotificacion"><strong>A partir de ahora puedes preparar la evaluación de tus colaboradores para la sesión 1-1. Ten listas tus observaciones.</strong></p>
                                        </div>
                                   </div>
                              </div>
                         
                         </div>
                    </div>
                    <div className="col-1">
                         <div className="col-1 d-flex align-items-start justify-content-end ajustarCerrarNotificacion">
                              <button className="btn" onClick={(e) => markAsRead(e, data.id, true)}><i className="far fa-times-circle"></i></button>
                         </div>
                    </div>
               </div>
               <div className="dropdown-divider"></div>
          </React.Fragment>
     }

     const notificacion_jefe_2 = ( data ) => {
          return <React.Fragment key={"Not_I_"+data.id+"_"+data.createdAt}>
               <div className="row mb-3">
                    <div className="col-11">
                         <div className="row d-flex align-items-center">
                              <div className="col-auto d-flex justify-content-center">
                                   &nbsp;
                              </div>
                              <div className="col">
                                   <div className="row">
                                        <div className="col-auto d-flex align-items-center justify-content-start">
                                             <span className="tituloNotificacion">NOTIFICACIÓN</span>
                                        </div>
                                        <div className="col d-flex align-items-center justify-content-start">
                                             <span className="fechaNotificacion">{fechaUTCaLocal(data.createdAt)}</span> 
                                        </div>
                                   </div>
                                   <div className="row">
                                        <div className="col d-flex justify-content-start">
                                             <p className="textoNotificacion"><strong>El periodo de preparación para la evaluación de tus colaboradores está por concluir. Ten listas tus observaciones para la retroalimentación 1-1.</strong></p>
                                        </div>
                                   </div>
                              </div>
                         
                         </div>
                    </div>
                    <div className="col-1">
                         <div className="col-1 d-flex align-items-start justify-content-end ajustarCerrarNotificacion">
                              <button className="btn" onClick={(e) => markAsRead(e, data.id, true)}><i className="far fa-times-circle"></i></button>
                         </div>
                    </div>
               </div>
               <div className="dropdown-divider"></div>
          </React.Fragment>
     }

     const notificacion_reto_tutor = ( data ) => {

          if(parseInt(currentUser.PROFILE) !== 4){ return <></> }

          var objData = { unidad: 0, usuario: '', grupo: '', nombre_grupo: ''};
          if (typeof (data.data) == "string") {
               try {
                    objData = JSON.parse( data.data )
               } catch (e) { }
          }

          return <React.Fragment key={"Not_Ev_"+data.createdAt}>
               <div className="row mb-3">
                    <div className="col-11">
                    <Link to={{pathname: '/miGrupo', state:{miGrupo: objData.grupo_id}}} onClick={(e) => { dispatch({ type: CONSTANT.TUTOR_SET_GRUPO, payload: objData.grupo_id }); markAsRead(e, data.id, false); }} className="dropdown-item notificacion notiReto" >
                              <div className="row d-flex align-items-center">
                                   <div className="col-auto d-flex justify-content-center">
                                        <img src={iconoPDF} className="img-circle" alt="" width="60" height="60"/>
                                   </div>
                                   <div className="col">
                                        <div className="row">
                                             <div className="col-auto d-flex align-items-center justify-content-start">
                                                  <span className="tituloNotificacion">Reto para evaluar</span>
                                             </div>
                                             <div className="col d-flex align-items-center justify-content-start">
                                                  <span className="fechaNotificacion">{fechaUTCaLocal(data.createdAt)}</span> 
                                             </div>
                                        </div>
                                        <div className="row">
                                             <div className="col d-flex justify-content-start">
                                                  <p className="textoNotificacion">Tienes un nuevo reto a evaluar en la generación {objData.nombre_grupo}.</p>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </Link>
                    </div>
                    <div className="col-1">
                         <div className="col-1 d-flex align-items-start justify-content-end ajustarCerrarNotificacion">
                              <button className="btn" onClick={(e) => markAsRead(e, data.id, true)}><i className="far fa-times-circle"></i></button>
                         </div>
                    </div> 
               </div>
               <div className="dropdown-divider"></div>
          </React.Fragment>
     }

     const nueva_gen = ( data ) => {

          if(parseInt(currentUser.PROFILE) !== 4){ return <></> }

          var objData = { unidad: 0, usuario: '', grupo: '', nombre_grupo: ''};
          if (typeof (data.data) == "string") {
               try {
                    objData = JSON.parse( data.data )
               } catch (e) { }
          }
          return <React.Fragment key={"Not_Ev_"+data.createdAt}>
               <div className="row mb-3">
                    <div className="col-11">
                         <Link to={{pathname: '/miGrupo', state:{miGrupo: objData.grupo}}} onClick={(e) => { dispatch({ type: CONSTANT.TUTOR_SET_GRUPO, payload: objData.grupo }); markAsRead(e, data.id, false); }} className="dropdown-item notificacion notiReto" >
                              <div className="row d-flex align-items-center">
                                   <div className="col-auto d-flex justify-content-center">
                                        <img src={iconoPDF} className="img-circle" alt="" width="60" height="60"/>
                                   </div>
                                   <div className="col">
                                        <div className="row">
                                             <div className="col-auto d-flex align-items-center justify-content-start">
                                                  <span className="tituloNotificacion">Nueva Generación</span>
                                             </div>
                                             <div className="col d-flex align-items-center justify-content-start">
                                                  <span className="fechaNotificacion">{fechaUTCaLocal(data.createdAt)}</span> 
                                             </div>
                                        </div>
                                        <div className="row">
                                             <div className="col d-flex justify-content-start">
                                                  <p className="textoNotificacion">Se te ha asignado la generación {objData.nombre_grupo}.</p>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </Link>
                    </div>
                    <div className="col-1">
                         <div className="col-1 d-flex align-items-start justify-content-end ajustarCerrarNotificacion">
                              <button className="btn" onClick={(e) => markAsRead(e, data.id, true)}><i className="far fa-times-circle"></i></button>
                         </div>
                    </div> 
               </div>
               <div className="dropdown-divider"></div>
          </React.Fragment>
     }

     return (
          <>
               <li className="nav-item dropdown notificaciones">
                    <a className="nav-link" data-toggle="dropdown" href="#/">
                         {Notificaciones.length > 0 ? <img src={notiVerde} alt="" width="40px" height="40px"/> : <img src={notiGris} alt="" width="40px" height="40px"/>}
                         
                    </a>
                    <div className="dropdown-menu dropdown-menu-xl dropdown-menu-right divNotificaciones">
                         <span className="dropdown-header">{Notificaciones.length} Notificaciones</span>
                         <div className="dropdown-divider"></div>
                         {
                              Notificaciones.map( notificacion =>{
                                   switch(notificacion.tipo){
                                        case 1: return notificacion_respuesta_comentario( notificacion );
                                        // case 2: return notificacion_evaluar_reto( notificacion ); 
                                        case 3: return notificacion_sesion_live( notificacion ); 
                                        case 4: return notificacion_insignia( notificacion ); 
                                        case 5: return notificacion_jefe_1( notificacion ); 
                                        case 6: return notificacion_jefe_2( notificacion ); 
                                        case 7: return notificacion_insignia_jefe( notificacion ); 
                                        case 8: return notificacion_reto_tutor( notificacion ); 
                                        case 9: return nueva_gen( notificacion ); 
                                        case 10: return notificacion_cambia_foto( notificacion ); 
                                        case 11: return notificacion_conoce_insignias( notificacion ); 
                                        default: return <></>
                                   }
                              })
                         }
                    </div>
               </li>
          </>
     )
}
