import { CONSTANT } from '../../static/constants'

const initialState = {
     grupo: null,
     unidad: null,
     usuario: null,
     vista: 'uno',
     dataUsuario: null,
     dataUnidad: null
 };

 const reducer = (state = initialState, { type, payload }) => {
     switch (type) {
         case CONSTANT.RUTA_JEFE_SET_GRUPO:
             return {
                ...state,
                grupo: payload,
                unidad: null,
                usuario: null,
                dataUsuario: null,
                dataUnidad: null
             };
        case CONSTANT.RUTA_JEFE_SET_UNIDAD_USUARIO:
            return {
                ...state,
                unidad: payload.unidad,
                usuario: payload.usuario,
                vista: payload.vista
            };
        case CONSTANT.RUTA_JEFE_SET_VISTA_ACTIVE:
            return {
                ...state,
                vista: payload.vista
            };
        case CONSTANT.RUTA_JEFE_SET_DATA_USUARIO:
            return {
                ...state,
                dataUsuario: payload
            };
        case CONSTANT.RUTA_JEFE_SET_DATA_UNIDAD:
            return {
                ...state,
                dataUnidad: payload
            };
        default:
             return state;
     }
 }
 
 export default reducer