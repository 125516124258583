import React, {useState}  from 'react'
import { useDispatch, useSelector } from "react-redux";
import { CONSTANT } from '../../../static/constants';
import { useAxios } from 'use-axios-client';
import Usuarios from './Usuarios';
import Retroalimientacion from './Retroalimientacion';
import DetallesUsuario from './DetallesUsuario';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import '../../../views/web/jefe/jefe.css';

export default function MisEquipos() {

  const dispatch = useDispatch();
  const storeJefe = useSelector((state) => state.rutaJefe);   

  const [active, setActive] = useState( 'uno' );
  const token = localStorage.getItem(CONSTANT.AUTH_TOKEN);
  const [seleccion, setSelectGrupo] = useState(null);
  const [usuario, setUsuario] = useState( 0 );
  const [unidad, setUnidad] = useState( 0 );
  const {data, error, loading } = useAxios({
    url: process.env.REACT_APP_API_URL + `/api/grupos_jefe`,
    headers: {
        'Authorization': `${token}` 
    }
  })

  if(loading) return(<div className="loadingPage"><LoadingSpinner /></div>)
  if(error || !data) return "Error!";
  const options = [];
  // console.log(data)
  data.rows.forEach( function(valor) {
    options.push({value: valor.id, label: valor.nombre});
  });

  return (
    <>
      {storeJefe.vista === 'uno' ? <Usuarios seleccion={seleccion} setSelectGrupo={setSelectGrupo} options={options} setActive={setActive} setUsuario={setUsuario} setUnidad={setUnidad}/> : null}
      {storeJefe.vista === 'dos' ? <Retroalimientacion usuario={usuario} unidad={unidad} setActive={setActive}/> : null}
      {storeJefe.vista === 'tres' ? <DetallesUsuario usuario={usuario} unidad={unidad} setActive={setActive}/> : null}
    </>
  )
}
