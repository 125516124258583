import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Banner from '../../../assets/img/BannerIntroduccionJefe.png';
import Slider1 from '../../../assets/img/p1_slider_jefe.png';
import Slider2 from '../../../assets/img/p2_slider_jefe.png';
import Slider3 from '../../../assets/img/p3_slider_jefe.png';
import Slider4 from '../../../assets/img/p4_slider_jefe.png';
import Slider5 from '../../../assets/img/p5_slider_jefe.png';
import Slider6 from '../../../assets/img/p6_slider_jefe.png';
import Slider7 from '../../../assets/img/p7_slider_jefe.png';
import Slider8 from '../../../assets/img/p8_slider_jefe.png';
import Slider9 from '../../../assets/img/p9_slider_jefe.png';
import Slider10 from '../../../assets/img/p10_slider_jefe.png';
import Slider11 from '../../../assets/img/p11_slider_jefe.png';
import Slider12 from '../../../assets/img/p12_slider_jefe.png';

const options = {
     loop: false,
     center: false,
     items: 1,
     nav: true,
     dots: false,
     margin: 35,
     stagePadding: 0,
     autoWidth: false,
     autoHeightClass: false,
     responsiveClass: false,
     mouseDrag: false,
     touchDrag: false,
     navText : ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>']
};

export default function Introduccion({clickMenu}) {
  return (
     <>
         <div className="row airex2">
              <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
                   <span className='tituloVistaJefe'>¡BIENVENIDO A LA PLATAFORMA DE RETROALIMENTACIÓN LIFT!</span>
              </div>
         </div>
         <div className="row aire">
              <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
                   <p className='parradoVistaJefe'>En este sitio podrás revisar el avance de tus colaboradores inscritos al programa LIFT, pero, sobre todo, retroalimentarás a cada participante sobre la forma en que está aplicando lo aprendido en el programa.</p>
              </div>
         </div>
         <div className="row menosAire">
                <div className="offset-1 col-10 imgPanel imagenObscura" style={{backgroundImage: `url(${Banner})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}></div>
          </div>
          <div className="row aire">
               <div className="offset-1 col-10 offset-lg-0 col-lg">{/** RESPONSIVO */}
                    <div className="row">
                         <div className="col d-flex align-items-center justify-content-center">
                              <span className='tituloVistaJefe'>¿CUÁL ES MI APORTACIÓN EN EL PROGRAMA LIFT?</span>
                         </div>
                    </div>
                    <div className="row aire">
                         <div className="col d-flex align-items-center justify-content-center">
                              <span className='textoVerdeIntroduccionJefe'>¿QUÉ ACCIONES DEBES LLEVAR A CABO?</span>
                         </div>
                    </div>
               </div>
          </div>
          <div className="row aire">
               <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
                    <div className="row rowGrisIntroduccionJefe">
                         <div className="col-1 d-flex align-items-center justify-content-center">
                              <i className='fas fa-eye iconoIntroduccion'></i>
                         </div>
                         <div className="col">
                              <div className="row">
                                   <div className="col">
                                        <span className='textoRojoIntroduccionJefe'>Observa</span>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col">
                                        <span className='textoNegroIntroduccionJefe'>Revisa con detenimiento el desempeño de cada uno de tus colaboradores, observa sus comportamientos y la forma en que se desenvuelve en el día a día. Apóyate en la <strong>"Guía de Observación"</strong> que hemos preparado para ti, ubicada en la sección <strong>"Mis herramientas"</strong>.</span>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
          <div className="row aire">
               <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
                    <div className="row rowGrisIntroduccionJefe">
                         <div className="col-1 d-flex align-items-center justify-content-center">
                              <i className='fas fa-user-friends iconoIntroduccion'></i>
                         </div>
                         <div className="col">
                              <div className="row">
                                   <div className="col">
                                        <span className='textoRojoIntroduccionJefe'>Retroalimenta</span>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col">
                                        <span className='textoNegroIntroduccionJefe'>Reúnete con el colaborador y retroaliméntalo, brindándole recomendaciones para seguir mejorando. Utiliza la <strong>"Guía de retroalimentación"</strong>, que encontrarás en <strong>"Mis herramientas"</strong>, para aprovechar al máximo este momento.</span>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
          <div className="row airex2">
              <div className="offset-1 col-10 offset-lg-2 col-lg-8 d-flex align-items-center justify-content-center">{/** RESPONSIVO */}
                   <span className='tituloVistaJefe textoJefeCentrado'>¿QUÉ PODRÁS HACER EN LA PLATAFORMA DE RETROALIMENTACIÓN?</span>
              </div>
          </div>
          <div className="row">
               <div className="offset-1 col-10 offset-lg-2 col-lg-8 d-flex align-items-center justify-content-center">{/** RESPONSIVO */}
                    <OwlCarousel className="owl-theme" {...options}>
                         <div className='item'>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-center espacioNegroCarouselIntroduccionJefe">
                                        <img src={Slider1} className='sliderCorrousellIntroduccionJefe'/>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col d-flex align-items-start justify-content-center espacioGrisCarouselIntroduccionJefe">
                                        <div className="offset-1 col-10 d-flex align-items-start justify-content-center">
                                             <span className='textoSliderCarrousellIntroduccionJefe'>1 / 12.  <strong>TOUR.</strong> Inicia este pequeño tutorial, te tomará sólo 1 minuto.</span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className='item'>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-center espacioNegroCarouselIntroduccionJefe">
                                        <img src={Slider2} className='sliderCorrousellIntroduccionJefe'/>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col d-flex align-items-start justify-content-center espacioGrisCarouselIntroduccionJefe">
                                        <div className="offset-1 col-10 d-flex align-items-start justify-content-center">
                                             <span className='textoSliderCarrousellIntroduccionJefe'>2 / 12. <strong> INTRODUCCIÓN.</strong> Haz clic en este apartado siempre que quieras dirigirte a la página de inicio.</span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className='item'>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-center espacioNegroCarouselIntroduccionJefe">
                                        <img src={Slider3} className='sliderCorrousellIntroduccionJefe'/>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col d-flex align-items-start justify-content-center espacioGrisCarouselIntroduccionJefe">
                                        <div className="offset-1 col-10 d-flex align-items-start justify-content-center">
                                             <span className='textoSliderCarrousellIntroduccionJefe'>3 / 12.  <strong>Consulta el avance de tu equipo en esta sección.</strong> Aquí aparecerán todos tus colaboradores que estén inscritos al programa. Puedes filtrarlos por generación.</span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className='item'>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-center espacioNegroCarouselIntroduccionJefe">
                                        <img src={Slider4} className='sliderCorrousellIntroduccionJefe'/>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col d-flex align-items-start justify-content-center espacioGrisCarouselIntroduccionJefe">
                                        <div className="offset-1 col-10 d-flex align-items-start justify-content-center">
                                             <span className='textoSliderCarrousellIntroduccionJefe'>4 / 12. <strong>Si el colaborador ha cumplido con todas sus tareas de manera puntual, su progreso se marcará "En tiempo".</strong>  Si por el contrario, tiene tareas pendientes, su estatus será <strong> "Retraso".</strong></span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className='item'>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-center espacioNegroCarouselIntroduccionJefe">
                                        <img src={Slider5} className='sliderCorrousellIntroduccionJefe'/>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col d-flex align-items-start justify-content-center espacioGrisCarouselIntroduccionJefe">
                                        <div className="offset-1 col-10 d-flex align-items-start justify-content-center">
                                             <span className='textoSliderCarrousellIntroduccionJefe'>5 / 12. <strong>Para conocer el detalle de las actividades y pendientes que el colaborador tiene asignados,</strong> haz clic en el botón Ver detalles.</span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className='item'>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-center espacioNegroCarouselIntroduccionJefe">
                                        <img src={Slider6} className='sliderCorrousellIntroduccionJefe'/>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col d-flex align-items-start justify-content-center espacioGrisCarouselIntroduccionJefe">
                                        <div className="offset-1 col-10 d-flex align-items-start justify-content-center">
                                             <span className='textoSliderCarrousellIntroduccionJefe'>6/12. El <strong>detalle de cada colaborador</strong> está organizado por unidad y muestra el progreso, el <strong>Reto on the Job</strong> y los <strong>Resultados del Reto on the Job</strong> que tenga disponibles. Haz clic en el botón de flecha para visualizar toda la información en las secciones en las que esté disponible.</span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className='item'>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-center espacioNegroCarouselIntroduccionJefe">
                                        <img src={Slider7} className='sliderCorrousellIntroduccionJefe'/>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col d-flex align-items-start justify-content-center espacioGrisCarouselIntroduccionJefe">
                                        <div className="offset-1 col-10 d-flex align-items-start justify-content-center">
                                             <span className='textoSliderCarrousellIntroduccionJefe'>7 / 12. <strong>Una vez finalizada tu observación, haz clic en "Prepara evaluación"</strong> para subir tus comentarios y sugerencias.</span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className='item'>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-center espacioNegroCarouselIntroduccionJefe">
                                        <img src={Slider8} className='sliderCorrousellIntroduccionJefe'/>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col d-flex align-items-start justify-content-center espacioGrisCarouselIntroduccionJefe">
                                        <div className="offset-1 col-10 d-flex align-items-start justify-content-center">
                                             <span className='textoSliderCarrousellIntroduccionJefe'>8 / 12.  <strong>En este apartado guarda tu retroalimentación.</strong> Para ello, deberás evaluar al colaborador, con base en las preguntas y afirmaciones que se presenten.</span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className='item'>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-center espacioNegroCarouselIntroduccionJefe">
                                        <img src={Slider9} className='sliderCorrousellIntroduccionJefe'/>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col d-flex align-items-start justify-content-center espacioGrisCarouselIntroduccionJefe">
                                        <div className="offset-1 col-10 d-flex align-items-start justify-content-center">
                                             <span className='textoSliderCarrousellIntroduccionJefe'>9 / 12. <strong>Cuando termines de registrar tu retroalimentación, haz clic en el botón correspondiente para guardarla.</strong> Ahora, estás listo para retroalimentar al colaborador en la sesión programada para ello.</span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className='item'>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-center espacioNegroCarouselIntroduccionJefe">
                                        <img src={Slider10} className='sliderCorrousellIntroduccionJefe'/>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col d-flex align-items-start justify-content-center espacioGrisCarouselIntroduccionJefe">
                                        <div className="offset-1 col-10 d-flex align-items-start justify-content-center">
                                             <span className='textoSliderCarrousellIntroduccionJefe'>10 / 12. <strong>Si en la sesión con el colaborador decidiste cambiar alguna de tus observaciones, podrás hacerlo haciendo nuevamente clic en el reactivo.</strong> Cuando estés seguro de tu retroalimentación haz clic en "Enviar".</span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className='item'>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-center espacioNegroCarouselIntroduccionJefe">
                                        <img src={Slider11} className='sliderCorrousellIntroduccionJefe'/>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col d-flex align-items-start justify-content-center espacioGrisCarouselIntroduccionJefe">
                                        <div className="offset-1 col-10 d-flex align-items-start justify-content-center">
                                             <span className='textoSliderCarrousellIntroduccionJefe'>11 / 12. <strong>Enseguida, registra las acciones y compromisos que tu colaborador debe llevar a cabo para mejorar su rendimiento.</strong> Una vez que hayas terminado, haz clic en "Terminar retroalimentación" y posteriormente en “Enviar” para que el colaborador las pueda recibir y consultar en cualquier momento.</span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className='item'>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-center espacioNegroCarouselIntroduccionJefe">
                                        <img src={Slider12} className='sliderCorrousellIntroduccionJefe'/>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col d-flex align-items-start justify-content-center espacioGrisCarouselIntroduccionJefe">
                                        <div className="offset-1 col-10 d-flex align-items-start justify-content-center">
                                             <span className='textoSliderCarrousellIntroduccionJefe'>12/ 12. <strong>Mis herramientas.</strong> Aquí podrás descargar los materiales que hemos preparado para ti, los cuales te ayudarán a observar y retroalimentar al colaborador. Selecciona la generación en la que estás trabajando y después haz clic en el material que desees descargar.</span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </OwlCarousel>
               </div>
          </div>
          <div className="row aire">
              <div className="offset-1 col-10 offset-lg-2 col-lg-8 d-flex align-items-center justify-content-center">{/** RESPONSIVO */}
                   <span className='textoVerdeIntroduccionJefe2'>¡Contamos contigo!</span>
              </div>
          </div>
          <div className="row aire">
              <div className="offset-1 col-10 offset-lg-2 col-lg-8 d-flex align-items-center justify-content-center">{/** RESPONSIVO */}
                   <span className='parradoVistaJefe'>Recuerda que una de tus principales responsabilidades como Líder es desarrollar a tu equipo y la retroalimentación que les brindes será fundamental para su crecimiento y desempeño en el programa LIFT y en la organización.</span>
              </div>
          </div>
          <div className="row aire">
              <div className="offset-1 col-10 offset-lg-2 col-lg-8 d-flex align-items-center justify-content-center">{/** RESPONSIVO */}
                   <span className='gatoJefe'>#</span><span className='caminandoJefe'>Caminando</span><span className="juntosJefe">Juntos</span>
              </div>
          </div>
          <div className="row aire">
              <div className="offset-1 col-10 offset-lg-2 col-lg-8 d-flex align-items-center justify-content-center">{/** RESPONSIVO */}
                   <button className='btn btn-verdeFormRetroJefe' onClick={(e) => clickMenu(e, 'dos')}>CONTINUAR CON MIS EQUIPOS</button>
              </div>
          </div>
          <br />
          <br />
          <br />
          <br />
     </>
  )
}
