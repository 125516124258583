import React from 'react'
import { Modal } from 'react-bootstrap';
import Fondo from '../../../assets/img/FondoInsignias.png'
import './DetalleInsignia.css'

export default function InsigniaGanada({show, setShow, insignia, nivel}) {
     const handleClose = () => {
          setShow(false);
     }
     var img = { fileName: '', fileUrl: '' };
     if (typeof (insignia.imagen) == "string") {
          try {
               img = JSON.parse(insignia.imagen)
          } catch (e) { }
     }
     if(insignia.tipo === 2 && insignia.nivel > 1){
          if (typeof (insignia["imagenNivel"+insignia.nivel]) == "string") {
               try {
                   img = JSON.parse(insignia["imagenNivel"+insignia.nivel])
               } catch (e) { }
           }
     }
     let nombreInsignia = insignia.nombre;
     if(insignia.tipo === 2 && insignia.nivel > 1){
          nombreInsignia = insignia["nombreNivel"+insignia.nivel]
     }
  return (
     <Modal show={show} onHide={handleClose} animation={false} size='md' centered className='modalInsigniaGanada'>
          <Modal.Title>
               <div className="row MasAire">
                    <div className="d-flex col align-items-center justify-content-center">
                         <span className='tituloInsigniaGanada'>¡FELICIDADES!</span>
                    </div>
               </div>
          </Modal.Title>
          <Modal.Body>
               <div className="row menosAire">
                    <div className="d-flex col align-items-center justify-content-center">
                    <span className='subtituloInsigniaGanada'>Haz obtenido tu Insignia </span>
                    </div>
               </div>
               <div className="row menosAire">
                    <div className="col d-flex align-items-center justify-content-center">
                         <img src={img.fileUrl} className={''} width="260" height="220" alt="imagen insignia"/>
                    </div>
               </div>
               <div className="row menosAire">
                    <div className="offset-2 col-8 d-flex align-items-center justify-content-center">
                         <span className='textoDescripcionInsigniaModal'>{insignia.descripcion}</span>
                    </div>
               </div>
               <div className="row menosAire">
                    <div className="d-flex col align-items-center justify-content-center">
                         <button className="btn btnVerdeInsigniaGanada" onClick={handleClose}>CONTINUAR</button>
                    </div>
               </div>
               <br />
          </Modal.Body>
     </Modal>
  )
}
