import jwtDecode from 'jwt-decode';
import { CONSTANT } from '../../static/constants'

let inMemoryToken = null;

const getAccessToken = () => {

	const accessToken = localStorage.getItem(CONSTANT.AUTH_TOKEN);
	const id = localStorage.getItem(CONSTANT.AUTH_USER_ID);
	const username = localStorage.getItem(CONSTANT.AUTH_USERNAME);
	const perfil = localStorage.getItem(CONSTANT.AUTH_PROFILE);

	if (!accessToken || accessToken === null ||
		!id || id === null ||
		!username || username === null ||
		!perfil || perfil === null
	) {
		AuthToken.setRemove(true)
		return false;
	}
	const metaToken = jwtDecode(accessToken);

	if (!metaToken.data) {
		AuthToken.setRemove(true)
		return false;
	}

	if (
		tokenExpirationCheck(accessToken, metaToken) ||
		parseInt(metaToken.data.id) !== parseInt(id) ||
		metaToken.data.username !== username ||
		parseInt(metaToken.data.perfil) !== parseInt(perfil)
	) {
		AuthToken.setRemove(true)
		return false;
	}

	setLastActivity()

	return true;
}

const setLastActivity = () => {
	localStorage.setItem(CONSTANT.AUTH_TIMESTAMP_LA, Date.now())
}

const renewToken = () => {
	const accessToken = localStorage.getItem(CONSTANT.AUTH_TOKEN)
	if (!accessToken || accessToken === null) {
		console.log("No hay token")
		return false
	}
	return true
}

const tokenStillValidFn = () => {
	const accessToken = localStorage.getItem(CONSTANT.AUTH_TOKEN)

	if (!accessToken || accessToken === null) {
		return false
	}
	const metaToken = jwtDecode(accessToken)
	const { exp } = metaToken
	const checkT = Date.now() / 1000
	const tokenAliveTime = (exp - checkT)

	return (tokenAliveTime > 0)
}

const tokenExpirationCheck = (accesToken, metaToken) => {
	const { exp } = metaToken;
	return (exp < Date.now() / 1000)
}

export default class AuthToken {

	static get() {
		if (!getAccessToken()) {
			return null;
		}
		return inMemoryToken || localStorage.getItem(CONSTANT.AUTH_TOKEN) || null
	}

	static set(token, rememberMe) {
		if (rememberMe) {
			localStorage.setItem(CONSTANT.AUTH_TOKEN, token || "")
		} else {
			inMemoryToken = token
			localStorage.setItem(CONSTANT.AUTH_TOKEN, "")
		}
	}

	static setRemove(removeToken) {
		if (removeToken) {
			localStorage.removeItem(CONSTANT.AUTH_TOKEN)
			localStorage.removeItem(CONSTANT.AUTH_USERNAME)
			localStorage.removeItem(CONSTANT.AUTH_USER_ID)
			localStorage.removeItem(CONSTANT.AUTH_PROFILE)
			localStorage.removeItem(CONSTANT.AUTH_NOMBRE)
			localStorage.removeItem(CONSTANT.AUTH_APELLIDO_P)
			localStorage.removeItem(CONSTANT.AUTH_APELLIDO_M)
			localStorage.removeItem(CONSTANT.AUTH_FOTO)
			localStorage.removeItem(CONSTANT.AUTH_BIENVENIDA)
			localStorage.removeItem(CONSTANT.AUTH_CREATEDAT)
			localStorage.removeItem('persist:root')
		}
	}

	static check() {
		return renewToken()
	}

	static tokenStillValid() {
		return tokenStillValidFn()
	}
}