import { CONSTANT } from '../../static/constants'

const initialState = {
     grupo: null
 };

 const reducer = (state = initialState, { type, payload }) => {
     switch (type) {
        case CONSTANT.TUTOR_CLEAR:
            return {
               ...state,
               grupo: initialState.grupo
            };
           
        case CONSTANT.TUTOR_SET_GRUPO:
            return {
                ...state,
                grupo: payload
            };
               
        default:
             return state;
     }
 }
 
 export default reducer