import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from "react-redux";
import { CONSTANT } from '../../../static/constants';
import { useAxios } from 'use-axios-client';
import FormularioRetro from './FormularioRetro';
import FormularioCompromisos from './FormularioCompromisos';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';

export default function Retroalimientacion({usuario, unidad, setActive}) {

     const dispatch = useDispatch();
     const storeJefe = useSelector((state) => state.rutaJefe);

     const token = localStorage.getItem(CONSTANT.AUTH_TOKEN);
     const [activeRetro, setActiveRetro] = useState( 'uno' );
     const clickMenuRetro = (e, boton) => {
          e.preventDefault();
          if(storeJefe.dataUsuario.evaluacion_jefe_enviada !== 1){
               return;
          }
          setActiveRetro(boton);
     }

     const {data, error, loading } = useAxios({
          url: process.env.REACT_APP_API_URL + `/api/consulta_usuario_unidad/${storeJefe.usuario}/${storeJefe.unidad}`,
          headers: {
               'Authorization': `${token}`,
               "Content-Type": "application/json",
               'Cache-Control': 'no-cache',
               'Pragma': 'no-cache',
               'Expires': '0' 
          }
     })

     // useEffect( () => {
     //      console.log( storeJefe.dataUsuario )
     // },[storeJefe.usuario, storeJefe.unidad])
     
     if(loading) return(<div className="loadingPage"><LoadingSpinner /></div>)
     if(error || !data) return "Error!";

     if(storeJefe.dataUsuario !== data.info.dataUsuario ){
          dispatch({ type: CONSTANT.RUTA_JEFE_SET_DATA_USUARIO, payload: data.info.dataUsuario })
          dispatch({ type: CONSTANT.RUTA_JEFE_SET_DATA_UNIDAD, payload: data.info.dataUnidad })  
     }

  return (
    <>
          <div className="row menosAire">
               <div className="col d-flex align-items-center justify-content-center">
                    <button className={'btn btn-link btn-menuJefe'} onClick={(e) => dispatch({ type: CONSTANT.RUTA_JEFE_SET_VISTA_ACTIVE, payload: {vista: 'uno'} })}>
                         <div className="row">
                              <div className="col d-flex align-items-center justify-content-center"><span className='textoMenuJefe'>{'< Regresar a Mis Equipos'}</span></div>
                         </div>
                    </button>
               </div>
               <div className="col d-flex align-items-center justify-content-center">
                    <button className={activeRetro === 'uno' ? 'btn btn-link btn-menuJefe active' : 'btn btn-link btn-menuJefe'} onClick={(e) => clickMenuRetro(e, 'uno')}>
                         <div className="row">
                              <div className="col d-flex align-items-center justify-content-center"><span className='textoMenuJefe'>1. Retroalimentación</span></div>
                         </div>
                         <div className="row">
                              <div className="col d-flex align-items-center justify-content-center">
                                   <div className={activeRetro === 'uno' ? 'col-6 linea-menuRetroJefe active' : 'offset-2 col-8 linea-menuJefe'}></div>
                              </div>
                         </div>
                    </button>
               </div>
               <div className="col d-flex align-items-center justify-content-center">
                    <button className={activeRetro === 'dos' ? 'btn btn-link btn-menuJefe active' : 'btn btn-link btn-menuJefe'} onClick={(e) => clickMenuRetro(e, 'dos')}>
                         <div className="row">
                              <div className="col d-flex align-items-center justify-content-center"><span className='textoMenuJefe'>2. Determina acciones y compromisos</span></div>
                         </div>
                         <div className="row">
                              <div className="col d-flex align-items-center justify-content-center">
                                   <div className={activeRetro === 'dos' ? 'col-6 linea-menuRetroJefe active' : 'offset-2 col-8 linea-menuJefe'}></div>
                              </div>
                         </div>
                    </button>
               </div>
          </div>
          {activeRetro === 'uno' ? <FormularioRetro funcion={clickMenuRetro}/> : null}
          {activeRetro === 'dos' ? <FormularioCompromisos /> : null}
    </>
  )
}
