import { CONSTANT } from '../../static/constants'

const initialState = {
    idGrupo: 0,
    idPrograma: 0,
    idUnidad: 0,
    idModulo: 0,
    idActividad: 0,
    reloadProgramas: false,
    reloadUnidades: false,
    reloadModulos: false,
    reloadActividades: false,
    loading: false,
    error: null
};

const reducer = (state = initialState, { type, payload }) => {
    switch(type){
        case CONSTANT.ARBOL_CHANGE_GRUPO:
            return {
                ...state,
                idGrupo: payload,
                idPrograma: 0,
                idUnidad: 0,
                idModulo: 0
              };
        case CONSTANT.ARBOL_CHANGE_PROGRAMA:
            return {
                ...state,
                idPrograma: payload,
                idUnidad: 0,
                idModulo: 0
            };
        case CONSTANT.ARBOL_CHANGE_UNIDAD:
            return {
                ...state,
                idUnidad: payload,
                idModulo: 0
            }; 
        case CONSTANT.ARBOL_CHANGE_MODULO:
            return {
                ...state,
                idModulo: payload
            }; 
        case CONSTANT.ARBOL_REFRESH_PROGRAMAS:
            return {
                ...state,
                reloadProgramas: payload
            }; 
        case CONSTANT.ARBOL_REFRESH_UNIDADES:
            return {
                ...state,
                reloadUnidades: payload
            }; 
        case CONSTANT.ARBOL_REFRESH_MODULOS:
            return {
                ...state,
                reloadModulos: payload
            }; 
        case CONSTANT.ARBOL_REFRESH_ACTIVIDADES:
            return {
                ...state,
                reloadActividades: payload
        }; 
        default: 
            return state;
    }
};

export default reducer
  