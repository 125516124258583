import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";
import AuthToken from "modules/shared/authToken";
import Template from "../views/web/template/index";
import Perfil from "../views/web/perfil/index";
import PerfilJefe from "../views/web/perfilJefe/index";
import AvisoJefe from "../views/web/aviso/avisoJefe";
import Jefe from "../views/web/jefe/index";
import RenderTemplateTutor from "views/web/tutor/TemplateTutor";
import axios from 'axios';
import { CONSTANT } from "../static/constants";
import DetalleInsignia from "../components/frontend/detalleInignia/InsigniaGanada";

const WebPrivateRouter = ({ currentUser, component: Component, ...rest }) => {
	const [show, setShow] = useState(false);
	const [insignia, setInsignia] = useState({});
	useEffect( () => {
		if (AuthToken.check()) {
			const token = localStorage.getItem(CONSTANT.AUTH_TOKEN)
			const cancelTokenSource = axios.CancelToken.source();
			
			axios.get(
				process.env.REACT_APP_API_URL + `/api/alive`,
				{ 
					headers: { 
						'Authorization': `${token}`,
						"Content-Type": "application/json",
						'Cache-Control': 'no-cache',
						'Pragma': 'no-cache',
						'Expires': '0' 
					}, 
					cancelToken: cancelTokenSource.token}
			)
			.then((res) => {
				let resData = res.data
				if (typeof resData.data.token !== "undefined") {
					AuthToken.set(resData.data.token, true)
				}
				if (typeof resData.data.popupInsignia !== "undefined") {
					if (typeof resData.data.popupInsignia.InsigniasUsuarios !== "undefined") {
						if(resData.data.popupInsignia.InsigniasUsuarios.popupVisto === false){
							resData.data.popupInsignia.nivel = resData.data.popupInsignia.InsigniasUsuarios.nivel;
							setInsignia( resData.data.popupInsignia );
							setShow( true );
							axios.get(
								process.env.REACT_APP_API_URL + `/api/insignia/marcar_visto/${resData.data.popupInsignia.InsigniasUsuarios.id}`,
								{ 
									headers: { 
										'Authorization': `${token}`,
										"Content-Type": "application/json",
										'Cache-Control': 'no-cache',
										'Pragma': 'no-cache',
										'Expires': '0' 
									}, 
									cancelToken: cancelTokenSource.token}
							)
							.then((res) => {})
						}
					}
				}
			})
			.catch((err) => {
				// console.log("Alive catch")
			})
			.finally(() => {
				// console.log("Alive finally")
			})
		}
	}, [])
	// console.log(rest)

	

	return (
		<>
		{show ? <DetalleInsignia show={show} setShow={setShow} insignia={insignia} /> : null}
		<Route
			{...rest}
			render={(props) => {
				if (!AuthToken.get()) {
					return <Redirect to="/login" />;
				}
				if (parseInt(currentUser.PROFILE) === 1) {
					return <Redirect to="/cms" /> 
				}else if(parseInt(currentUser.PROFILE) === 3){
					if(rest.location.pathname === "/perfilJefe"){
						return <PerfilJefe component={<Component />} />;
					}else if (rest.location.pathname === "/avisoLogeadoJefe") {
						return <AvisoJefe component={<Component />} />;
					}
					return <Jefe />;
				}else if(parseInt(currentUser.PROFILE) === 4){
					if(rest.location.pathname === "/"){
						return <Redirect to="/tutor" />
					}
					return <RenderTemplateTutor component={<Component />}/> ;
				}
				if(rest.location.pathname === "/perfil"){
					return <Perfil component={<Component />} />;
				}
				return (<Template component={<Component />} />);
			}}
		/>
		</>
	);
};

export default WebPrivateRouter;

WebPrivateRouter.propTypes = {
	currentUser: PropTypes.object.isRequired,
	component: PropTypes.func.isRequired,
};
