import { combineReducers } from "redux";

import home from "./home"
import auth from "./auth"
import arbol from './arbol'
import modulos from './modulos'
import actividades from './actividades'
import actividadesModulo from './actividadesModulo'
import objetoAprendizaje from './objetoAprendizaje'
import insignias from './insignias'
import tabsReto from './tabsReto'
import rutaJefe from './rutaJefe'
import filtrosAnaliticos from './filtrosAnaliticos'
import tutor from './tutor'
import admin from './admin'

const combinedReducer = combineReducers({ 
  auth,
  home,
  arbol,
  modulos,
  actividades,
  actividadesModulo,
  objetoAprendizaje,
  insignias,
  tabsReto,
  rutaJefe,
  filtrosAnaliticos,
  tutor,
  admin
});


export const rootReducer = (state, action) => {
  // console.log( "dispatch fire type", action)
  if (action.type === "AUTH_LOGOUT_RESET_STORE") {
    // console.log("CLEANING STORE")
    localStorage.removeItem('persist:root')
    state = undefined
  }
  return combinedReducer(state, action);
}

export default rootReducer;
