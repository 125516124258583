import AuthToken from '../../../modules/shared/authToken';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { CONSTANT } from '../../../static/constants'

export default function Header() {

  document.body.classList.add('light-mode');
  document.body.classList.add('sidebar-mini');
  document.body.classList.add('layout-fixed');
  document.body.classList.add('layout-navbar-fixed');
  document.body.classList.add('layout-footer-fixed');

  const currentUser = useSelector((state) => state.auth);
  const dispatch = useDispatch()

  const cerrarSesion = () => {
    dispatch({type: CONSTANT.AUTH_LOGOUT_RESET_STORE});
    AuthToken.setRemove(true);
  }
  
  useEffect( () => {
    //Esconder menu al cambiar de vista
    document.body.classList.remove('sidebar-open');
  }, [])

  var obj={fileName: '', fileUrl: ''};
  if(typeof(currentUser.FOTO) == "string"){
    try {
        obj = JSON.parse(currentUser.FOTO) 
    }catch(e) {}
  }

  return (
    <nav className="main-header navbar navbar-expand navbar-dark">
      <ul className="navbar-nav ajusteAlturaHeader">
        <li className="nav-item">
          <a href="#/" className="nav-link" data-widget="pushmenu">
            <i className="fas fa-bars"></i>
          </a>
        </li>
      </ul>
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <a href="#/">
            <span className="navbar-text">{currentUser.USERNAME} </span>
          </a>
        </li>
      </ul>
      <ul className="navbar-nav">
        <li className="nav-item dropdown">
          <a className="nav-link navbar-brand" href="#/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img src={obj.fileUrl} className="img-circle fotoPerfil" width="35" height="35" alt="Perfil usuario"/>
          </a>
          <div className="dropdown-menu dropusuario dropdown-menu-right" aria-labelledby="navbarDropdown">
            <a className="dropdown-item" href="#/" onClick={() => { cerrarSesion() }}>
              <i className="fas fa-sign-out-alt"></i> Cerrar Sesion
            </a>
          </div>
        </li>
      </ul>
    </nav>
  );
}
