import { CONSTANT } from '../../static/constants'

const initialState = {
    activeLinkSidebar: '',
    programaReducidoArbol: false
};

const reducer = (state = initialState, { type, payload }) => {
    switch(type){
        case CONSTANT.ADMIN_SET_ACTIVE_MENU:
            return {
                ...state,
                activeLinkSidebar: payload
              };
            case CONSTANT.ADMIN_SET_PROGRAMA_REDUCIDO_ARBOL:
            return {
                ...state,
                programaReducidoArbol: payload
                };
        default: 
            return state;
    }
};

export default reducer
  