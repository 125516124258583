import React, {useState} from 'react'
import Header from '../../../components/frontend/jefe/Header.jsx'
import Introduccion from '../../../components/frontend/jefe/Introduccion.jsx'
import MisEquipos from '../../../components/frontend/jefe/MisEquipos.jsx'
import MisHerramientas from '../../../components/frontend/jefe/MisHerramientas.jsx'
import './jefe.css'

export default function Jefe() {
  const [active, setActive] = useState( 'uno' );

  const clickMenu = (e, boton) => {
    e.preventDefault();
    setActive(boton);
  }

  return (
    <>
      <Header/>
      <div className="container-fluid">
        <div className="row opcionesJefe">
          <div className="col d-flex align-items-center justify-content-center">
            <button className={active === 'uno' ? 'btn btn-link btn-menuJefe active' : 'btn btn-link btn-menuJefe'} onClick={(e) => clickMenu(e, 'uno')}>
              <div className="row">
                <div className="col d-flex align-items-center justify-content-center"><span className='textoMenuJefe'>INTRODUCCIÓN</span></div>
              </div>
              <div className="row">
                <div className="col d-flex align-items-center justify-content-center">
                  <div className={active === 'uno' ? 'col-8 linea-menuJefe active' : 'offset-2 col-8 linea-menuJefe'}></div>
                  </div>
              </div>
            </button>
          </div>
          <div className="col d-flex align-items-center justify-content-center">
            <button className={active === 'dos' ? 'btn btn-link btn-menuJefe active' : 'btn btn-link btn-menuJefe'} onClick={(e) => clickMenu(e, 'dos')}>
              <div className="row">
                <div className="col d-flex align-items-center justify-content-center"><span className='textoMenuJefe'>MIS EQUIPOS</span></div>
              </div>
              <div className="row">
                <div className="col d-flex align-items-center justify-content-center">
                  <div className={active === 'dos' ? 'col-8 linea-menuJefe active' : 'offset-2 col-8 linea-menuJefe'}></div>
                  </div>
              </div>
            </button>
          </div>
          <div className="col d-flex align-items-center justify-content-center">
            <button className={active === 'tres' ? 'btn btn-link btn-menuJefe active' : 'btn btn-link btn-menuJefe'} onClick={(e) => clickMenu(e, 'tres')}>
              <div className="row">
                <div className="col d-flex align-items-center justify-content-center"><span className='textoMenuJefe'>MIS HERRAMIENTAS</span></div>
              </div>
              <div className="row">
                <div className="col d-flex align-items-center justify-content-center">
                  <div className={active === 'tres' ? 'col-8 linea-menuJefe active' : 'offset-2 col-8 linea-menuJefe'}></div>
                  </div>
              </div>
            </button>
          </div>
        </div>
        {active === 'uno' ? <Introduccion clickMenu={clickMenu}/> : null}
        {active === 'dos' ? <MisEquipos/> : null}
        {active === 'tres' ? <MisHerramientas/> : null}
      </div>
    </>
  )
}
