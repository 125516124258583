import React from "react";
import LoadingSpinner from "./components/LoadingSpinner";

const LoadingPage = () => {
  return (
    <div className="loadingPage">
      <LoadingSpinner />
    </div>
  );
};

export default LoadingPage;
