import React from "react";

import Header from './header';
import Sidebar from './sidebar';
import Footer from './footer';
import '../../../assets/adminlte.css';

const RenderTemplate = ( {component} ) => {
 
  return (
    <div className="sidebar-mini">
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{ minHeight: "1594.02px" }}>
            {component}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default RenderTemplate;
