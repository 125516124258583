import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo from '../../../assets/img/logoLearner.png';
import Notificaciones from '../../../components/frontend/notificaciones/Notificaciones';
import '../../../views/web/template/Header.css';

export default function Header() {
	const currentUser = useSelector((state) => state.auth);
     var obj = { fileName: '', fileUrl: '' };
	if (typeof (currentUser.FOTO) == "string") {
		try {
			obj = JSON.parse(currentUser.FOTO)
		} catch (e) { }
	}
  return (
     <>
          <nav id="navbarLift" className="main-header navbar navbar-expand-md navbar-light navbar-white">
               <div className="container-fluid">
                    <div className="logoContainer">
                         <a href="/" className="navbar-brand">
                              <img src={logo} className="logo" alt="logo" />
                         </a>
                    </div>
                    <div className="separador d-none d-sm-none d-md-block"></div>					
                    {/* <span className="nombrePrograma d-none d-sm-none d-md-block">{programa.nombre}</span> */}
                    <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
                         <li className="nav-item ajusteNombreLearner d-none d-lg-block">
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-end">
                                        <span className="navbar-text nombreUsuario text-right">{`${currentUser.NOMBRE} ${currentUser.APELLIDO_P} ${currentUser.APELLIDO_M||''}`}</span>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-end">
                                        <div className="col-auto labelHeaderJefe d-flex align-items-center justify-content-end">
                                             <span className='textoLabelHeaderJefe'>Rol de Jefe</span>
                                        </div>
                                   </div>
                              </div>
                         </li>
                         <li className="nav-item">
                              <Link to={{pathname: '/perfilJefe'}} className="nav-link navbar-brand">
                                   <img src={obj.fileUrl} className="img-circle fotoPerfil" width="60" height="60" alt="Perfil usuario" />
                              </Link>
                         </li>
                         <Notificaciones />
                    </ul>
               </div>
          </nav>
     </>
  )
}
