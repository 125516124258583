import React from 'react'
import { Link } from "react-router-dom";
import './Aviso.css'

export default function Aviso() {
     return (
          <>
          <div className="container">
            <div className="row aire mx-0 p-0 olineB">
            <div className="col-12 offset-md-1 col-md-10 offset-lg-1 col-lg-10">
               <br />
               <br />
               <h1>AVISO DE PRIVACIDAD</h1>
               <br />
               <br />
               <p>Cadena Comercial Oxxo, S.A. de C.V., sus subsidiarias y/o filiales (“Oxxo”), con domicilio en calle Edison 1235 Norte, colonia Talleres en Monterrey, Nuevo León, México, C.P. 64480, es el responsable del uso y protección de sus datos personales, y al respecto le informamos lo siguiente: </p>
               <br />
               <p><span className="masFont">¿Para qué fines utilizaremos sus datos personales?</span><br />
                  Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades:</p>
               <br />
               <p><span className="masFont">Finalidades primarias.</span><br />
               Establecimiento de relación laboral. <br />
               Desarrollar una plataforma digital la cual le permita al colaborador tener en un solo lugar las herramientas necesarias para que pueda vivir su programa de desarrollo a través de un nuevo modelo de experiencia de aprendizaje que le permita al colaborador instalar las competencias de Liderazgo necesarias para desempeñar mejor su rol.<br />
               Cumplimiento de obligaciones contractuales.<br />
               Gestión y otorgamiento de beneficios y prestaciones.</p>
               <br />
               <p><span className="masFont">¿Qué datos personales utilizaremos para estos fines?</span><br />
                  Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales: <br />
                  Datos de Identificación. <br />
                  Datos de Contacto. <br />
                  Datos laborales. </p>
               <br />
               <p><span className="masFont">¿Con quién compartimos su información personal y para qué fines?</span><br />
                  Le informamos que sus datos personales son compartidos dentro del país con las siguientes personas, empresas, organizaciones o autoridades distintas a nosotros, para los siguientes fines y respecto de los cuáles se requiere de su consentimiento:</p>
               <br />
               <p>-Intelego Corporativa, S.A. de C.V.</p>
               <br />
               <p><span className="masFont">¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su tratamiento?</span><br />
                  Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué finalidades los utilizamos y las condiciones del uso que les damos (Acceso).<br />
                  Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación).<br />
                  Tiene derecho a que eliminemos su información de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente, de acuerdo con los requisitos de eliminación señalados en la legislación aplicable vigente (Cancelación). </p>
               <br />
               <p><span className="masFont">¿Cómo puede limitar el uso o divulgación de su información personal?</span><br />
                  Con objeto de que usted pueda limitar el uso y divulgación de su información personal, le ofrecemos los siguientes medios:<br />
                  Llamando al teléfono: 018002886996, mediante el correo electrónico: <strong><u><a className="linkMail" href="mailto:oxxoescucha@oxxo.com">oxxoescucha@oxxo.com</a></u></strong>  o acudiendo personalmente al domicilio señalado en este aviso.<br />	
                  De igual manera, usted puede oponerse al uso de sus datos personales para fines específicos (Oposición).<br />
                  Estos derechos se conocen como derechos ARCO.<br />
                  Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud respectiva a través del siguiente medio:<br /> 
                  Llamando al teléfono: 018002886996, mediante el correo electrónico: <strong><u><a className="linkMail" href="mailto:oxxoescucha@oxxo.com">oxxoescucha@oxxo.com</a></u></strong>  o acudiendo personalmente al domicilio señalado en este aviso.<br /> 
                  Con relación al procedimiento y requisitos para el ejercicio de sus derechos ARCO, le informamos lo siguiente:</p>
               <br />
               <p><span className="masFont">a) ¿A través de qué medios pueden acreditar su identidad el titular y, en su caso, su representante, así como la personalidad este último?</span><br />
                  Mediante el correo electrónico: <strong><u><a className="linkMail" href="mailto:oxxoescucha@oxxo.com">oxxoescucha@oxxo.com</a></u></strong> o acudiendo personalmente al domicilio señalado en este aviso.</p>
               <br />
               <p><span className="masFont">b) ¿Qué información y/o documentación deberá contener la solicitud?</span><br />
                  Nombre, teléfono y domicilio o dato del medio idóneo que permita notificarle la respuesta a la solicitud, su identificación oficial o en su caso, el poder e identificación oficial de su representante legal, la descripción clara y precisa de los datos respecto a los cuales se pretende ejercer algún derecho ARCO y cualquier otro elemento que facilite la localización de los datos personales.</p>
               <br />
               <p><span className="masFont">c) ¿En cuántos días le daremos respuesta a su solicitud?</span><br />
                  20 días hábiles, contados a partir de la fecha de recepción de la misma. </p>
               <br />
               <p><span className="masFont">d) ¿Por qué medio le comunicaremos la respuesta a su solicitud?</span><br />
                  Por el medio que haya señalado para notificación en su solicitud, de conformidad con lo señalado en el inciso inmediato anterior.</p>
               <br />
               <p><span className="masFont">e) ¿En qué medios se pueden reproducir los datos personales que, en su caso, solicite?</span><br />
                  Archivos electrónicos, grabaciones y fotografías.<br />
                  Los datos de contacto de la persona o departamento de datos personales, que está a cargo de dar trámite a las solicitudes de derechos ARCO, son los siguientes:</p>
               <br />
               <p><span className="masFont">¿Cómo puedo conocer los cambios en este aviso de privacidad?</span><br />
                  Oxxo podrá efectuar en cualquier momento modificaciones o actualizaciones al presente aviso de privacidad. Usted está de acuerdo y conforme en que la forma de notificar cualquier cambio a este Aviso de Privacidad será a través de [La sección Perfil de Usuario]. En caso de que Usted no esté de acuerdo con los cambios efectuados a este Aviso de Privacidad, Usted podrá manifestar su negativa en un plazo de 5 (cinco) días hábiles siguientes a aquel en que tuvo conocimiento del presente aviso, en cualquiera de los siguientes medios: llamando al teléfono: 018002886996, mediante el correo electrónico: <strong><u><a className="linkMail" href="mailto:oxxoescucha@oxxo.com">oxxoescucha@oxxo.com</a></u></strong> o acudiendo personalmente al domicilio señalado en este aviso.</p>
               <br />
               <p>a) El facilitador de protección de datos personales por cuenta del Responsable es la Lic. María Eugenia Pérez Hinojosa.<br />
                  b) Domicilio: Calle Edison 1235 Norte, colonia Talleres, Monterrey, Nuevo León, México, C.P. 64480</p>
               <br />
               <p><span className="masFont">Usted puede revocar su consentimiento para el uso de sus datos personales</span><br />
                  Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales.<br />
                  Asimismo, usted deberá considerar que para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.<br />
                  Para pedir la revocación de consentimiento deberá presentar su solicitud a través del siguiente medio:<br />
                  Llamando al teléfono: 018002886996, mediante el correo electrónico: <strong><u><a className="linkMail" href="mailto:oxxoescucha@oxxo.com">oxxoescucha@oxxo.com</a></u></strong>  o acudiendo personalmente al domicilio señalado en este aviso.<br />
                  Con relación al procedimiento y requisitos para la solicitud de revocación de consentimiento, le informamos lo siguiente:</p>
               <br />
               <p><span className="masFont">a) ¿A través de qué medios  pueden acreditar su identidad el titular y, en su caso, su representante, así como la personalidad este último?</span><br/>
                  Mediante el correo electrónico: <strong><u><a className="linkMail" href="mailto:oxxoescucha@oxxo.com">oxxoescucha@oxxo.com</a></u></strong> o acudiendo personalmente al domicilio señalado en este aviso.</p>
               <br />
               <p><span className="masFont">b) ¿Qué información y/o documentación deberá contener la solicitud?</span><br/>
                  Nombre, teléfono y domicilio o dato del medio idóneo que permita notificarle la respuesta a la solicitud, su identificación oficial o en su caso, el poder e identificación oficial de su representante legal, la descripción clara y precisa de la revocación del consentimiento que se pretenda llevar a cabo.</p>
               <br />
               <p><span className="masFont">c) ¿En cuántos días le daremos respuesta a su solicitud?</span><br/>
                  20 días hábiles, contados a partir de la fecha de recepción de la misma.</p>
               <br />
               <p><span className="masFont">d) ¿Por qué medio le comunicaremos la respuesta a su solicitud?</span><br/>
                  Por el medio que haya señalado para notificación en su solicitud, de conformidad con lo señalado en el inciso inmediato anterior.</p>

               <br />
               <p>Fecha última actualización: 12 octubre 2021</p>
               <br />
               <div className="row aireabajo">
                    <div className="col d-flex justify-content-center">
                         <Link to={{pathname: '/login'}}><button className="btn btn-lg btn-verdeAviso"><i className="fas fa-arrow-left"></i>&nbsp;REGRESAR</button></Link>
                    </div>
               </div>
               </div>
            </div>     
          </div>
          </>
     )
}
